<script lang="ts" setup>
import { ref, reactive, computed } from 'vue';
import { useLocalization } from '@/plugins/localization';
import { useAlerts } from '@/plugins/alerts';
import { Form } from '@/helpers/Form';
import { FormBuilderContract } from '@/components/builder/form';
import { ImportModel } from '@/modules/core/import/services/ImportService';
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import { RootEntry } from '@/components/builder/form/entries/RootEntry';
import BpmnService from '@/modules/low-code/process/services/BpmnService';
import { saveAs } from 'file-saver';
import { TableEntry } from '../index';

const emit = defineEmits(["import-completed"]);

const props = defineProps({
  "blueprintName": null,
  "formSchema": null,
  "entry": null
});

const { $alert } = useAlerts();
const { $t } = useLocalization();

const modal = ref(null);

const form = reactive(
    Form.create<ImportModel>({
        file: null,
    })
);

const module = computed(() => props.entry.module);
const formId = computed(() => props.entry.formId);

const fetchImportTemplate = async () =>
{
    try
    {
        const response = await BpmnService.getTableImportTemplate(module.value, formId.value, props.blueprintName);

        const file = new Blob([response], {
            type: response.type,
        });

        saveAs(file, `${module.value}_${formId.value}_${props.blueprintName}_template.xlsx`);
    }
    catch (ex: any)
    {
        if (ex.code === 422) $alert.error($t('[[[Wystąpił problem podczas pobierania szablonu]]]'));
    }
};

const onSubmit = async () =>
{
    try
    {
        form.wait();

        const result = await BpmnService.getTableImportEntry(
            module.value,
            formId.value,
            props.blueprintName,
            form.file
        );
        const blueprint = props.formSchema.schema.find(props.blueprintName) as EntryFactory<any>;
        const tableEntry = blueprint.createEntry([]);

        tableEntry.data = [];

        for (let i = 0; i < result.entry.data.length; i++)
        {
            tableEntry.data[i] = {};

            Object.entries(result.entry.data[i]).forEach(([key, value]) =>
            {
                if (!value) return;

                const elementBlueprint = props.formSchema.schema.find(key) as EntryFactory<any>;

                if (!blueprint) return;

                tableEntry.data[i][key] = elementBlueprint.createEntry(value.data);
            });
        }

        emit('import-completed', tableEntry);

        if (result.errorCells.length > 0)
        {
            $alert.warning($t('[[[Plik zawiera niepoprawne dane w komórkach]]]' + result.errorCells.join(', ')));
        }
    }
    catch (ex: any)
    {
        if (ex.code === 400 || ex.code === 422) $alert.warning($t(ex.message));
    }
    finally
    {
        form.continue();
        modal.value.hide();
    }
};

const showModal = async () =>
{
    modal.value.show();
};

defineExpose({
    showModal,
});
</script>

<template>
    <ideo-modal ref="modal" :title="$t('[[[Import tabeli]]]')" size="lg" centered>
        <template #default>
            <ideo-form-group
                :label="$t('[[[Plik importowy]]]')"
                :invalid-feedback="form.$errors.first('file')"
                :state="form.$errors.state('file')"
                required
            >
                <fileupload v-model="form.file" :max-files="1"></fileupload>
            </ideo-form-group>
        </template>
        <template #modal-footer="{ cancel }">
            <ideo-button variant="success" @click="onSubmit()" :disabled="!form.file">
                {{ $t('[[[Importuj]]]') }}
            </ideo-button>
            <ideo-button
                variant="primary"
                icon="fas fa-arrow-down"
                :disabled="!form.active()"
                @click="fetchImportTemplate"
            >
                {{ $t('[[[Pobierz szablon]]]') }}
            </ideo-button>
            <ideo-button variant="secondary" @click="cancel()">
                {{ $t('[[[Anuluj]]]') }}
            </ideo-button>
        </template>
    </ideo-modal>
</template>

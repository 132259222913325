<script lang="ts" setup>
import { ref, reactive } from 'vue';
import { useEvents } from '@/plugins/events';
import { useAlerts } from '@/plugins/alerts';
import { $t } from '@/plugins/localization';
import { Form } from '@/helpers/Form';
import ShareOneDriveService from '@/modules/low-code/services/ShareOneDriveService';
import IdeoModal from '@/components/ideo/modal/IdeoModal.vue';

const { $events } = useEvents();
const { $alert } = useAlerts();

const form = reactive(
    Form.create({
        mode: null,
        asPdf: false,
    })
);

const filePublicId = ref('');
const modal = ref<InstanceType<typeof IdeoModal> | null>(null);

const onSubmit = async () =>
{
    try
    {
        await ShareOneDriveService.stopShareDocument(filePublicId.value, form.formatData());

        $alert.success($t('[[[Udostępnianie zostało zatrzymane]]]'));
        $events.$emit('refetchDataDetails');
        $events.$emit('refetchData');

        modal.value?.hide();
        form.clear();
    }
    catch (ex: any)
    {
        if (ex.code === 200) $alert.warning($t('[[[Błąd podczas zatrzymywania udostępniania]]]'));
        else if (ex.code === 422) form.$errors.record(ex.data.errors);
    }
};

const showModal = async (_id: string | null) =>
{
    modal.value?.show();
    filePublicId.value = _id;
};

defineExpose({
    showModal,
});
</script>

<template>
    <ideo-modal ref="modal" :title="$t('[[[Zatrzymaj udostępnianie]]]')" centered @close="form.clear()">
        <template #default>
            <form id="stop-share-one-drive-modal" @submit.prevent="onSubmit">
                <ideo-form-group
                    :label="$t('[[[Tryb zapisu dokumentu]]]')"
                    :invalid-feedback="form.$errors.first('mode')"
                    :state="form.$errors.state('mode')"
                >
                    <ideo-select
                        v-model="form.mode"
                        :placeholder="$t('[[[Tryb zapisu dokumentu]]]')"
                        :endpoint="`simple-dictionary/one-drive-doc/form/${filePublicId}/close/mode`"
                        @update:model-value="form.$errors.clear('mode')"
                    />
                </ideo-form-group>
                <ideo-form-group :invalid-feedback="form.$errors.first('asPdf')" :state="form.$errors.state('asPdf')">
                    <ideo-form-checkbox v-model="form.asPdf" @update:model-value="form.$errors.clear('asPdf')">
                        {{ $t('[[[Zapisz dokument jako nowy w PDF]]]') }}
                    </ideo-form-checkbox>
                </ideo-form-group>
            </form>
        </template>
        <template #modal-footer="{ cancel }">
            <ideo-button variant="success" type="submit" form="stop-share-one-drive-modal" :disabled="!form.active()">
                {{ $t('[[[Zatrzymaj]]]') }}
            </ideo-button>
            <ideo-button variant="secondary" @click="cancel()">
                {{ $t('[[[Anuluj]]]') }}
            </ideo-button>
        </template>
    </ideo-modal>
</template>

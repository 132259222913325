import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { ValidatableBlueprint } from '@/components/builder/base/blueprints/ValidatableBlueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { FormBuilderContract } from '@/components/builder/form';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { CustomErrorBlueprint } from '@/components/builder/form/blueprints/CustomErrorBlueprint';
import { RequiredBlueprint } from '@/components/builder/form/blueprints/RequiredBlueprint';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { ReadonlyBlueprint } from '@/components/builder/form/blueprints/ReadonlyBlueprint';
import { Entry, entry } from '@/components/builder/form/entries/Entry';
import { ValidEntry } from '@/components/builder/form/entries/ValidEntry';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import { HasHelp } from '@/components/builder/form/traits/HasHelp';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { HasPlaceholder } from '@/components/builder/form/traits/HasPlaceholder';
import { ProcessCallback } from '@/components/builder/form/types/ProcessCallback';
import { KeyValuePair } from '@/helpers/Interfaces';

export const Definition: BlueprintDefinition = {
    type: 'user',
    name: '[[[Użytkownik]]]',
    icon: 'fa-solid fa-person',
    group: 'logito',
    position: 3,
};

export enum UserFeatures {
    Director = 1,
    Manager,
    Superior,
    Subordinate,
    DirectSubordinate,
    NoLogon,
}

export enum UserAccessType {
    All = 1,
    Active,
    ModuleAccess,
}

export enum UserDataFields {
    GivenName,
    Surname,
    Email,
    Unit,
    JobPosition,
    Boss,
    IsDirector,
    IsManager,
}

export enum FilterByGroupType {
    None = 0,
    Unit,
    Team,
    Group,
    Dynamic
}

export interface UserContract
    extends Blueprint,
    VisibleBlueprint,
    ReadonlyBlueprint,
    RequiredBlueprint,
    CustomErrorBlueprint,
    HasLabel,
    HasWidth,
    HasPlaceholder,
    HasHelp {
    multiselect: boolean;
    defaultValue: string[];
    userTypes: Record<string, boolean>;
    accessType: UserAccessType;
    defaultCurrentUser: boolean;
    dataFields: Record<string, boolean>;
    filterByGroupType: string;
    filterGroups: string[];
    filterByGroupField: string;
}

export class UserEntry extends ValidEntry<KeyValuePair[]>
{
    public type: string = Definition.type;
    public data: KeyValuePair[] = [];
    public valuesType: number = null;

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.data = data;
        }
    }

    public async collect(
        blueprint: UserContract,
        form: FormBuilderContract,
        preprocess: ProcessCallback
    ): Promise<Entry>
    {
        await preprocess(blueprint, this, form.blueprintId, form.entryId);

        const value = this.data.map((item) => item.key);

        return entry(value);
    }

    public validate(blueprint: UserContract, form: FormBuilderContract): boolean
    {
        this.errors = {};

        const value = this.data;

        if (!form.expressions.readonly(blueprint, true) && form.expressions.visible(blueprint, true))
        {
            if (form.expressions.required(blueprint) && (value === null || !value.length))
            {
                this.errors.value = [`[[[Pole "%0" jest wymagane.|||${form.localization.translate(blueprint.label)}]]]`];
            }
            else if (form.expressions.customError(blueprint))
            {
                this.errors.custom = [form.expressions.customErrorMessage(blueprint, form)];
            }
        }

        return this.valid();
    }

    private getValues(blueprint: UserContract): string[]
    {
        if (this.data !== null) return this.data.map(item => item.key);

        if (blueprint.defaultValue?.length) return blueprint.defaultValue;

        return [];
    }
}

export const instanceOfUserEntry = (object: any): object is UserEntry =>
{
    return object && 'type' in object && object.type === Definition.type;
};

export class UserType implements UserContract, ValidatableBlueprint, EntryFactory<UserEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: Record<string, string>;
    public fetchModule: string;
    public fetchProp: string;
    public guid: string;
    public processGuid: string;
    public showLabel: boolean;
    public width: number;
    public minWidth: number;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public required: AlwaysChoice | NeverChoice | WhenChoice;
    public requiredWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: Record<string, string>;
    public placeholder: Record<string, string>;
    public errors: ValidationErrors;
    public dataSource: string | KeyValuePair;
    public help: Record<string, string>;
    public multiselect: boolean;
    public defaultValue: string[];
    public userTypes: Record<string, boolean>;
    public accessType: UserAccessType;
    public defaultCurrentUser: boolean;
    public dataFields: Record<string, boolean>;
    public filterByGroupType: string;
    public filterGroups: string[];
    public filterByGroupField: string;

    public constructor(id: string, name: string)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.width = 0;
        this.minWidth = 1;
        this.fetchModule = '';
        this.fetchProp = '';
        this.guid = '';
        this.processGuid = '';
        this.showLabel = true;
        this.label = { 'pl-PL': 'Użytkownik' };
        this.placeholder = { 'pl-PL': 'Wybierz...' };
        this.required = AlwaysChoice.Always;
        this.requiredWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = {};
        this.dataSource = null;
        this.help = {};
        this.errors = {};
        this.multiselect = false;
        this.defaultValue = [];
        this.userTypes = {
            Subordinate: true,
            Director: false,
            Superior: false,
            Manager: false,
            DirectSubordinate: false,
            NoLogon: false,
        };
        this.accessType = null;
        this.defaultCurrentUser = false;
        this.dataFields = {
            GivenName: false,
            Surname: false,
            Email: false,
            Unit: false,
            JobPosition: false,
            Boss: false,
            IsDirector: false,
            IsManager: false,
        };
        this.filterByGroupType = FilterByGroupType[FilterByGroupType.None];
        this.filterGroups = [];
        this.filterByGroupField = null;
    }

    public setDefaultWidth(width: number): void
    {
        this.width = Math.min(3, Math.max(this.minWidth, width));
    }

    public createEntry(data: any): UserEntry
    {
        return new UserEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        if (!this.accessType) this.errors.accessType = ['[[[Źródło danych jest wymagane]]]'];

        return {
            [this.name]: this.errors,
        };
    }
}

<script lang="ts" setup>
import { ref, reactive, watch, onUnmounted, computed } from 'vue';
import { useLocalization } from '@/plugins/localization';
import { useStore } from 'vuex';
import Pager from '@/helpers/Pager';
import { Form } from '@/helpers/Form';
import { RelatedDocumentType } from '..';
import { scrollRedirect } from '@/components/common/dynamic-grid/helpers/scrollRedirect';
import IdeoModal from '@/components/ideo/modal/IdeoModal.vue';
import { FormEntry } from '../../form';
import { KeyValuePair } from '@/helpers/Interfaces';
import { FormBuilderContract } from '@/components/builder/form';
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';

const { $t, $i18n } = useLocalization();
const store = useStore();

const emit = defineEmits(["document-selected", "hide"]);

const props = defineProps({
  "moduleLicence": null,
  "blueprint": null,
  "entry": null,
  "form": null,
  "index": null
});

const modal = ref<IdeoModal | null>(null);

const pager = reactive(new Pager(1, 10, 'Id', 'desc')) as Pager;
const filter = reactive(Form.create({})) as any;
const filterBuilder = reactive<Record<string, unknown>>({});

const licence = computed(() => props.moduleLicence ?? 'document');
const endpoint = computed(() => `${licence.value}/${props.moduleLicence ? 'search' : 'list'}`);
const filtersDone = computed(() => store.state.common.grid.filtersInitialized);
const title = computed(() => $i18n.currentTranslation(props.blueprint.label));
const hiddenFilters = computed(() =>
{
    const result = [];

    if (props.blueprint.modules.length > 0) result.push('moduleId');

    if (props.blueprint.hasFilterByContractor) result.push(props.blueprint.contractorFilter?.value?.toLowerCase());

    const filters = JSON.parse(props.blueprint.filtersJson);

    for (const prop in filters)
    {
        if (filters[prop] == null || (Array.isArray(filters[prop]) && filters[prop].length == 0))
            continue;

        result.push(prop);
    }

    for (const field in props.blueprint.filterMappings)
    {
        result.push(props.blueprint.filterMappings[field]);
    }

    return result;
});

// TODO: Rewrite filters
const overwriteFilter = (event: { key: string; value: any; period: boolean }) =>
{
    if (event.period)
    {
        filterBuilder[`${event.key}.FromUtc`] = event.value;
        filterBuilder[`${event.key}.ToUtc`] = event.value;
        filterBuilder[`${event.key}.DaysBeforeNow`] = event.value;
        filterBuilder[`${event.key}.DaysAfterNow`] = event.value;
    }
    else filterBuilder[event.key] = event.value;

    const filters = JSON.parse(props.blueprint.filtersJson);

    for (const prop in filters)
        filterBuilder[prop] = filters[prop];

    filterBuilder['moduleId'] = props.blueprint.modules;
    filterBuilder['allowTargets'] = props.blueprint.allowTargets;
    filterBuilder['onlyUnfinished'] = props.blueprint.onlyUnfinished;
    filterBuilder['allowAuthor'] = props.blueprint.allowAuthor;

    if (props.blueprint.hasFilterByContractor)
        filterBuilder[props.blueprint.contractorFilter.value.toLowerCase()] = props.entry[props.blueprint.contractorFilter.key].data;

    if (props.blueprint.hasFilterByRelatedDocuments)
        filterBuilder['relatedDocumentId'] =  (props.entry[props.blueprint.relatedDocumentsFilter]?.data as KeyValuePair[])?.first();

    if (props.blueprint.modules?.length != 1 || !Object.keys(props.blueprint.filterMappings ?? {}).length) return;

    for (const item in props.blueprint.filterMappings)
    {
        const value = props.blueprint.filterMappings[item];
        const blueprint = props.form.schema.find(item) as EntryFactory<any>;
        const parent = props.form.schema.parent(blueprint);

        if (!blueprint) continue;

        const element = parent?.type === 'table' ? props.entry[parent.name].data[props.index][item] : props.entry[item];

        if (element && 'getValues' in element)
        {
            filterBuilder[value] = element.getValues(blueprint, props.form);
        }
    }

};

const onRowClick = (item: any) =>
{
    emit('document-selected', item.result.publicId);

    modal.value?.hide();
};

const onScrollClick = (item: any) =>
{
    scrollRedirect({
        name: 'module-form-details',
        params: { licence: item.result.licence, publicId: item.result.publicId },
    });
};

watch(filtersDone, (value) =>
{
    if (value)
    {
        filter.setInitialValues(filterBuilder);
        filter.withData(filterBuilder);
    }
});

const showModal = async () =>
{
    modal.value.show();
};

onUnmounted(() =>
{
    store.commit('common/grid/SET_INITIALIZATION', false);
});

defineExpose({
    showModal,
});
</script>

<template>
    <ideo-modal ref="modal" centered size="xxl" :title="title" @hidden="emit('hide')">
        <template #default>
            <dynamic-grid
                :pager="pager"
                :filter="filter"
                :endpoint="endpoint"
                :row-click="onRowClick"
                :scroll-click="onScrollClick"
                :persist-state="false"
            >
                <template #toolbar>
                    <ideo-panel :column="false" :stretch="false" :start="desktop">
                        <template #default>
                            <new-filters-wrapper :filter="filter" :url="licence">
                                <dynamic-filters
                                    :request-url="licence"
                                    :filter="filter"
                                    :hidden-filters="hiddenFilters"
                                    @filter-update="overwriteFilter"
                                />
                            </new-filters-wrapper>
                        </template>
                    </ideo-panel>
                </template>
                <template #actions="{ item }">
                    <row-actions>
                        <ideo-dropdown-item-button @click="onRowClick(item)">
                            <i class="fas fa-square-list"></i>
                            <span>{{ $t('[[[Wybierz dokument]]]') }}</span>
                        </ideo-dropdown-item-button>
                    </row-actions>
                </template>
            </dynamic-grid>
        </template>
        <template #modal-footer="{ cancel }">
            <ideo-button variant="secondary" @click="cancel">
                {{ $t('[[[Anuluj]]]') }}
            </ideo-button>
        </template>
    </ideo-modal>
</template>

<script lang="ts" setup>
import { computed, ref, getCurrentInstance } from 'vue';

const instance = getCurrentInstance();

defineOptions({
    name: 'field-numeric'
});

const props = defineProps({
  "label": null,
  "modelValue": null,
  "placeholder": { default: null },
  "append": { default: null },
  "naturals": { type: Boolean, default: false },
  "nullable": { type: Boolean, default: false },
  "step": { default: 0 },
  "precision": { default: 0 },
  "min": { default: () => Number.MIN_SAFE_INTEGER + 1 },
  "max": { default: () => Number.MAX_SAFE_INTEGER - 1 },
  "invalidFeedback": { type: Function, default: null }
});

const emit = defineEmits(["update:modelValue"]);

const focused = ref(false);

const uid = computed(() => `form-field-${instance.uid}`);
const display = computed(() => props.modelValue !== null ? props.modelValue.toFixed(props.precision) : null);
const increment = computed(() => incrementFn(props.step, props.precision));

const rangeMin = computed(() => props.naturals ? Math.max(0, props.min) : props.min);
const rangeMax = computed(() => props.max);

const incrementFn = (step: number, precision: number): number =>
{
    if (step === 0)
        return 1 / Math.max(Math.pow(10, precision), 1);
    else
        return step;
};

const triggerInput = (value: number): void =>
{
    emit('update:modelValue', value);
};

const update = (e: any): void =>
{
    if (!focused.value)
    {
        if (e.target.value !== '' || props.nullable === false)
            triggerInput(parseFloat(e.target.value || 0));
        else
            triggerInput(null);

        instance?.proxy?.$forceUpdate();
    }
};

const focus = (e: any): void =>
{
    focused.value = true;
};

const blur = (e: any): void =>
{
    focused.value = false;
    update(e);
};
</script>

<template>
    <div class="form-group">
        <label :for="uid" v-if="label">{{ label }}</label>
        <ideo-input-group :append="append">
            <input type="number" :min="rangeMin" :max="rangeMax" :step="increment" :id="uid" class="form-control" :placeholder="placeholder" :value="display" @input="update" @focus="focus" @blur="blur">
        </ideo-input-group>
        <small class="form-text text-danger" v-if="invalidFeedback && invalidFeedback(modelValue)">
            {{ $t(invalidFeedback(modelValue)) }}
        </small>
    </div>
</template>

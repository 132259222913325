export default {
    "Project-Id-Version": "",
    "POT-Creation-Date": "2024-12-03T10:34:06.950Z",
    "MIME-Version": "1.0",
    "Content-Type": "text/plain; charset=utf-8",
    "Content-Transfer-Encoding": "8bit",
    "X-Generator": "Edito",
    "PO-Revision-Date": "2024-12-03T10:34:07.326Z",
    "Last-Translator": "",
    "Language-Team": "",
    "Language": "en-US",
    "Messages": {
        "1": "",
        "2": "",
        "3": "3",
        "4": "",
        " - klient http z następującymi metodami:": " - http client with the following methods:",
        " - powoduje wywołanie akcji jako użytkownik systemowy": " - causes an action to be called as a system user",
        " - pozwala na wyciąganie wszystkich serwisów": "- allows for access to all services",
        " - tablica ze wszystkimi akcjami procesu": " - an array with all proces actions",
        " - tablica ze wszystkimi akcjami procesu (tylko dla skryptów timerowych)": "- an array with all proces actions (only for timer events)",
        " - ustawia ciało zapytania jako przekazany obiekt": " - sets the query body as a passed object",
        " - ustawia endpointa na określony url z wybraną metodą HTTP": " - sets the endpoint to the specified url with the selected HTTP method",
        " - wysyła zapytanie i zwraca odpowiedź": " - sends a query and returns a response",
        " - zawiera dane dokumentu sprzed zapisu formularza w takim samym formacie jak document": "- contains document data prior to form being saved (in the same format as standard document data)",
        " - zawiera pole BaseUrl z nazwą licencji w postaci, w jakiej trafia do url": " - contains the BaseUrl field with the name of the license as it goes to the url",
        " - zawiera pole PublicId oraz DbData, które jest słownikiem zawierającym wszystkie indeksowane pola dokumentu": " - contains the PublicId field and DbData, which is a dictionary containing all indexed fields of the document",
        " - zawiera pole PublicId oraz DbDataDynamic, które jest słownikiem zawierającym wszystkie indeksowane pola dokumentu": "",
        " - zawiera pole value z id aktualnego procesu": " - contains a value field with the id of the current process",
        " z ": " z ",
        ".": ".",
        "...": "...",
        "' + file.filename + '": "",
        "' + func.name + '": "",
        "“Po potwierdzeniu wybrani użytkownicy całkowicie utracą dostęp do dokumentu. Czy chcesz kontynuować?": "",
        "(Przekroczyłeś limit aktywnych kont użytkowników, skontaktuj się z dostawcą systemu w celu zmiany warunków licencji)": "(You have exceeded the limit of active user accounts, contact your system provider to change the license terms)",
        "%0": "%0",
        "%0 z %1": "%0 of %1",
        "%0 znaków": "%0 characters",
        "+3 dni": "+3 days",
        "<Brak nazwy>": "<Brak nazwy>",
        "${copied ? 'Skopiowano' : 'Kopiuj'}": "${copied ? 'Copied' : 'Copy'}",
        "${el.text}": "",
        "${form.variants > 1 ? 'Generuj obrazy' : 'Generuj obraz'}": "",
        "${getCurrentScope().label}": "",
        "${item.entityName}": "",
        "${item.text}": "",
        "${item.value}": "${item.value}",
        "${loading ? 'Ładowanie elementów...' : 'Nie znaleziono żadnych elementów.'}": "${loading ? 'Loading elements ...' : 'No elements found.'}",
        "${props.inputPlaceholder}": "",
        "${str}": "",
        "${text}": "${text}",
        "${tokenVisibility ? 'Ukryj' : 'Pokaż'} token": "${tokenVisibility ? 'Hide' : 'Show'} token",
        "1 godzina": "1 hour",
        "1:1": "1:1",
        "10 minut": "10 minutes",
        "12 godzin": "12 hours",
        "15 minut": "15 minutes",
        "2 dni": "2 days",
        "2 miesiące": "2 months",
        "2 tygodnie": "2 weeks",
        "24 godziny": "24 hours",
        "3 dni": "3 days",
        "3 godziny": "3 hours",
        "3 miesiące": "3 months",
        "30 minut": "30 minutes",
        "4 dni": "4 days",
        "4:7": "4:7",
        "5 dni": "5 days",
        "5 minut": "5 minutes",
        "6 dni": "6 days",
        "6 godzin": "6 hours",
        "7:4": "7:4",
        "Aby dodawać członków zespołu musisz najpierw zapisać formularz.": "To add team members, you have to save the form.",
        "Aby dodawać linie do szablonu musisz najpierw zapisać formularz.": "To add lines to the template you must first save the form.",
        "Aby edytować tylko bieżącą wersję roboczą utwórz nową wersję procesu": "To edit only the current draft version create a new process version",
        "Aby móc przetestować poprawność kluczy, najpierw zapisz formularz.": "To test the correctness of the keys, first save the form.",
        "Aby podpisać dokument zaloguj się do usługi mSzafir klikając przycisk poniżej.": "To sign a document log in to the mSzafir service by pressing the buton below.",
        "Aby podpisać dokument zaloguj się do usługi SimplySign klikając przycisk poniżej.": "To sign the document, log in to SimplySign by clicking the button below.",
        "Aby sprawdzić strukturę danych, które powinny zostać przekazane do importu, pobierz przykładowy plik przyciskiem \"Pobierz szablon\"": "To check the structure of the data that should be transferred to the import, download the sample file with the button \"Download template\"",
        "Aby zawężyć wybór terminów dostępnych na liście możesz wyfiltrować wpisy na podstawie pól formularza zdefiniowanych w definicji słownika.": "To narrow down the selection of terms available in the list, you can filter entries based on the form fields defined in the dictionary definition.",
        "Aby zobaczyć aktualny podgląd szablonu musisz go zapisać": "To see the current preview of the template you must save it",
        "Adaptive sampling": "",
        "Administracja": "Administration",
        "Administracja - Kraje": "Administration - Countries",
        "Administracja - Role": "Administration - Roles",
        "Administracja - Stanowiska pracy": "Administration - Positions",
        "Administracja - Użytkownicy": "Administration - Users",
        "Administracja - Zespoły": "Administration - Teams",
        "Administratorzy": "Administrators",
        "Adres": "Address",
        "Adres do eDoręczeń: ": "Address for eDelivery:",
        "Adres domenowy": "Domain address",
        "Adres e-mail": "E-mail address",
        "Adres e-mail potwierdzony": "The email address has been confirmed",
        "Adres email": "Email address",
        "Adres email nadawcy": "Sender's email address",
        "Adres IP": "IP address",
        "Adres IP: %0": "IP address: %0",
        "Adres korespądencyjny: ": "Correspondence address:",
        "Adres serwera": "Server address",
        "Adres siedziby: ": "Registered address:",
        "Adres URL": "URL",
        "Akcent": "Accent",
        "Akceptacja": "Acceptance",
        "Akceptacja dokumentów": "Acceptance of documents",
        "Akceptacja dokumentu": "Acceptance of the document",
        "Akceptacja własnych dokumentów": "Acceptance of own documents",
        "Akceptacja wszystkich dokumentów": "Acceptance of all documents",
        "Akceptowany format danych": "Accepted data format",
        "Akceptuj": "Accept",
        "Akceptujący": "Accepter",
        "Akceptujący 1 powyżej limitu": "Accepting 1 over the limit",
        "Akceptujący 2 powyżej limitu": "Accepting 2 over the limit",
        "Akcja": "Action",
        "Akcja systemowa": "System action",
        "Akcja wykonana pomyślnie": "Action performed successfully",
        "Akcje": "Actions",
        "Akcje zostały zapisane": "The shares were recorded",
        "Aktualizacja: {0}": "Update: {0}",
        "Aktualizuj": "Update",
        "Aktualna sesja": "Current session",
        "Aktualne hasło": "Current password",
        "Aktualne urządzenie": "Current device",
        "Aktywna": "Active",
        "Aktywna do": "Active until",
        "Aktywne": "Active",
        "Aktywne kanały komunikacji": "Active communication channels",
        "Aktywne sesje": "Active sessions",
        "Aktywne źródła": "Active sources",
        "Aktywni": "Active",
        "Aktywność": "Activity",
        "Aktywny": "Active",
        "Aktywny filtr:": "Active filter:",
        "Aktywnych kont": "Active accounts",
        "Aktywuj OCR": "Activate OCR",
        "Aktywuj tłumaczenia": "Activate translations",
        "Aktywuj zestaw inteligentnych narzędzi": "Activate inteligent tools",
        "Alert": "Alert",
        "Alias": "Alias",
        "Alias może zawierać tylko znaki alfanumeryczne oraz znak podkreślenia": "Alias can only contain alphanumeric symbols or an underscore symbol",
        "Alias został przepięty.": "Alias has been rewired.",
        "aneks": "appendix",
        "Angielski": "English",
        "Ankieta": "Survey",
        "Anonimizacja kontrahenta rozpoczęta. W zależności od ilości dokumentów proces ten może potrwać do kilkunastu minut.": "Contractor anonymization has begun. Depending on the number of documents, this process can take up to several minutes.",
        "Anonimizuj": "Anonymize",
        "Anonimizuj dane osobowe": "Anonymize personal information",
        "Anonimowy": "Anonymous",
        "Anuluj": "Cancel",
        "Aplikacja": "Aplicacion",
        "Aplikacja zostanie zrestartowana w przeciągu maksymalnie 60 sekund.": "Application will be restarted within 60 seconds.",
        "application/json": "",
        "application/x-www-form-urlencoded": "",
        "Archiwizuj przez maksymalnie (dni)": "Archive for a maximum of (days)",
        "Argument": "",
        "Asystent": "Assistant",
        "Asystent AI": "AI Assitant",
        "Asystent został dodany.": "Assitant was added",
        "Asystent został zaktualizowany.": "Assistant was updated",
        "Asystenta AI": "AI Assistant",
        "Atrybut": "Attribute",
        "Atrybut 1": "Attribute 1",
        "Atrybut 10": "Attribute 10",
        "Atrybut 2": "Attribute 2",
        "Atrybut 3": "Attribute 3",
        "Atrybut 4": "Attribute 4",
        "Atrybut 5": "Attribute 5",
        "Atrybut 6": "Attribute 6",
        "Atrybut 7": "Attribute 7",
        "Atrybut 8": "Attribute 8",
        "Atrybut 9": "Attribute 9",
        "Autentykacja SimplySign": "SimplySign Authentication",
        "Automatyzacja": "Automation",
        "Autor": "Author",
        "Autor dokumentu": "Author of the document",
        "Azure AD": "Azure AD",
        "Azure AI": "Azure AI",
        "Azure AI Vision": "",
        "Azure Application Insights": "",
        "Azure OpenAI": "Azure OpenAI",
        "Basic Auth": "",
        "Baza danych": "Database",
        "Baza danych tego klienta jest nieaktualna.": "Database of this client is out of date.",
        "Bazy danych": "Database",
        "Bez formularza": "Without a form",
        "Bezpieczeństwo": "Security",
        "Bezpieczne połączenie SSL": "Secure SSL connection",
        "Bezpośrednio do członków wybranej grupy (np. działu)": "Directly to members of a selected gropu (ie. a unit)",
        "Bieżący dokument": "Current document",
        "Biuro": "Office",
        "Blokada konta": "Account blocked",
        "Blokowanie kont": "Account blocking",
        "Blokująca": "Blocking",
        "Błąd 404. Nie znaleziono żądanego zasobu.": "Error 404. The requested resource was not found.",
        "Błąd importu. Zaimportowano wierszy: ": "Import error. Rows imported: ",
        "Błąd podczas eksportowania": "Error during export",
        "Błąd podczas generowania dokumentu": "Error during document generation",
        "Błąd podczas oznaczania jako nieodczytany": "Error during marking as unseen",
        "Błąd podczas pobierania": "Error during download",
        "Błąd podczas pobierania danych": "Error while downloading data",
        "Błąd podczas pobierania listy": "Error while downloading the list",
        "Błąd podczas pobierania schematu": "Error while downloading schema",
        "Błąd podczas pobierania szczegółów": "Error while downloading details",
        "Błąd podczas pobierania udostępnionych dokumentów": "Error when downloading shared documents",
        "Błąd podczas pobierania użytkownika": "Error while downloading user",
        "Błąd podczas renderowania PDF": "Error while rendering the PDF",
        "Błąd podczas rezerwacji": "Error during reservation",
        "Błąd podczas wykonywania akcji": "",
        "Błąd podczas zatrzymywania udostępniania": "Error while stopping sharing",
        "Błąd walidacji": "Validation error",
        "Błędne": "Incorrect",
        "Błędny": "Faulty",
        "Błędy": "Errors",
        "Błędy walidacji należy przypisać do \"entry.errors.value\" w postaci tablicy komunikatów.": "Validation errors should be set in \"entry.errors.value\" as a message array.",
        "Brak": "Lack",
        "Brak aktywnego filtru": "No active filter",
        "Brak cennika dla wybranego dostawcy.": "No pricetable for selected supplier.",
        "Brak danych": "No data",
        "Brak danych dla wykresu": "No data available for the chart",
        "Brak dodanych kolumn": "No columns added",
        "Brak dostępnych akcji": "No shares available",
        "Brak działu nadrzędnego": "No parent section",
        "Brak elementów": "No elements",
        "Brak kluczy": "No keys",
        "Brak kolekcji": "No collection",
        "Brak kolumn": "No columns",
        "Brak limitu": "No limit",
        "Brak nazwy": "<Brak nazwy>",
        "Brak nowych powiadomień.": "No new notifications.",
        "Brak odpowiedzi": "No response",
        "Brak opcji": "No options",
        "Brak pracowników w danym dziale": "Lack of employees in the department",
        "Brak przesyłki": "No shipment",
        "Brak wartości wyświetlanej": "No value displayed",
        "Brak wersji": "No version",
        "Brak wpisów": "No entries",
        "Brak wyboru": "No choice",
        "Brak wymaganych danych": "No data required",
        "Brak wyników": "No results",
        "Brak wyników wyszukiwania": "No search results.",
        "Brak wyników!": "No results!",
        "Brak załączników": "No attachments",
        "Brak zdefiniowanych ról.": "No defined roles.",
        "Brak zdefiniowanych zespołów.": "No defined teams.",
        "Bramka SMS": "SMS gateway",
        "Budżet": "Budget",
        "Budżet i opis dokumentu": "Budget and document description",
        "Cache będzie przechowywany z wykorzystaniem serwera Redis.": "The cache will be stored using a Redis server.",
        "Cache po stronie serwera": "Server-side caching",
        "Cache-Control": "Cache-Control",
        "Cel aktywny": "Target active",
        "Cel został dodany.": "Target was added.",
        "cel został usunięty.": "Target was deleted.",
        "Cel został zaktualizowany.": "Target was updated.",
        "Cele": "Targets",
        "Cena": "Price",
        "Cena w dolarach sumy tokenów wejściowych i wyjściowych.": "Price in dollars (sum of input and output tokens).",
        "Cennik Azure OpenAI": "Pricetable of Azure OpenAI",
        "Cennik AzureAI": "Pricatable of AzureAI ",
        "Cennik OpenAI": "Pricatetable of OpenAI",
        "Centrum powiadomień": "Notification center",
        "Checkbox": "Checkbox",
        "Ciemny": "Dark",
        "Client ID": "",
        "Client Secret": "",
        "co": "co",
        "Co 10 dni": "Every 10 days",
        "Co 10 minut": "Every 10 minutes",
        "Co 12 godzin": "Every 12 hours",
        "Co 15 minut": "Every 15 minutes",
        "Co 2 godziny": "Every 2 hours",
        "Co 2 minuty": "Every 2 minutes",
        "Co 2 tygodnie": "Every 2 weeks",
        "Co 20 minut": "Every 20 minutes",
        "Co 3 godziny": "Every 3 hours",
        "co 3 miesiące": "every 3 months",
        "Co 3 miesiące": "Every 3 months",
        "co 30 dni": "every 30 days",
        "Co 30 minut": "Every 30 minutes",
        "Co 4 miesiące": "Every 4 months",
        "Co 5 dni": "Every 5 days",
        "Co 5 minut": "Every 5 minutes",
        "Co 6 godzin": "Every 6 hours",
        "co 6 miesięcy": "every 6 months",
        "Co chcesz wygenerować?": "What do you want to generate?",
        "Co drugi dzień": "Every other day",
        "Co drugi miesiąc": "Every other month",
        "Co godzinę": "Every hour",
        "Co miesiąc": "Every month",
        "Co minutę": "Every minute",
        "Co pół roku": "Every six months",
        "co rok": "every year",
        "Co tydzień": "Every week",
        "Codziennie": "Every day",
        "Cofnięto prywatność dokumentu": "Document privacy revoked",
        "Connection String": "",
        "Coś poszło nie tak": "Something went wrong",
        "Cron": "Cron",
        "Cyfry": "Digits",
        "Czas": "Time",
        "Czas pomiędzy": "Time between",
        "Czas rozpoczęcia": "Start time",
        "Czas trwania (minuty)": "Duration (minutes)",
        "Czas trwania blokady": "Account blocking time",
        "Czas uruchomienia": "Start-up time",
        "Czas zakończenia": "Closing time",
        "Czat z asystentem": "Chat with assistant",
        "Czerwiec": "June",
        "Częstotliwość wykonywania (Cron)": "Frequency of execution (Cron)",
        "Członkowie zespołu": "Team members",
        "Cztery kolumny": "Four columns",
        "Czwartek": "Thursday",
        "Czy aby na pewno chesz przywrócić ustawienia domyślne?": "Are you sure you want to restore default settings?",
        "Czy aby na pewno chesz zrestartować aplikacje?": "Are you sure you want to restart the application?",
        "Czy chcesz ograniczyć dostęp do dokumentu w zastępstwie?": "Do you want to restrict access to the document by proxy?",
        "Czy chcesz usunąć ograniczenie dostępu do dokumentu w zastępstwie?": "Do you want to remove the document access restriction in the alternate?",
        "Czy na pewno chcesz dezaktywować spółkę?": "Are you sure you want to deactivate the company?",
        "Czy na pewno chcesz rozgrupować te pola?": "Are you sure you want to ungroup these fields?",
        "Czy na pewno chcesz usunąć szablon?": "Are you sure you want to delete the template?",
        "Czy na pewno chcesz usunąć tego kontrahenta?": "Are you sure you want to remove this contractor?",
        "Czy na pewno chcesz usunąć ten dokument?": "Are you sure you want to delete this document?",
        "Czy na pewno chcesz usunąć ten obiekt?": "Are you sure you want to remove this object?",
        "Czy na pewno chcesz usunąć ten rekord?": "Are you sure you want to delete this record?",
        "Czy na pewno chcesz usunąć ten widget?": "Are you sure you want to delete this widget?",
        "Czy na pewno chcesz usunąć to pole wspólne?": "Are you sure you want to delete this shared field?",
        "Czy na pewno chcesz usunąć widget?": "Are you sure you want to delete this widget?",
        "Czy na pewno chcesz zanonimizować dane osobowe tego kontrahenta?": "Are you sure you want to anonymize this contractor's personal information?",
        "Czy napewno chcesz usunąć tą zmienną środowiskową?": "Are you sure you want to delete this enviromental variable?",
        "Czy napewno chcesz usunąć ten szablon": "Are you sure you want to delete this template",
        "Czynności": "Activities",
        "czynność": "activity",
        "Czynność": "Activity",
        "Czynność zostanie wpisana w historii po wznowieniu obiegu": "The activity will be entered in the history when circulation resumes",
        "Dalej": "Next",
        "DALL-E 3": "",
        "Dane": "Data",
        "Dane do zapisania": "Data to be recorded",
        "Dane formularza są przechowywane w obiekcie Entry.": "The form data is stored in the Entry object.",
        "Dane osobowe": "Personal data",
        "Dane podstawowe": "Basic data",
        "Dane tabeli przechowywane są w obiekcie table": "The table data is stored in the table object",
        "Dane w tabeli": "Table data",
        "Dane wejściowe": "Input data",
        "Dane wektorowe": "",
        "Dane wyjściowe": "Output data",
        "Dane z tego pola są przechowywane w bazie w szyfrowanej formie": "Data from this field is stored in the database in an encrypted form",
        "danger": "danger",
        "dark": "dark",
        "Data": "Date",
        "Data aktywacji": "Activation date",
        "Data anonimizacji": "Date of anonymization",
        "Data dezaktywacji": "Deactivation date",
        "Data do": "Date to",
        "Data do walidacji": "Date to be validated",
        "Data do walidacji budżetu i dostępnych kodów opisu dokumentu": "Date to validate budget and available document description codes",
        "Data dodania": "Adding date",
        "Data dokumentu": "Date of document",
        "Data edycji": "Change date",
        "Data i czas": "Date and time",
        "Data końcowa": "End date",
        "Data Kursu": "Course Date",
        "Data modyfikacji": "Modification date",
        "Data od": "Date from",
        "Data opuszczenia zespołu": "Date of leaving the team",
        "Data otrzymania": "Date of acquisition",
        "Data powiadomienia": "Date of notification",
        "Data rejestracji": "Registration date",
        "Data rozpoczęcia": "Start date",
        "Data udostępnienia": "Date of release",
        "Data uruchomienia": "Launch date",
        "Data uruchomienia (UTC)": "Launch date (UTC)",
        "Data usunięcia": "Deletion date",
        "Data utworzenia": "Creation date",
        "Data utworzenia wersji": "Version creation date",
        "Data utworzenia: ": "Creation date:",
        "Data użycia": "Use date",
        "Data wygaśnięcia": "Expiration date",
        "Data wykonania": "Transaction date",
        "Data wylogowania": "Logout date",
        "Data wysyłki": "Date of shipment",
        "Data wywołania": "Execution date",
        "Data zakończenia": "End date",
        "Data zakończenia blokady konta": "Account block end date",
        "Data zalogowania": "Login date",
        "Data zapoznania": "",
        "Data zapytania": "Query date",
        "Data zatrudnienia": "Date of employment",
        "Data zatrzymania udostępniania": "Release stop date",
        "Data zdarzenia": "Event date",
        "Data zwolnienia": "Date of release",
        "Data: %0 | Status: %1": "Date: %0 | Status: %1",
        "Debug": "",
        "Debugger": "",
        "Dedykowane": "Dedicated",
        "DeepL": "",
        "Definicja": "Definition of",
        "Definicja (JSON)": "Definition (JSON)",
        "Definicja formularza znajduje się w obiekcie Form.": "The form definition is in the Form object.",
        "Definicja pól JSON": "Definition of JSON fields",
        "Definicja została zaktualizowana.": "The definition has been updated.",
        "Definicję pól zapisujemy jako obiekt JSON, którego klucze to klucze pól formularza, a wartości to obiekty z właściwościami konfigurującymi dane pole.": "We save the definition of the fields as a JSON object, whose keys are the keys of the form fields, and whose values are objects with properties that configure the field.",
        "DELETE: Usunięcie wpisu": "DELETE: Delete an entry",
        "Design Studio": "Design Studio",
        "Dezaktywacja spółki spowoduje trwałe usunięcie powiązania z pracownikami. ": "Deactivating the company will permanently remove the link to employees. ",
        "Dezaktywuj": "Deactivate",
        "Diagram": "Diagram",
        "Dla dokumentów o sygnaturach": "For documents with references",
        "Dla kogo": "Owner",
        "Dla wybranych dokumentów nie znaleziono wspólnych akcji do wykonania": "For the selected documents, no common actions were found to be performed",
        "Długość czasu przechowywania": "Length of storage time",
        "Długość trwania sesji [min": "Session duration [min",
        "dni": "days",
        "Dni": "Days",
        "do": "to",
        "Do": "To",
        "Do akceptacji": "To be accepted",
        "do czasu ręcznego odblokowania": "until manually unlocked",
        "Do działu": "",
        "Do grupy użytkowników": "To the user group",
        "Do kierowników działu autora dokumentu": "To the department heads of the document's author",
        "Do osoby wykonującej akcję oraz do osoby wybranej na formularzu": "To the person performing the action and to the person selected on the form",
        "Do osoby wykonującej bieżącą akcję": "To the person performing the current action",
        "Do przełożonego użytkownika wykonującego wskazany krok": "To the supervisor of the user performing the indicated step",
        "Do rezygnacji": "To resign",
        "Do użytkownika": "",
        "Do użytkownika wykonującego wskazany krok": "To the user performing the indicated step",
        "Do użytkownika z najmniejszą ilością zadań w grupie (np. zespole)": "",
        "Do użytkownika z pola dokumentu": "",
        "Do użytkownika/grupy użytkowników wskazanej w polach dokumentu": "To the user/group of users indicated in the fields of the document",
        "Do użytkownika/grupy użytkowników wskazanej w wybranym kroku na wskazanym formularzu": "To the user/group of users indicated in the selected step on the indicated form",
        "Do użytkownika/grupy użytkowników/kontrahenta/maila wskazanego w polach dokumentu": "To user/user group/contractor/mail selected in document fields",
        "Do użytkowników wykonujących obecny krok z wyjątkiem osoby wykonującej akcję": "To users performing the current step except the person performing the action",
        "Do użytkowników z wybranego działu": "To users in the selected department",
        "Do wprowadzenia": "To be introduced",
        "do wszystkich zaproszonych": "to all invitees",
        "Do wybranego użytkownika": "To the selected user",
        "Do wybranego użytkownika i działu": "",
        "Do zespołu": "",
        "Do zespołu/działu wybranego w polu dokumentu": "",
        "doc['Nazwa pola'": "doc['Field name'",
        "Docs": "Docs",
        "Dodaj": "Add",
        "Dodaj / Edytuj": "Add / Edit",
        "Dodaj akcję": "Add action",
        "Dodaj asystenta": "Add an assistant",
        "Dodaj atrybut": "Add attribute",
        "Dodaj automatyzacje": "Add automatization",
        "Dodaj cel": "Add a target",
        "Dodaj czynność": "Add an activity",
        "Dodaj danę wektorową": "",
        "Dodaj dni": "Add days",
        "Dodaj do zespołu": "Add to team",
        "Dodaj dokument": "Add the document",
        "Dodaj dział": "Add section",
        "Dodaj endpoint": "Add endpoint",
        "Dodaj formularz": "Add form",
        "Dodaj funkcję": "Add a function",
        "Dodaj grupę": "Add group",
        "Dodaj jeden lub więcej widgetów, aby uzyskać wgląd w postępy swojego zespołu.": "Add one or more widgets to gain insight into your team's progress.",
        "Dodaj kategorię": "Add category",
        "Dodaj klienta": "Add client",
        "Dodaj kod kontrolingowy": "Add controlling code",
        "Dodaj komentarz": "Add comment",
        "Dodaj komponent": "Add a component",
        "Dodaj kontrahenta": "Add Vendor",
        "Dodaj licznik": "Add counter",
        "Dodaj logo": "Add logo",
        "Dodaj moduł": "Add module",
        "Dodaj nową linię": "Add a new line",
        "Dodaj nowego kontrahenta": "Add a new contractor",
        "Dodaj nowy folder": "Add a new folder",
        "Dodaj nowy termin": "Add new term",
        "Dodaj nowy wątek": "Add a new thread",
        "Dodaj plik": "Add a file",
        "Dodaj pliki": "Add files",
        "Dodaj pliki asystenta": "Add assistant files",
        "Dodaj podsumowanie": "Add summary",
        "Dodaj powiadomienie weryfikacyjne": "Add verification notification",
        "Dodaj pozycję": "Add item",
        "Dodaj przycisk `sprawdź odpowiedź ai`": "",
        "Dodaj przycisk z przekierowaniem do dokumentu": "Add a 'redirect to details' button",
        "Dodaj regułe": "Add a rule",
        "Dodaj rolę": "Add a role",
        "Dodaj scenariusz": "Add a scenario",
        "Dodaj sekcję": "Add a section",
        "Dodaj serwer": "Add server",
        "Dodaj shortcode": "Add shortcode",
        "Dodaj skan": "Scan adding",
        "Dodaj słownik": "Add dictionary",
        "Dodaj słowo": "Add the word",
        "Dodaj status": "Add status",
        "Dodaj stronę": "Add page",
        "Dodaj synonimy": "Add synonyms",
        "Dodaj szablon": "Add template",
        "Dodaj tabelę wektorową": "",
        "Dodaj termin": "Add term",
        "Dodaj token": "Add token",
        "Dodaj użytkownika": "Add user",
        "Dodaj webhooka": "Add a webhook",
        "Dodaj wersję": "Add version",
        "Dodaj widget": "Add widget",
        "Dodaj wiersz": "Add row",
        "Dodaj wpis": "Add entry",
        "Dodaj załączniki": "Add attachments",
        "Dodaj zastępstwo": "Add a replacement",
        "Dodaj zespół": "Add team",
        "Dodaj źródło": "Add source",
        "Dodaj źródło danych": "Add data source",
        "Dodają kierownicy wybranego działu": "Add the managers of the selected department",
        "Dodaje  każda osoba która ma uprawnienie (widzi moduł)": "Adds any person who has the authorization (sees the module)",
        "Dodaje członek grupy użytkowników": "Adds user group member",
        "Dodaje członek zespołu": "Adds team member",
        "Dodaje wybrany użytkownik": "Adds selected user",
        "dodał": "added",
        "dodane i edytowane przeze mnie": "added and edited by me",
        "Dodanie": "Adding",
        "Dodanie celu": "Add a target",
        "Dodanie dokumentu": "Addition of document",
        "Dodanie działu": "Add section",
        "Dodanie endpointu": "Adding endpoint",
        "Dodanie formularza": "Add form",
        "Dodanie klienta": "Add client",
        "Dodanie komponentu": "Adding a component",
        "Dodanie modułu": "Adding a module",
        "Dodanie roli": "Add role",
        "Dodanie scenariusza": "Adding a scenario",
        "Dodanie serwera": "Add server",
        "Dodanie spółki": "Adding the company",
        "Dodanie szablonu": "Add template",
        "Dodanie użytkownika": "Add user",
        "Dodanie webhooka": "Adding a webhook",
        "Dodanie wpisu": "Add entry",
        "Dodanie zastępstwa": "Add replacement",
        "Dodanie zespołu": "Add team",
        "Dodanie źródła": "Adding a source",
        "Dodanie źródła danych": "Add data source",
        "Dodano": "Added",
        "Dodano nową wersję": "New version added",
        "dodany": "added",
        "Dodanych plików %0 (limit wynosi %1).": "Added files %0 (limit is %1).",
        "Dodatkowa weryfikacja": "Additional verification",
        "Dodatkowe informacje": "Additional information",
        "Dodatkowe opcje": "Additional options",
        "Dodatkowo dostępny jest obiekt document zawierający dane dokumentu oraz obiekt data z polami ProcessId, ModuleId i ModuleVersionId.": "Additionally, there is a 'document' object that contains document data, and a 'data' object with 'ProcessId', 'ModuleId' and 'ModuleVersionId' fields.",
        "Dodawanie": "Adding",
        "Dodawanie dokumentów": "Adding documents",
        "Dodawanie i edycja własnych dokumentów": "Adding and editing your own documents",
        "Dodawanie modułu": "Adding a module",
        "Dodawanie wartości dwóch kolumn": "Adding values of two columns",
        "Dokument": "Document",
        "Dokument %0": "Document %0",
        "Dokument jest niepoprawnie opisany": "The document is incorrectly described",
        "Dokument już jest udostępniony": "The document is already available",
        "Dokument PDF": "PDF document",
        "Dokument powiązany": "Related document",
        "Dokument tekstowy": "Text document",
        "Dokument z pola": "Document from the field",
        "Dokument został powiązany.": "The document was linked.",
        "Dokument został udostępniony dla wybranych użytkowników.": "The document has been made available to selected users.",
        "Dokument został usunięty.": "The document has been removed.",
        "Dokumentacja": "Docs",
        "Dokumenty": "Documents",
        "Dokumenty kontrahenta": "Counterparty documents",
        "Dokumenty powiązane": "Related documents",
        "Dokumenty przekazane do wglądu na zasadzie powyższej nie będą dostępne z poziomu Zestawienia dokumentów i wyszukiwarki globalnej, a jedynie dostępne przez szczegóły dokumentu głównego.": "Documents submitted for review on the basis of the above will not be accessible from the Document Summary and Global Search, but only accessible through the master document details.",
        "Dołącz stack trace": "Append stack trace",
        "Domena": "Domain",
        "Domyślna": "Default",
        "Domyślna wartość": "Default value",
        "Domyślne": "Default",
        "Domyślne filtry": "Default filters",
        "Domyślnie ukryte": "Hidden by default",
        "Domyślnie widoczne": "By default visible",
        "Domyślny": "Default",
        "Domyślny pager": "",
        "Dopuść brak odpowiedzi": "Allow lack of response",
        "Dostawca": "Supplier",
        "Dostawca podpisu": "Signature provider",
        "Dostęp do modułu": "Access to the module",
        "Dostęp do systemu": "Access to the system",
        "Dostęp do zmiennych terminu słownika (np. stawka VAT)": "Access to properties of dictionary term (ie. VAT rate)",
        "Dostęp niezdefiniowany": "Undefined access",
        "Dostęp przyznany": "Access granted",
        "Dostęp zabroniony": "Access denied",
        "Dostępna jest nowa aktualizacja. Proszę zapisać postęp pracy i klinąć przycisk \"Aktualizuj\". Możesz również zaakceptować aktualizację poprzez odświeżenie okna przeglądarki. Brak aktualizacji może spowodować błędy.": "A new update is available. Please save the work progress and click the \"Update\" button. You can also accept the update by refreshing the browser. Noo update may result in errors.",
        "Dostępne": "Available",
        "Dostępne operatory": "Available operators",
        "Dostępne są propki Form i Entry zawierające odpowiednio strukturę formularza oraz dane z wypełnionego formularza": "Form and Entry props are available that contain the form structure and data from the completed form, respectively",
        "Dostępność": "Availability",
        "Dostępny dla modułu": "Available for the module",
        "Dostępny limit": "Available limit",
        "Dotyczy tylko dokumentów z modułów do których użytkownik ma dostęp": "Applies only to documents from modules to which the user has access",
        "Dotyczy wznawiania obiegu": "Refers to the resumption of circulation",
        "dowolnej wybranej wersji": "any selected version",
        "Dowolny": "Any",
        "Dozwolone rozszerzenia": "Allowed extensions",
        "Dozwolone rozszerzenia: %0.": "Allowed extensions: %0.",
        "Dozwolone są tylko małe i duże litery": "Only lowercase and uppercase letters are allowed",
        "Dozwolone są tylko małe litery i znak -": "Only lowercase letters and the character - are allowed",
        "Dozwolone typy plików: %0.": "Allowed file types: %0.",
        "Drugie imię": "Middle name",
        "Drugorzędny": "Secondary",
        "Dsn": "",
        "Duplikuj": "Duplicate",
        "Duża": "",
        "Duży": "Large",
        "Duży układ siatki": "Large grid layout",
        "Dwie kolumny": "Two columns",
        "Dynamicznie": "Dynamic",
        "Dyrektor": "Director",
        "Dyrektorzy": "Directors",
        "Dyrektywa max-age=N wskazuje, że odpowiedź pozostaje świeża do N sekund po wygenerowaniu odpowiedzi.": "The max-age=N directive indicates that the response remains fresh until N seconds after the response is generated.",
        "Dyrektywa no-store wskazuje, że jakikolwiek cache dowolnego rodzaju nie powinien przechowywać tej odpowiedzi.": "The no-store directive indicates that any cache of any kind should not store this response.",
        "Dyrektywa PRIVATE wskazuje, że odpowiedź może być przechowywana wyłącznie w prywatnej pamięci podręcznej (np. lokalnej pamięci przeglądarki).": "The PRIVATE directive indicates that the response can only be stored in a private cache (e.g. local browser memory).",
        "Dyrektywa PUBLIC wskazuje, że odpowiedź może być przechowywana we współdzielonej pamięci podręcznej (np. proxy).": "The PUBLIC directive indicates that the response can be stored in a shared cache (such as a proxy).",
        "Dyrektywa s-maxage jest ignorowana przez prywatne pamięci podręczne i zastępuje wartość określoną przez dyrektywę max-age dla współdzielonych pamięci podręcznych.": "The s-maxage directive is ignored by private caches and replaces the value specified by the max-age directive for shared caches.",
        "Dyrektywa s-maxage wskazuje, jak długo odpowiedź pozostaje aktualna we współdzielonej pamięci podręcznej (np. proxy).": "The s-maxage directive indicates how long a response remains valid in a shared cache (such as a proxy).",
        "Dyrektywy zakresu": "Scope directives",
        "Dział": "Department",
        "Dział aktywny": "Active section",
        "Dział nadrzędny": "Parent section",
        "Dział został dodany.": "The section has been added.",
        "Dział został zaktualizowany.": "The section has been updated.",
        "Działania masowe": "Mass actions",
        "Działy": "Departments",
        "Dzielenie": "Division",
        "Dziennik korespondencji": "Correspondence log",
        "Dzienny": "Daily",
        "dzień": "day",
        "Dzień": "Day",
        "dzień (dni)": "day(s)",
        "Dzień miesiąca": "Day of the month",
        "Dziś minus": "Today minus",
        "Dziś plus": "Today plus",
        "E-Doręczenia Ade": "E-Delivery Ade",
        "E-mail": "E-mail",
        "Edycja": "Edit",
        "Edycja celu": "Target edit",
        "Edycja dokumentów": "Edit documents",
        "Edycja dokumentu": "Edit document",
        "Edycja działu": "Edit section",
        "Edycja endpointu": "Edit endpoint",
        "Edycja formularza": "Edit form",
        "Edycja klienta": "Edit client",
        "Edycja kodu kontrolingowego": "Editing the controlling code",
        "Edycja komponentu": "Editing a component",
        "Edycja modułu": "Module editing",
        "Edycja roli": "Edit role",
        "Edycja scenariusza": "Editing the scenario",
        "Edycja serwera": "Edit server",
        "Edycja słownika": "Editing the dictionary",
        "Edycja szablonu": "Edit template",
        "Edycja terminu": "Edit date",
        "Edycja użytkownika": "Edit user",
        "Edycja w popupie": "Edit in popup",
        "Edycja webhooka": "Editing the webhook",
        "Edycja wpisu": "Edit post",
        "Edycja wszystkich dokumentów": "Edit all documents",
        "Edycja zastępstwa": "Edit substitution",
        "Edycja zespołu": "Edit team",
        "Edycja źródła": "Editing the source",
        "Edycja źródła danych": "Edit data source",
        "Edytor": "Editor",
        "Edytowana wersja": "Edited version",
        "Edytowane mogą być tylko szablony włączonych i obsługiwanych kanałów.": "Only templates for enabled and supported channels can be edited.",
        "Edytuj": "Edit",
        "Edytuj akcję": "Edit action",
        "Edytuj członka grupy": "Edit group member",
        "Edytuj danę wektorową": "Edit vector data",
        "Edytuj dashboard": "Edit dashboard",
        "Edytuj dokument": "Edit document",
        "Edytuj filtr": "Edit filter",
        "Edytuj kategorię": "Edit category",
        "Edytuj kontrahenta": "Edit contractor",
        "Edytuj pole": "Edit field",
        "Edytuj szablon": "Edit template",
        "Edytuj tabelę wektorową": "Edit the vector table",
        "Edytuj termin": "Edit term",
        "Edytuj uprawnienia": "Edit permissions",
        "Edytuj wersję": "Edit version",
        "Edytuj wpis": "Edit post",
        "Edytuj źródło danych": "Edit data source",
        "Eksport": "Export",
        "Eksport do arkusza Excel": "Export to Excel sheet",
        "Eksport do Excela": "Export to Excel",
        "Eksport dokumentów": "Exporting documents",
        "Eksport użytkowników": "Export users",
        "Eksport wykonany pomyślnie": "Export succeded",
        "Eksportuj": "Export",
        "Eksportuj do Excel": "Export to Excel",
        "Eksportuj wersję modułu do pliku": "Export module version to file",
        "Elastic": "Elastic",
        "Element sitemap": "Sitemap entry",
        "Element został przywrócony.": "The element has been restored.",
        "Element został usunięty.": "The element has been deleted.",
        "Elementu układu": "",
        "Email": "E-mail",
        "Email potwierdzony": "Email confirmed",
        "Endpoint": "Endpoint",
        "Endpoint został dodany.": "Endpoint has been added.",
        "Endpoint został zaktualizowany.": "Endpoint has been updated.",
        "Error - błedy wewnętrze SDK": "Error - internal SDK error",
        "Eskportuj": "Escort",
        "Etykieta": "Tag",
        "Etykiety": "Tags",
        "Export XML": "Export XML",
        "Exportuj do Excela": "Export to Excel",
        "Exportuj do pliku": "Export to file",
        "fakturę": "invoice",
        "Fatal - błedy krytyczne": "Fatal - critical errors",
        "Filtr dla synchronizacji działów": "Filter for department synchronization",
        "Filtr dla synchronizacji użytkowników": "Filter for user synchronization",
        "Filtr domyślny": "Default filter",
        "Filtr globalny": "Global filter",
        "Filtr po grupie": "Filter by group",
        "Filtr prywatny": "Private filter",
        "Filtr został usunięty": "Filter has been removed",
        "Filtr został zapisany": "Filter has been saved",
        "Filtrowanie": "Filtering",
        "Filtrowanie listy dokumentów": "Filtering a document list",
        "Filtruj": "Filter",
        "Filtruj po roli": "Filter by role",
        "filtry": "filters",
        "Filtry": "Filters",
        "Filtry globalne": "Global filters",
        "Filtry systemowe": "System filters",
        "Filtry wartościami pól formularza": "Filters with values of form fields",
        "Folder plików": "File folder",
        "Foldery": "Folders",
        "Format dokumentu": "Document format",
        "Formatowanie": "Formatting",
        "Formularz": "Modules / Contact form",
        "Formularz bazuje na nieaktualnej wersji definicji.": "The form is based on an outdated version of the definition.",
        "Formularz dynamiczny": "Dynamic form",
        "Formularz zarchiwizowany": "Archived form",
        "Formularz został dodany.": "The form has been added.",
        "Formularz został skopiowany.": "The form has been copied.",
        "Formularz został zaktualizowany.": "The form has been updated.",
        "Formularz został zapisany": "The form has been saved",
        "Formularze": "Forms",
        "Formularze i pola formularzy": "Forms and form fields",
        "Formuła jest nieprawidłowa": "The formula is incorrect",
        "Funkcja OCR nie jest włączona w konfiguracji systemu": "The OCR function is not enabled in system configuration",
        "Funkcja została dodana.": "Function was added",
        "Funkcja została usunięta.": "Function was deleted.",
        "Funkcja została zaktualizowana.": "Function was updated.",
        "Funkcje": "Functions",
        "Funkcje Asystent": "Assistant functions",
        "Funkcje asystenta": "Assistant functions",
        "Funkcje Asystenta": "Assistant functions",
        "Funkcje asystenta AI": "AI Assistant functions",
        "Funkcjonalności": "Features",
        "Generator obrazów AI": "AI image generator",
        "Generowanie obrazów": "Image generation",
        "Generowanie obrazów zakończone.": "Image generation finished.",
        "Generuj dokument z szablonu": "Generate a document from a template",
        "Generuj metadane": "Generate metadata",
        "Generuj metadane (AI)": "Generate metadata (AI)",
        "Generuj nowy obraz": "Generate new image",
        "Generuj obraz": "Generate image",
        "GET: Lista wpisów (sortowanie i stronicowanie)": "GET: List of entries (sorting and paging)",
        "GET: Pobranie danych": "GET: Downloading data",
        "GET: Pobranie wpisu": "GET: Downloading an entry",
        "GET: Schemat (definicja formularza)": "GET: schema (form definition)",
        "Godzina rozpoczęcia": "Start time",
        "Godzina zakończenia": "End time",
        "Godziny": "Hours",
        "Godziny robocze": "Working hours",
        "Google Translate V2": "",
        "GPT-4 Vision": "",
        "Graf statusów": "Status graph",
        "Grafika": "Graphics",
        "Grudzień": "December",
        "Grupa": "Group",
        "Grupa użytkowników": "User group",
        "grupę": "group",
        "Grupuj po": "Group by",
        "Grupuj po okresie": "Group by period",
        "Grupuj pola": "Group fields",
        "Grupy": "Groups",
        "Grupy użytkowników": "User groups",
        "Grupy użytkowników zostały zapisane": "User groups have been saved",
        "Harmonogram zadań": "Task scheduler",
        "Harmonogram został zmieniony": "The schedule has been revised",
        "Hasło": "Password",
        "Hasło (Klucz ukryty)": "Password (Hidden Key)",
        "Hasło musi zawierać co najmniej jeden znak specjalny np.: ~ @ # $ % ^ & *": "The password must contain at least one special character, e.g.: ~ @ $ % ^ & *",
        "Hasło użytkownika serwisowego": "Maintenace user password",
        "Hasło zostało zmienione. Zaloguj się ponownie": "The password has been changed. Sign in again. ",
        "Health": "Health",
        "Heatmapa dla wersji": "Heatmap for version",
        "Hierarchia": "Hierarchy",
        "Historia": "History",
        "Historia logowania": "Login history",
        "Historia zadania": "Task history",
        "Historia zapytań AI": "",
        "Host": "Host",
        "Host dodatkowy": "Secondary host",
        "Host główny": "Main host",
        "Id": "Id",
        "ID": "ID",
        "ID lub typ zadania": "ID or task type",
        "Id modelu: %0": "Model Id: %0",
        "Id obiektu": "Facility id",
        "Id sesji": "Session Id",
        "Id wpisu": "Entry id",
        "ID zadania": "Task ID",
        "Id zapytania": "Request Id",
        "Id zdarzenia": "Event Id",
        "Identyfikator": "Identifier",
        "Identyfikator przestrzeni (WorkspaceId)": "Workspace Id",
        "Identyfikator wdrożenia": "Deployment ID",
        "Identyfikator zadania": "Task ID",
        "Ikona": "Icon",
        "Ikona nagłówka": "Header icon",
        "Ikonka": "Icon",
        "Ilość cyfr w polu numer": "Number of digits in the number field",
        "Ilość kont": "Number of accounts",
        "Ilość modułów": "Number of modules",
        "Ilość wierszy": "Number of rows",
        "Ilość wyników do odrzucenia": "Number of results to be rejected",
        "Ilość wyników do pobrania": "Number of results to download",
        "Ilość znaków": "Number of characters",
        "Ilość znaków odpowiedzi": "Number of response characters",
        "Imię": "First name",
        "Imię i Nazwisko": "First name and last name",
        "Implementacja": "Implementation",
        "Implementacja metod/zmiennych/stałych, których chcemy używać w skrypcie": "Implementation of methods/variables/constants we want to use in the script",
        "Import": "Import",
        "Import danych": "Data import",
        "Import modułu": "Module import",
        "Import tabeli": "Table import",
        "Import użytkowników": "User import",
        "Import w trakcie przetwarzania...": "Imports under processing...",
        "Import wykonany pomyślnie": "Import performed successfully",
        "Import zakończony pomyślnie.": "Import completed successfully.",
        "Import został dodany.": "Import has been added.",
        "Import został odrzucony. Nie zaimportowanego żadnego wpisu": "The import was rejected. No entry imported",
        "Importer wprowadzi do systemu wszystkie wiersze, które przejdą walidację.": "The importer will enter into the system all lines that pass validation.",
        "Importuj": "Import",
        "Importuj definicję formularza": "Import form definition",
        "Importuj linie opisu z pliku": "Import description lines from a file",
        "Importuj linie z pliku": "Import lines from a file",
        "Importuj moduł z pliku": "Import a module from a file",
        "Importuj proces z pliku": "Import a process from a file",
        "Importuj wersję z pliku": "Import version from file",
        "Indeks": "Index",
        "Indeks został usunięty.": "The index has been removed.",
        "info": "info",
        "Info - informacje": "Info - informations",
        "Informacja": "Information",
        "Informacyjna": "Informational",
        "Inspekcja": "Inspection",
        "Instalacja": "Installation",
        "Instrukcja": "Manual",
        "Instrukcja modelu (prompt).": "Model manual (prompt).",
        "Interpreter kodu": "",
        "Interpreter kodu umożliwia asystentowi pisanie i uruchamianie kodu. To narzędzie może przetwarzać pliki z różnymi danymi i formatowaniem oraz generować pliki.": "",
        "Istniejące firmy": "Existing companies",
        "Jakość": "Quality",
        "Jakość HD 1024x1024": "",
        "Jakość HD 1024x1792 i 1792x1024": "",
        "Jakość standardowa 1024x1024": "",
        "Jakość standardowa 1024x1792 i 1792x1024": "",
        "Jakość wygnerowanego obrazu.": "",
        "Jasny": "Bright",
        "Jedna kolumna": "One column",
        "Jest autorem": "He is the author of",
        "Jeśli kwota przekracza 2000": "If amount exceeds 2000",
        "Jeśli pole jest puste, wówczas używana jest wartość domyślna. W przeciwnym wypadku wartość domyślna jest nadpisywana.": "If the field is empty, then the default value is used. Otherwise, the default value is overwritten.",
        "Jeśli użytkownik należy do grupy użytkowników modułu o id 456": "If user belongs to a user group with id 456",
        "Jeśli w polu wyboru wybrano opcję 1": "If option 1 was selected in a choice field",
        "Jeżeli": "If",
        "Język": "Language",
        "Kalendarz": "Calendar",
        "Kanał jest domyślnie wymagany. Nie da się wyłączyć wysyłania powiadomienia tym kanałem. Możliwa jest tylko zmiana tytułu i treści komunikatu.": "The channel is required by default. It is not possible to stop sending a notification through this channel. It is only possible to change the title and content of the message.",
        "kanał wymagany": "required channel",
        "Kanały": "Channels",
        "Kanały komunikacji": "Communication channels",
        "Kanały obsługiwane przez wybrany typ powiadmienia": "Channels supported by the selected notification type",
        "Kanały włączone w ustawieniach aplikacji": "Channels enabled in app settings",
        "Karta": "Card",
        "Katalog": "Directory",
        "Katalog główny": "Main directory",
        "Katalog został utworzony": "The directory has been created",
        "Katalog został utworzony.": "The directory has been created.",
        "Kategoria": "Category",
        "Kategoria archiwalna w innych komórkach": "Archive category in other cells",
        "Kategoria archiwalna w komórce macierzystej": "Archive category in parent cell",
        "Kategoria jest wymagana": "Category is required",
        "Kategoria została dodana.": "The category has been added.",
        "Kategoria została usunięta.": "The category has been removed.",
        "Kategoria została zaktualizowana.": "The category has been updated.",
        "Kategorie": "Categories",
        "Kierownicy": "Managers",
        "Kierownik": "Head",
        "Kierunek sortowania": "Sorting direction",
        "Klienci": "Clients",
        "Klient aktywny": "Active client",
        "Klient został dodany.": "The client has been added.",
        "Klient został usunięty.": "The client has been deleted.",
        "Klient został zaktualizowany.": "The client has been updated.",
        "Kliknij aby usunąć": "Click to delete",
        "Klucz": "Key",
        "Klucz API": "API key",
        "Klucz główny (PrimaryKey)": "",
        "Klucz prywatny": "Private key",
        "Klucz publiczny": "Public key",
        "Klucze": "Keys",
        "Klucze są nie prawidłowe.": "Keys are invalid.",
        "Klucze są prawidłowe.": "The keys are correct.",
        "Klucze unikalne": "Unique keys",
        "Kod": "Code",
        "Kod musi kończyć się wyrażeniem, które zwraca wartość typu boolean": "The code must end with an expression that returns a value of type boolean",
        "Kod musi zwracać kończyć się wyrażeniem, które zwraca wartość wybranego typu": "The code must return an expression that returns the value of the selected type",
        "Kod pocztowy": "Postal code",
        "Kod walidacji": "Validation code",
        "Kod wertfikacyjny": "Verification code",
        "Kod weryfikacyjny jest nieprawidłowy.": "The verification code is invalid.",
        "Kod został skopiowany do schowka.": "Code was copied to clipboard",
        "Kod źródłowy": "Source code",
        "Kody kontrolingowe": "Controlling codes",
        "Kody kontrollingowe": "Controlling codes",
        "Kolejka": "Queue",
        "Kolejne osoby w procesie": "More people in the process",
        "Kolekcja bazy wektorowej": "Vector base collection",
        "Kolor": "Color",
        "Kolor drugorzędny: %0": "Secondary color: %0",
        "Kolor etykiety": "Label color",
        "Kolor icon na panelu z aplikacjami: %0": "The color of the icon on the app panel: %0",
        "Kolor ikony": "Icon color",
        "Kolor liczby": "Color of the number",
        "Kolor podstawowy: %0": "Basic color: %0",
        "Kolor przewodni obrazu": "Leading image color",
        "Kolor tekstu aktywnej pozycji menu: %0": "The text color of the active menu item: %0",
        "Kolor tła panelu z aplikacjami: %0": "Background color of the application panel: %0",
        "Kolor wartości": "Value color",
        "Kolorowana konsola": "Colored console",
        "Kolory": "",
        "Kolory zostały przywrócone.": "The colors have been restored.",
        "Kolory zostały zapisane.": "The colors have been recorded.",
        "Kolumna": "Column",
        "Kolumna do zsumowania": "Column to be added up",
        "Kolumny": "Columns",
        "Kom": "Kom",
        "Kom.": "Mob.",
        "Komentarz": "Comments",
        "Komentarz został dodany.": "The comment was added.",
        "Komentarze": "Comments",
        "Komponent którego wartość zostanie użyta jako nazwa elementu nadrzędnego. Dostępne tylko komponenty typu ``Tekst``.": "Component of which value will be used as the name of parent element. Only text components are available.",
        "Komponent został dodany.": "The component has been added.",
        "Komponent został usunięty.": "The component has been removed.",
        "Komponent został zaktualizowany.": "The component has been updated.",
        "Komponent źródłowy": "Source component",
        "Komponenty": "Components",
        "Komunikat": "Message",
        "Komunikat błedu": "Error message",
        "Komunikat błędu": "Error message",
        "Komunikat walidacji": "Validation message",
        "Konfiguracja": "Settings",
        "Konfiguracja asystenta ai": "",
        "Konfiguracja dla stałych wartości, których chcemy używać w skrypcie": "Configuration for fixed values we want to use in the script",
        "Konfiguracja domyślna": "Default configuration",
        "Konfiguracja harmonogramu": "Schedule configuration",
        "Konfiguracja harmonogramu (czas UTC)": "Schedule configuration (UTC)",
        "Konfiguracja kolumn tabeli": "Table columns configuration",
        "Konfiguracja list": "List configuration",
        "Konfiguracja logów": "Logs configuration",
        "Konfiguracja strony": "Site configuration",
        "Konfiguracja zaawansowana": "Advanced configuration",
        "Konfiguracja zapytania": "Query configuration",
        "Konfiguracja została przywrócona dla reguł i celów": "Configuration was restored for targets and rules",
        "Koniec": "End",
        "Koniec po": "The end after",
        "Konta bez logowania": "Accounts without login",
        "Konta użytkowników": "User accounts",
        "Konta użytkowników zostały odblokowane": "User accounts have been unlocked",
        "Konta użytkowników zostały przypisane do zespołu": "User accounts have been assigned to the team",
        "Konta użytkowników zostały usunięte z systemu": "User accounts have been removed from the system",
        "Konta użytkowników zostały zablokowane": "User accounts have been blocked",
        "Kontakt": "Contact",
        "Kontener plików": "File container",
        "Konto aktywne": "Active account",
        "Konto bankowe": "Bank account",
        "Konto bez logowania": "Account without login",
        "Konto pozostanie nieaktywne dopóki adres e-mail nie zostanie potwierdzony": "Account will remain inactive until email address is confirmed",
        "Kontrahenci": "Vendors",
        "Kontrahent": "Vendor",
        "Kontrahent został nadpisany.": "The contractor has been overwritten.",
        "Kontrahent został usunięty.": "Vendor has been removed.",
        "Kontrahent został zapisany.": "The contractor has been enrolled.",
        "Kontrolka": "Light",
        "Kontrolka wyboru": "Choice control",
        "Kontrolki no-code": "No-code controls",
        "Kontrolki podstawowe": "Basic controls",
        "Kontrolki specjalizowane": "Specialized controls",
        "Konwertuj obrazy do formatu WebP": "Convert images to WebP",
        "Końcowa": "End",
        "Kopiuj": "Copy",
        "Kopiuj adres": "Copy address",
        "Kopiuj definicję": "Copy definition",
        "Kopiuj filtr": "Copy filter",
        "Kopiuj pole": "Copy field",
        "Kopiuj sygnaturę": "Copy signature",
        "Kopiuj z": "Copy from",
        "Kosz": "Bin",
        "Kraj": "Country",
        "Krok": "Step",
        "Krok minut": "Minute step",
        "Krok minut musi być liczbą z zakresu od 1 do 60.": "Minute step must be a number between 1 and 60.",
        "Krok procesu": "Process step",
        "Kroki": "Steps",
        "Krótki opis": "Short description",
        "KRS: ": "KRS:",
        "Kto dodaje": "Who adds",
        "Kto może udostępniać dokumenty": "Who can share documents",
        "Kto może wiązać dokumenty": "Who can bind documents",
        "Kurs": "Course",
        "Kursy walut": "Exchange rates",
        "Kwiecień": "April",
        "Kwota": "Amount",
        "Kwota brutto": "Gross amount",
        "Kwota budżetu": "Budget amount",
        "Kwota do walidacji tabeli opisu dokumentu": "Amount to validate document description table",
        "Kwota Limitu": "Limit Amount",
        "Kwota netto": "Net amount",
        "Kwota opisu nie jest zgodna z kwotą faktury. Różnica: ": "The description amount does not match the invoice amount. Difference: ",
        "LDAPS (LDAP over SSL)": "LDAPS (LDAP over SSL)",
        "Lewy panel": "Left panel",
        "Licencja": "License",
        "Licencja Logito": "Logito license",
        "Licencjobiorca": "Licensee",
        "Liczba": "Number",
        "Liczba całkowita": "Integer",
        "Liczba danych wejściowych podana w tokenach.": "",
        "Liczba danych wyjściowych podana w tokenach.": "",
        "Liczba dni": "Number of days",
        "Liczba dni na wykonanie kroku": "Number of days to take a step",
        "Liczba dziesiętna": "Decimal",
        "Liczba miesięcy": "Number of months",
        "Liczba wariantów": "",
        "Liczba wyników na stronie": "Number of results per page",
        "Licznik zależny od": "Counter dependent",
        "Liczniki": "Meters",
        "Lider": "Leader",
        "light": "light",
        "Limit": "Limit",
        "Limit API": "API Limit",
        "Limit został dodany.": "The limit has been added.",
        "Limit został zaktualizowany.": "The limit has been updated.",
        "Linia": "Line",
        "linie": "lines",
        "linię": "line",
        "Link do użycia": "Link to use",
        "Link skopiowany": "Link copied",
        "Lipiec": "July",
        "lista": "list",
        "Lista": "List",
        "Lista dozwolonych / wykluczonych żądań HTTP": "",
        "Lista efektywnych uprawnień użytkownika wynikających z uprawnień indywidualnych oraz ról.": "List of effective user permissions resulting from individual permissions and roles.",
        "Lista elementów zostanie zawężona tylko do tych, które są pozwiązane z wybranymi wartościami z zaznaczonych komponentów.": "",
        "Lista ID": "",
        "Lista indeksów": "List of indexes",
        "Lista jest pusta": "The list is empty",
        "Lista kontahentów": "List of counterparties",
        "Lista kontrahentów": "List of contractors",
        "Lista plików": "File list",
        "Lista powiadomień": "Notification list",
        "Lista pracowników": "List of employees",
        "Lista rozwijana": "Drop-down list",
        "Listopad": "November",
        "Listy": "Lists",
        "Litery": "Letters",
        "Litery i cyfry": "Letters and numbers",
        "Log Analytics": "",
        "Logi": "Logs",
        "Logi wydajnościowe": "Perfomance logs",
        "Login": "Login",
        "Logito": "Logito",
        "Logo": "Logo",
        "Logowanie": "Log in",
        "Logowanie zakończyło się niepowodzeniem.": "Login failed.",
        "Logowanie zależności / żądań typu HTTP": "",
        "Loguj wybrane": "Log selected",
        "Lokalne": "Local",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit.": "",
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit.": "",
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quam commodi autem id, veniam, illum temporibus.": "",
        "Lp.": "No.",
        "Luty": "February",
        "Łączenie treści": "Combining content",
        "Łączny czas": "Total time",
        "Ma dostęp do dokumentu": "It has access to the document",
        "Maj": "May",
        "Maksimum": "Maximum",
        "Maksymalna ilość plików": "Maximum number of files",
        "Maksymalna rozdzielość %0x%1px": "Maximum resolution %0 %1px",
        "Maksymalnie można wprowadzić 500 znaków": "A maximum of 500 characters can be entered",
        "Maksymalny rozmiar pliku w MB": "Maximum file size in MB",
        "Maksymalny rozmiar pliku: %0MB.": "Maximum file size: %0MB.",
        "Mała": "",
        "Małe znaki": "Small characters",
        "Mały": "Small",
        "Mały układ siatki": "Small grid layout",
        "Mappingi": "Mappings",
        "Marzec": "March",
        "Mediana": "Median",
        "Metadane": "Metadata",
        "Metoda": "Method",
        "Metoda autentykacji": "Authentication method",
        "Metoda musi zwracać obiekt \"entry.errors\".": "Method must return a \"entry.errors\" object.",
        "Metody": "Methods",
        "Miara podobieństwa (Wrtość od 1 do 5, gdzie 1 to największa dokładność)": "",
        "Miasto": "City",
        "Miejsca po przecinku": "Decimal points",
        "Miejscowość": "Town",
        "mies.": "months",
        "miesiąc": "month",
        "Miesiąc": "Month",
        "Miesiące": "Months",
        "Miesięczny": "Monthly",
        "Migracje": "Migrations",
        "Min. ilość elementów podobnych wymagana do automatyzacji": "Min. number of similar elements required for automation",
        "Miniaturki": "Thumbnails",
        "Minimalna długość hasła": "Minimum password length",
        "Minimum": "Minimum",
        "Minuty": "Minutes",
        "Mnożenie": "Multiplication",
        "Model": "Model",
        "Model danych": "Data model",
        "Moderacja danych wejściowych": "Moderation of input data",
        "Moduł": "Module",
        "Moduł dynamiczny": "Dynamic module",
        "Moduł jest wymagany": "Module is required",
        "Moduł statyczny": "Static module",
        "Moduł został dodany.": "The module has been added.",
        "Moduł został usunięty.": "The module has been removed.",
        "Moduł został wyeksportowany.": "The module has been exported.",
        "Moduł został zaktualizowany.": "The module has been updated.",
        "Moduł został zapisany.": "The module has been saved.",
        "Moduły": "Modules",
        "Modyfikacja": "Modification",
        "Modyfikacja dokumentów": "Document modification",
        "Modyfikuj istniejący wątek": "",
        "Moje aplikacje": "My Apps",
        "Moje dokumenty": "My Documents",
        "Moje filtry": "My filters",
        "Moje konto": "My account",
        "Moje pliki": "My files",
        "Monitor kolejek": "Queue monitor",
        "Monitoring": "Monitoring",
        "Monitoruj statystyki .NET": "",
        "Monitoruj żądania HTTP": "",
        "Może zarządzać dokumentami w rejestrze.": "It can manage the documents in the registry.",
        "Może zarządzać fakturami w rejestrze. Powiązane z uprawnieniami \"Może dodawać\", \"Koordynator\", \"Księgowa\".": "Can manage invoices in the register. Related to the permissions \"Can add\", \"Coordinator\", \"Accountant\".",
        "Może zarządzać pismami w rejestrze. Powiązane z opcjami dodawania, przypisywania po przyjęciu, edycji.": "Can manage letters in the register. Associated with options to add, assign after adoption, edit.",
        "Może zarządzać pismami w rejestrze. Powiązane z opcją dodawania pism. Edycja dostępna jest tylko dla autora. Wysyłanie i dodawanie zwrotek.": "Can manage letters in the register. Related to the option to add letters. Editing is only available to the author. Sending and adding returns.",
        "Może zarządzać rejestrem": "Can manage the registry",
        "Może zarządzać reklamacjami w rejestrze. Powiązane z uprawnieniami \"Może dodawać\", \"Koordynator\", \"Specialista przepływu statusów\".": "Can manage complaints in the registry. Related to the permissions \"Can add\", \"Coordinator\", \"Status flow specialist\".",
        "Może zarządzać umowami w rejestrze. Powiązane z opcją dodawania umów.": "Can manage contracts in the registry. Related to the option to add contracts.",
        "Możesz podać nową nazwę pliku.": "You can select a new filename.",
        "Możesz wybrać maksymalnie 10 elementów": "You can select up to 10 items",
        "Możliwość modyfikacji tego działu jest ograniczona. Nieaktywne pola są sychronizowane z zewnętrzną bazą danych %0.": "The possibility of modifying this section is limited. Inactive fields are synchronized with the external database %0.",
        "Możliwość modyfikacji tego konta jest ograniczona. Nieaktywne pola są sychronizowane z zewnętrzną bazą danych %0.": "The possibility to modify this account is limited. Inactive fields are synchronized with the external database %0.",
        "Możliwość modyfikacji tego konta jest ograniczona. Nieaktywne pola są synchronizowane z zewnętrzną bazą danych %0.": "The ability to modify this account is limited. Inactive fields are synchronized with an external database %0.",
        "Możliwość wielokrotnego wyboru": "Multiple choice",
        "Mój kalendarz": "My calendar",
        "Mój tytuł": "My title",
        "Musisz wybrać przynajmniej jedną grupę.": "Atleast one group must be chosen.",
        "Na diagramie procesu występują błędy. Sprawdź zakładkę \"Błędy\".": "There are errors on the process diagram. Check the \"Errors\" tab.",
        "Na pewno usunąć wybrane elementy?": "Are you sure to delete the selected elements?",
        "Na widoku nie będą dostępne akcje dokumentów łącznie z linkiem do szczegółów": "",
        "Nadaj dostęp do dokumentów powiązanych osobom, które mają dostęp do dokumentu głównego": "Grant access to related documents to people who have access to the main document",
        "Nadawanie uprawnień do dokumentów": "Granting rights to documents",
        "Nadawanie uprawnień do własnych dokumentów": "Assigning rights to your own documents",
        "Nadawanie uprawnień do wszystkich dokumentów": "Granting rights to all documents",
        "Nadawca": "Sender",
        "Nadpisz": "Overwrite",
        "Nagłówek": "Header",
        "Nagłówek dokumentu": "Document header",
        "Najstarsza wiadomość": "Oldest message",
        "Narzędzia": "Tools",
        "Narzędzie integruje się z platformą SMSAPI.pl": "",
        "Następna strona": "Next page",
        "Nazwa": "Name",
        "Nazwa (wymagane)": "Name (required)",
        "Nazwa aliasu": "Alias name",
        "Nazwa asystenta": "Assistant name",
        "Nazwa bazy danych": "Database name",
        "Nazwa działu": "Department name",
        "Nazwa filtra": "Filter name",
        "Nazwa formularza": "Form name",
        "Nazwa funkcji": "Function name",
        "Nazwa indeksu": "Index name",
        "Nazwa jest wymagana": "The name is required",
        "Nazwa katalogu": "Directory name",
        "Nazwa katalogu została zmieniona.": "The directory has been renamed.",
        "Nazwa klienta": "Client name",
        "Nazwa kolumny": "Column name",
        "Nazwa kolumny jest wymagana.": "The name of the column is required.",
        "Nazwa kolumny z opisem": "Description column name",
        "Nazwa kolumny z wartością": "Column name with value",
        "Nazwa komponentu": "Component name",
        "Nazwa konta (Klucz dostępu)": "Account Name (Access Key)",
        "Nazwa kontrahenta": "Vendor's name",
        "Nazwa modelu": "Model name",
        "Nazwa modułu": "Module name",
        "Nazwa musi mieć co najmniej 3 znaki": "The name must have at least 3 characters",
        "Nazwa musi mieć co najmniej 5 znaków": "The name must be at least 5 characters long",
        "Nazwa na grafie": "Name on the graph",
        "Nazwa nadawcy": "Sender name",
        "Nazwa narzędzia": "Tool name",
        "Nazwa nie może być dłuższa niż 30 znaków": "The name must not be longer than 30 characters",
        "Nazwa nie może być dłuższy niż 50 znaków": "The name must not be longer than 50 characters",
        "Nazwa pliku": "File name",
        "Nazwa pliku została zmieniona.": "The file has been renamed.",
        "Nazwa pola": "Field name",
        "Nazwa pola na formularzu": "Name of the field on the form",
        "Nazwa powiadomienia": "Notification name",
        "Nazwa przycisku": "Button name",
        "Nazwa przycisku ``Dalej``": "Button name ''Next''",
        "Nazwa przycisku ``Wstecz``": "Button name ''Back''",
        "Nazwa przycisku ``Wyślij``": "Button name ''Send''",
        "Nazwa roli": "Role name",
        "Nazwa sekcji w menu": "Name of the section in the menu",
        "Nazwa sekcji...": "Section name...",
        "Nazwa serii": "Series name",
        "Nazwa serwera": "Server name",
        "Nazwa skrócona": "Shortened name",
        "Nazwa spółki": "Company name",
        "Nazwa szablonu": "Template name",
        "Nazwa typu": "Type name",
        "Nazwa urządzenia": "Device name",
        "Nazwa użytkownika": "User name",
        "Nazwa użytkownika serwisowego": "Maintenance user name",
        "Nazwa w indeksie": "Name in the index",
        "Nazwa wątku": "",
        "Nazwa webhooka": "Webhook name",
        "Nazwa wersji": "Version name",
        "Nazwa zadania": "Task name",
        "Nazwa zakładki": "Bookmark name",
        "Nazwa zasobu": "Name of the resource",
        "Nazwa zespołu": "Team name",
        "Nazwa źródła": "Source name",
        "Nazwa źródła danych": "Data source name",
        "Nazwisko": "Last name",
        "Nazwy wartości": "Names of values",
        "Nicość - nie zapisuj": "Blackhole - don't save",
        "Nie": "No",
        "Nie loguj wybranych": "Don't log selected",
        "Nie ma użytkowników, którym można odebrać uprawnienia do dokumentu": "There are no users who can have their document privileges revoked",
        "Nie masz uprawnień do panelu administracyjnego.": "You do not have permission to access the administration panel.",
        "Nie masz uprawnień do tego dokumentu": "You don't have the authority for this document",
        "Nie masz uprawnień do tego widoku. Stan widoku mógł ulec zmianie, odśwież i sprawdź lub spróbuj ponownie później.": "You do not have permissions for this view. The state of the view may have changed, refresh and check or try again later.",
        "Nie możesz dodać tego samego widgetu dwa razy": "You can't add the same widget twice",
        "Nie możesz modyfikować profilu zastępowanego użytkownika.": "You cannot modify the profile of the replaced user.",
        "Nie możesz modyfikować uprawnień": "You cannot modify permissions",
        "Nie można usunąć swojego konta.": "You can't delete your account.",
        "Nie odnaleziono kontrahenta o podanym numerze NIP w bazie GUS.": "A contractor with the specified TIN number was not found in the CSO database.",
        "Nie podano ID procesu, którego dotyczy formularz": "The ID of the process to which the form applies was not provided",
        "Nie podano wymaganej ilości znaków: %0.": "The required number of characters was not given: %0.",
        "Nie udało się pobrać akcji": "Failed to download action",
        "Nie udało się pobrać historii": "Failed to download history",
        "Nie udało się pobrać listy celów": "Failed to load targets list",
        "Nie udało się pobrać listy działów": "Failed to download the sections list",
        "Nie udało się pobrać listy filtrów": "Failed to download filter list",
        "Nie udało się pobrać listy kanałów": "Failed to download channel list",
        "Nie udało się pobrać listy kolejek": "Failed to download queue list",
        "Nie udało się pobrać listy powiadomień": "Failed to download notification list",
        "Nie udało się pobrać listy ról": "Failed to download role list",
        "Nie udało się pobrać listy serwerów baz danych": "Failed to retrieve the database server list",
        "Nie udało się pobrać listy serwerów plików": "Failed to download the file server list",
        "Nie udało się pobrać listy spółek": "Failed to download the list of companies",
        "Nie udało się pobrać listy typów danych.": "The list of data types could not be retrieved.",
        "Nie udało się pobrać listy typów modeli": "Failed to download the list of model types",
        "Nie udało się pobrać listy wpisów.": "The list of entries could not be downloaded.",
        "Nie udało się pobrać listy wydziałów": "Failed to download the list of sections",
        "Nie udało się pobrać listy zespołów": "Failed to download the list of teams",
        "Nie udało się pobrać obrazu.": "Failed to load the image",
        "Nie udało się pobrać powiadomień.": "Failed to download notifications.",
        "Nie udało się pobrać szczegółów": "Failed to download details",
        "Nie udało się pobrać url do podpisu dokumentu": "Failed to download url for document signature",
        "Nie udało się pobrać ustawień kont użytkowników": "Failed to download user account settings",
        "Nie udało się pobrać widgetu/ów": "Failed to download widget(s)",
        "Nie udało się usunąć szablonu": "Failed to delete template",
        "Nie udało się utworzyć nowej wersji": "Failed to create new version",
        "nie udało się wykonać akcji": "failed to perform the action",
        "Nie udało się wykonać akcji": "Failed to perform the action",
        "Nie udało się wysłać pliku": "Failed to send file",
        "Nie udało się załadować widgetu AI.": "",
        "Nie udało się zapisać filtra": "Failed to save filter",
        "Nie udało się zapisać formularza": "",
        "Nie udało się zapisać szablonu": "Failed to save template",
        "Nie wszystkie pola zostały wypelnione prawidłowo.": "Not all fields were filled out correctly.",
        "Nie wszystkie pola zostały wypełnione prawidłowo.": "Not all fields were filled in correctly.",
        "Nie wszystkie wybrane elementy zostały przeniesione. Nie posiadasz wymaganych uprawnień lub, w przypadku przenoszenia katalogów, katalog docelowy jest podkatalogiem katalogu źródłowego. Katalogi i pliki których nie udało się przenieść pozostały nadal w zakładce Wybrane.": "Not all selected items have been transfered. You do not have the required permissions or, if you are moving directories, the destination directory is a subdirectory of the source directory. Directories and files that could not have been transfered remain in the Selected tab.",
        "Nie wszystkie wymagane pola zostały wypełnione": "Not all required fields have been completed",
        "Nie wybrano żadnego użytkownika.": "No user has been selected.",
        "Nie wybrano żadnych filtrów": "No filters selected",
        "Nie wymaga uwierzytelniania": "No authentication required",
        "nie wymuszaj zmiany hasła": "do not force password change",
        "Nie zdefiniowano żadnych ról.": "No roles are defined.",
        "Nie zdefiniowano żadnych spółek.": "No companies have been defined.",
        "Nie znaleziono dostępnych widgetów": "No available widgets found",
        "Nie znaleziono filtrów.": "No filters found.",
        "Nie znaleziono formularza.": "The form was not found.",
        "Nie znaleziono kolumn.": "No columns found.",
        "Nie znaleziono pasującego widgetu": "No matching widget found",
        "Nie znaleziono pasujących elementów.": "No matching items found.",
        "Nie znaleziono żadnego pola.": "No field found",
        "Nie znaleziono żadnych akcji": "No shares found",
        "Nie znaleziono żadnych plików": "No files found",
        "Nie znaleziono żadnych użytkowników.": "No users were found.",
        "Nie znaleziono żadnych wyników.": "No results found.",
        "Nieaktywne": "Inactive",
        "Nieaktywni": "Inactive",
        "Nieaktywny": "Inactive",
        "niedozwolone rozszerzenie pliku (%0)": "file extension not allowed (%0)",
        "niedozwolony typ pliku (%0)": "file type not allowed (%0)",
        "Niedziela": "Sunday",
        "Nieobsłużony status importu.": "Unsupported import status.",
        "Nieopublikowany": "Unpublished",
        "Niepoprawna nazwa kolumny.": "Incorrect column name.",
        "Niepoprawne wyrażenie regularne": "Incorrect regular expression",
        "Niepotwierdzeni": "Unconfirmed",
        "Nieprawidłowa definicja": "Incorrect definition",
        "Nieprawidłowy adres email": "Invalid email address",
        "Nieprawidłowy adres URL": "Invalid URL",
        "Nieprawidłowy format dokumentu. Aby podpisać dokument przekonwertuj go na format PDF": "Incorrect document format. To sign a document convert it to PDF",
        "Nieprzeczytane": "Unread",
        "Niestandardowy błąd": "Non-standard error",
        "Nieudane": "Failed",
        "Nieudane próby logowania": "Failed login attempts",
        "Niewidoczne": "Not visible",
        "Niezgodne sumy kwot wg stawek vat z tabelką VAT": "Inconsistent sums of amounts by vat rates with VAT table",
        "Nieznane narzędzie": "",
        "Nieznany model": "",
        "Nieznany typ pliku": "Unknown file type",
        "Nigdy": "Never",
        "Nikomu nie przydzielono uprawnień": "No one has been granted permissions",
        "NIP": "Tax ID Number",
        "NIP: ": "TAX ID:",
        "no-store (wyłącza cache)": "no-store (disables cache)",
        "Nowa data": "New date",
        "Nowe hasło": "New password",
        "np. (&(objectClass=organizationalUnit))": "e.g. (&(objectClass=organizationalUnit))",
        "np. (&(objectClass=user)": "e.g. (&(objectClass=user)",
        "Nr budynku": "Building number",
        "Nr konta bankowego": "Bank Account No",
        "Nr lokalu": "Apt. no.",
        "Nr telefonu": "Phone number",
        "Numer budynku": "Building number",
        "Numer dokumentu będzie uzupełniany zerami wiodącymi": "The document number will be completed with leading zeros",
        "Numer domu": "House no",
        "Numer kolejny": "Sequence number",
        "Numer KRS": "KRS Number",
        "Numer lokalu": "Location",
        "Numer mieszkania": "Flat no",
        "Numer pokoju": "Room number",
        "Numer REGON": "REGON number",
        "Numer sesji": "Session number",
        "Numer telefonu": "Phone number",
        "Numeruj używając licznika wg daty": "Number using the counter by date",
        "OAuth2 dla Exchange Online": "OAuth2 for Exchange Online",
        "Obecnie edytowana wersja procesu jest używana w wersjach modułu": "The currently edited version of the process is used in versions of the module",
        "Obiekt": "Item",
        "Obiekt %0 został %1": "Object %0 zostood %1",
        "Obiekt ma strukturę Dictionary<string, object>[] z C#": "The object has the Dictionary<string, object>[] structure from C#",
        "Obliczenie godzin między datami z pierwszego i ostatniego wiersza": "Calculation of the hours between the dates of the first and last lines",
        "Obok siebie": "Next to each other",
        "Obraz został pomyślnie opisany": "Image was successfully saved",
        "Obraz został wycięty.": "The image has been cut out.",
        "Obraz został wygenerowany.": "The image has been generated.",
        "Obsługiwane formaty plików: txt, md, pdf, docx.": "Supported file formats: txt, md, pdf, docx.",
        "OCR": "OCR",
        "Oczekuje na akceptacje": "Awaiting approval",
        "Oczekuje na wysłanie": "To be shipped",
        "od": "from",
        "Od": "From",
        "od %0": "from %0",
        "Odbierz uprawnienia do dokumentu": "Receive document privileges",
        "Odbierz uprawnienie": "Revoke entitlement",
        "Odbiorca": "Recipient",
        "Odblokuj": "Unblock",
        "Odejmowanie": "Subtraction",
        "Odpowiedzi": "Responses",
        "Odpowiedzi: %0 na %1": "Responses: %0 for %1",
        "Odpowiedź": "Answer",
        "Odpowiedź dowolna": "Any answer",
        "Odrzucone": "Rejected",
        "Odrzucony": "Rejected",
        "Odrzuć": "Reject",
        "Odstęp": "Space",
        "Odśwież": "Refresh",
        "Odtwórz indeks": "Recreate index",
        "Odtwórz wszystkie indeksy": "Recreate all indices",
        "Odwołanie do klucza pierwszego wybranego terminu słownika w polu Słownik Logito": "Reference to the key of the first selected dictionary term in the Logito dictionary field",
        "Odwołanie do pól terminu słownika (np. stawka VAT)": "Reference to properties of dictionary term (ie. VAT rate)",
        "Odwołanie do wartości pola numerycznego": "Reference to the value of a numeric field",
        "Odznacz": "Unselect",
        "Odznacz wszystkie": "Unselect all",
        "Ogólne": "General",
        "Ok": "Ok",
        "Omnitool": "",
        "OneDrive": "OneDrive",
        "Opcje": "Options",
        "OpenAI": "OpenAI",
        "Operacje": "Operations",
        "Opiekun": "Guardian",
        "Opis": "Description",
        "Opis błędu": "Error message",
        "Opis dokumentu": "Document description",
        "Opis faktury": "Invoice description",
        "Opis funkcji": "",
        "Opis kodami kontrolingowymi": "Description by controlling codes",
        "Opis kompletny": "Complete description",
        "Opis obrazu": "Image description",
        "Opis pola (drobny tekst pod polem na formularzu)": "Field description (small text below the field on the form)",
        "Opis zespołu": "Team description",
        "Opis źródła": "Source description",
        "Opisy": "Descriptions",
        "Optymalizuj rozdzielczość obrazów": "Optimize image resolution",
        "Opublikowano wersję": "A version has been published",
        "Opublikowany": "Published",
        "Oraz hasło": "And the password",
        "Oraz nowe hasło": "And a new password",
        "Organizacja": "Organization",
        "Organizacja została usunięta.": "The organization has been removed.",
        "Orientacja wydruku": "Print orientation",
        "Osoba": "Person",
        "Osoba zastępowana": "Person being substituted",
        "Osoba zastępująca": "Substitute",
        "Osoby które mają dostęp do dokumentu": "Those who have access to the document",
        "Osoby które realizują obecnie zadanie w obiegu dokumentu": "People who are currently carrying out the task in the document circulation",
        "Osoby zaproszone": "Invited persons",
        "Ostatnia aktywność": "Recent activity",
        "Ostatnia strona": "Last page",
        "ostatnie sprawdzenie": "last check",
        "Ostrzeżenia": "Warnings",
        "Ostrzeżenie": "Warning",
        "Otwórz": "Open",
        "Otwórz licznik na kolejny rok": "Open the meter for another year",
        "Otwórz wyszukiwarkę": "Open search engine",
        "Oznacz": "Mark",
        "Oznacz jako nieodczytany": "Mark as unread",
        "Oznacz jako nieprzeczytane": "Mark as unread",
        "Oznacz jako przeczytane": "Mark as read",
        "Oznacz powiadomienia": "Mark notifications",
        "Oznacz wszystkie jako przeczytane": "Mark all as read",
        "Oznacz wybrane jako nieprzeczytane": "Mark selected as unread",
        "Oznacz wybrane jako przeczytane": "Mark selected as read",
        "Paleta kolorów": "Color palette",
        "Parametry funkcji": "",
        "Parametry, wymagane do wykonania zapytania:": "Parameters required to execute the request:",
        "Pasek": "Bar",
        "Pasek postępu": "Progress bar",
        "Październik": "October",
        "Pełne dane": "Full data",
        "Pełny": "Full",
        "PESEL": "Employer identification number",
        "PESEL: ": "PESEL:",
        "PH": "PH",
        "Piątek": "Friday",
        "Pierwiastkowanie wartości kolumny": "Priming of column values",
        "Pierwsza strona": "First page",
        "Pigułki": "Pills",
        "Piktogram": "Pictogram",
        "pismo przychodzące": "incoming letter",
        "Plik audio": "Audio file",
        "Plik binarny": "Binary file",
        "Plik CSS": "CSS file",
        "Plik CSV": "CSV file",
        "Plik DOC": "DOC file",
        "Plik DOCX": "DOCX file",
        "Plik GIF": "GIF file",
        "Plik HTML": "HTML file",
        "Plik importowy": "Import file",
        "Plik jest wysyłany": "The file is being sent",
        "Plik JPEG": "JPEG file",
        "Plik JPG": "JPG file",
        "Plik MP4": "MP4 file",
        "Plik PNG": "PNG file",
        "Plik skompresowany": "Compressed file",
        "Plik tekstowy json": "Json text file",
        "Plik tekstowy txt": "Txt text file",
        "Plik zawiera niepoprawne dane w komórkach": "File contains incorrect data in cells",
        "Plik ZIP": "ZIP file",
        "Plik został dodany.": "File has been added,",
        "Plik został usunięty.": "File has been deleted.",
        "Plik został wysłany": "The file has been sent",
        "Pliki": "Files",
        "Pliki Asystent": "",
        "Pliki Asystenta": "",
        "Pliki asystenta AI": "",
        "Pliki do wysłania: %0": "Files to send: %0",
        "Pliki prywatne": "Private files",
        "Pliki publiczne": "Public files",
        "Pliki współdzielone": "Shared files",
        "Pliki: %0": "Files: %0",
        "po %0 nieudanych próbach logowania": "after %0 unsuccessful login attempts",
        "Po jednym synonimie w wierszu": "One synonym per line",
        "Po wybraniu tej opcji konta użytkowników zostaną usunięte z systemu. Czy chcesz kontynuować?": "If you select this option, user accounts will be deleted from the system. Do you want to continue?",
        "Po zakończonym imporcie wyświetli się podsumowanie o ilości zaimportowanych/odrzuconych rekordów oraz lista błędów dla wierszy odrzuconych": "After the import is complete, you will see a summary about the number of imported/rejected records and a list of errors for rejected rows",
        "Po zaznaczeniu tej opcji uprawnienia indywidualne i pochodzące z ról nie są brane pod uwagę.": "When you select this option, individual and role permissions will not be considered.",
        "Pobierz": "Download",
        "Pobierz dane z GUS": "Download data from CSO",
        "Pobierz szablon": "Download the template",
        "Początkowa ilość wierszy": "Initial number of rows",
        "Poczta": "Mail",
        "Podaj NIP": "Enter TIN",
        "Podaj nową nazwę": "Enter a new name",
        "Podaj swój adres email": "Enter your email address",
        "Podana nazwa jest zajęta.": "The name you entered is already taken.",
        "Podana wartość jest nieprawidłowa.": "The provided value is not valid.",
        "Podanie nazwy jest wymagane.": "Name is required.",
        "Podano adres": "The address provided is",
        "Podano nieprawidłowe dane": "Invalid data provided.",
        "Podano nieprawidłowe dane.": "Invalid data provided.",
        "Podczas pierszego uruchomienia wymagane jest utworzenie konta głównego administratora.": "During the first startup, it is required to create a master administrator account.",
        "Podgląd": "Preview",
        "Podgląd dokumentu": "Document preview",
        "Podgląd formularza": "Form preview",
        "Podgląd jest dostępny jedynie po zapisaniu szablonu": "Preview is only available after saving the template",
        "Podgląd niedostępny w trybie projektowania": "Preview not available in design mode",
        "Podgląd przycisku": "Button preview",
        "Podgląd tabeli": "Table preview",
        "Podkreślenie": "Highlight",
        "Podpis": "Signature",
        "Podpisz dokument": "Sign the document",
        "Podstawowe": "Basic",
        "Podstawowe operacje na datach": "Basic date operations",
        "Podstawowe operacje na liczbach": "Basic digits operations",
        "Podstawowe operacje tekstowe": "Basic text operations",
        "Podstawowe operacje warunkowe": "Basic conditional operations",
        "Podstawowy": "Basic",
        "Podwładni": "Subordinates",
        "Podziedziałek - Piątek": "Monday - Friday",
        "Podziel widok terminarza wg": "Split the schedule view by",
        "Pogrubienie etykiety": "Label bold",
        "Pogrubienie wartości": "Value bold",
        "Pojedynczy dokument": "Single document",
        "Pojedynczy wybór": "Single choice",
        "Pokaż": "Show me",
        "Pokaż etykietę": "Show tag",
        "Pokaż etykietę nad wartością": "Show label over value",
        "Pokaż etykiety bez wartości": "Show labels without value",
        "Pokaż filtry": "Show filters",
        "Pokaż filtry na liście": "Show filters in the list",
        "Pokaż fukncję dowiązania dokumentów wg uprawnień": "Show document link function by authorization",
        "Pokaż godziny robocze": "Show business hours",
        "Pokaż heatmapę": "Show heatmap",
        "Pokaż historię": "Show history",
        "Pokaż kolumnę z liczbą porządkową": "",
        "Pokaż numery stron": "Show page numbers",
        "Pokaż pager": "Show pager",
        "Pokaż pełne godziny": "Show full hours",
        "Pokaż przycisk ``Wyślij``": "Show ''Send'' button",
        "Pokaż przycisk dla akcji": "Show button for action",
        "Pokaż przycisk dodawania": "Show 'add' button",
        "Pokaż przycisk operacji grupowych": "Show group operations button",
        "Pokaż tylko moje dokumenty": "Show only my documents",
        "Pokaż tytuł formularza": "Show form title",
        "Pokaż tytuł sekcji": "Show section title",
        "Pokaż tytuł strony": "Show page title",
        "Pokaż tytuły stron": "Show page titles",
        "Pokaż w koncie użytkownika": "Show in user account",
        "Pokaż wartości na wykresie": "Show values on the chart",
        "Pokaż więcej": "Show more",
        "Pokaż więcej komentarzy": "Show more comments",
        "pokaż więcej...": "show more...",
        "Pokaż załączniki dokumentów powiązanych": "Show attachments of related documents",
        "Pokaż załączniki dokumentu": "Show document attachments",
        "Pokój": "Room",
        "Pola": "Campos",
        "Pola do wyświetlenia": "Fields to be displayed",
        "Pola do zgrupowania": "Fields for grouping",
        "Pola dokumentu": "Document fields",
        "Pola formularzy": "Form fields",
        "Pola mapowane": "Mapped fields",
        "Pola mapowane (excel)": "Mapped fields (excel)",
        "Pola należące do grupy": "Fields belonging to the group",
        "Pola systemowe": "System fields",
        "Pola tekstowe": "Text fields",
        "Pola wspólne": "Common fields",
        "Pola wyliczane": "Enumerated fields",
        "Pola zgrupowane": "Grouped fields",
        "Pola zostały zgrupowane.": "The fields have been grouped together.",
        "Pole": "Field",
        "Pole \"%0\" jest wymagane.": "Field ''%0'' is required.",
        "Pole \"%0\" nie zawiera prawidłowego adresu email.": "Field ''%0'' does not contain a valid email address.",
        "Pole \"%0\" nie zawiera prawidłowego adresu URL.": "Field ''%0'' does not contain a valid URL address.",
        "Pole \"%0\" zawiera niedozwoloną wartość.": "File \"%0\" contains illegal value.",
        "Pole \"Drugie imię\" jest wymagane.": "''Middle name'' is required.",
        "Pole \"Imię\" jest wymagane.": "''Name'' is required.",
        "Pole \"Nazwisko\" jest wymagane.": "''Surname'' is required.",
        "Pole \"Tytuł\" jest wymagane.": "''Title'' is required.",
        "Pole do filtrowania przy imporcie tabeli": "Field to filter when importing a table",
        "Pole formularza": "Form field",
        "Pole jest wymagane": "The field is required",
        "Pole może zawierać jedynie cyfry.": "Field can only contain numbers.",
        "Pole może zawierać jedynie litery i cyfry.": "This field can contain only letters and numbers.",
        "Pole może zawierać jedynie litery.": "This field can only contain letters.",
        "Pole może zawierać tylko ciało metody walidującej dane w postaci natywnego kodu JavaScript.": "",
        "Pole na zapis wydruku": "Field for recording the printout",
        "Pole nie może zawierać spacji": "The field must not contain spaces",
        "Pole powiązania dokumentu": "Document linkage field",
        "Pole priorytetowe": "Priority field",
        "Pole skopiowane": "Field copied",
        "Pole tekstowe": "Text field",
        "Pole typu \"%0\" jest niedozwolone w tym formularzu.": "Field type \"%0\" is not allowed in this form.",
        "Pole wspólne zostało dodane.": "Shared field has been added.",
        "Pole wspólne zostało usunięte": "Shared field has been deleted.",
        "Pole wspólne zostało zaktualizowane.": "Shared field has been updated.",
        "Pole wymagane": "Field required",
        "Pole z datą": "Date field",
        "Pole z datą wg której liczymy kurs": "Field with the date by which we count the rate",
        "Pole z dokumentem": "Field with document",
        "Pole z tabelą VAT": "Field with VAT table",
        "Pole zawiera": "Field contains",
        "Pole zostało rozgrupowane.": "The field has been ungrouped.",
        "Pole zostało zaktualizowane.": "The field has been updated.",
        "Polski": "Polish",
        "Pomiędzy": "Between",
        "Pomijanie kolejkowania": "Skip queue",
        "Pomiń pierwszy wiersz excela": "Skip the first line of excel",
        "Pomiń wysłanie powiadomienia w przypadku braku dokumentów": "Don't send notifications if there's no documents to send",
        "Pomoc": "Help",
        "Pomyślnie przywrócono konfigurację domyślną": "Successfully restored the default configuration",
        "Pomyślnie uruchomiono proces reindeksacji": "Successfully started reindexation",
        "Pomyślnie usunięto rekord": "Successfully deleted the record",
        "Pomyślnie usunięto wersje": "Successfully removed versions",
        "Pomyślnie zapisano konfigurację filtrów": "Successfully saved filter configuration",
        "Pomyślnie zapisano konfigurację kolumn": "Successfully saved the column configuration",
        "Poniedziałek": "Monday",
        "Poprawne": "Correct",
        "Poprawny": "Correct",
        "Poprzednia strona": "Previous page",
        "Porównaj %0": "Compare %0",
        "Porównaj z edytowaną wersją": "Compare with edited version",
        "Porównania": "Comparison",
        "Porównanie": "Comparison",
        "Porównywana wersja": "Compared version",
        "Port": "Port",
        "Posiada załączniki": "It has attachments",
        "POST: Lista wpisów (dowolne wyszukiwanie)": "POST: List of entries (any search)",
        "POST: Upload załączników": "POST: Upload attachments",
        "POST: Utworzenie wpisu": "POST: Create an entry",
        "POST: Zapisanie zmian": "POST: Save changes",
        "Potwierdzenia": "Confirmations",
        "Potwierdzenie przywrócenia": "Confirmation of restoration",
        "Potwierdzenie usunięcia": "Deletion confirmation",
        "Potwierdzenie wylogowania użytkownika": "Confirmation of user logout",
        "Potwierdzenie zmiany statusu": "Confirmation of status change",
        "Potwierdź": "Confirm",
        "Potwierdź akceptację": "Confirm acceptance",
        "Powiadomienia": "Notifications",
        "Powiadomienia weryfikacyjne": "Verification notifications",
        "Powiadomienia zbiorcze": "Bulk notifications",
        "Powiadomienia zostały skonfigurowane.": "Notifications have been configured.",
        "Powiadomienie jest domyślnie wymagane. Nie da się wyłączyć wysyłania tego powiadomienia.": "Notification is required by default. You cannot stop this notification to appear.",
        "Powiadomienie testowe zostało wysłane.": "The test notification has been sent.",
        "Powiadomienie weryfikacyjne": "Verification notification",
        "Powiadomienie zostało usunięte": "Notification was deleted",
        "Powiąż": "Tie",
        "Powiąż dokument": "Bind the document",
        "Powiąż z polem typu grupa użytkowników": "Link with a 'user group' field",
        "Powiąż ze spółką": "Tie up with the company",
        "Powiększ": "Zoom in",
        "Powinno się dodać dyrektywę PRIVATE dla treści personalizowanych dla użytkownika, zwłaszcza dla odpowiedzi otrzymywanych po zalogowaniu i z uwzględnieniem uprawnień.": "A PRIVATE directive should be added for user-personalized content, especially for responses received after login and with permissions.",
        "powrót": "return",
        "Powrót": "Back",
        "Powtarzaj co": "Repeat every",
        "Powtórz hasło": "Repeat password",
        "Powtórzone": "Repeated",
        "Powyższe obiekty zawierają tylko właściwości i są pozbawione wszystkich metod.": "",
        "Poziom dostępu do webhooka": "",
        "Poziom dostępu użytkownika": "User access level",
        "Poziom logowania błedów": "",
        "Poziomy logowania": "",
        "Pozostałe": "Remaining",
        "Pozostało %0 znaków.": "There are %0 characters left.",
        "Pozwalaj na grupowe wykonanie akcji": "Allow group execution of actions",
        "Pozwalaj wiązać tylko niezakończone dokumenty": "Allow only unfinished documents to be bound",
        "Pozwól dodawać terminy użytkownikom": "Allow users to add terms",
        "Pozwól na dodawanie wierszy": "Allow rows to be added",
        "Pozwól na uzupełnianie danych z pliku excel": "Allow data completion from excel file",
        "Pozycja": "Position",
        "pół roku": "six months",
        "Pracownicy": "Employees",
        "Pracownik": "Employee",
        "Prawy panel": "Right panel",
        "Predefiniowane tła panelu z aplikacjami": "Predefined backgrounds of the panel with applications",
        "Predefiniowane zestawy kolorów": "Predefined color sets",
        "Prezentacja": "Presentation",
        "primary": "primary",
        "Priorytet": "Priority",
        "private (tylko cache przeglądarki)": "private (browser cache only)",
        "Procent": "Percentage",
        "Procent całości": "Percentage of total",
        "Procent kolumny": "Percentage of column",
        "Proces": "Process",
        "Proces i elementy podstawowe (statusy, czynności, akcje, grupy użytkowników)": "Process and basic elements (statuses, activities, actions, user groups)",
        "Proces został dodany.": "The process has been added.",
        "Proces został edytowany.": "The process has been edited.",
        "Proces został zapisany.": "The process has been saved.",
        "Profil użytkownika": "Profile",
        "Profil został zaktualizowany.": "The profile has been updated.",
        "Projekt": "Project",
        "Prometheus": "",
        "Prompt informacyjny": "Information Prompt",
        "Prompt systemowy": "System Prompt",
        "Prompt z instrukcją": "Prompt with instructions",
        "Propka": "Propka",
        "Propki": "Propki",
        "Proporcje obrazu": "Image aspect ratio",
        "Proste uprawnienia": "Simple powers",
        "Próbkowanie": "Sampling",
        "Prywatne": "Private",
        "Przebudowanie indeksów": "Rebuilding the indexes",
        "Przebudowanie indeksównnOperacja może trwać od kilku sekund do kilku godzin w zależności od rozmiaru indeksów.nnNależy używać tylko w przypadku konieczności zaaplikowania zmian dokonanych w słownikach synonimów lub słów wykluczonych.": "Rebuilding indexesnnOperation can take from a few seconds to several hours depending on the size of the indexes.nnUse only if you need to apply changes made to the dictionaries of synonyms or excluded words.",
        "Przeciągnij i upuść aby posortować pliki": "Drag and drop to sort files",
        "Przeczytane": "Read",
        "przed": "before",
        "Przedrostek": "Prefix",
        "Przeglądarka z której korzystasz nie wspiera powiadomień PUSH": "The browser you are using does not support PUSH notifications",
        "Przeindeksowanie danych": "Data over-indexing",
        "Przeindeksowanie danychnnOperacja może trwać od kilku sekund do kilku godzin w zależności od ilości danych w bazie.nnNależy używać tylko w przypadku zauważenia braku danych w indeksach.": "Over-indexing datannOperation can take from a few seconds to a few hours depending on the amount of data in the database.nnUse only if you notice missing data in the indexes.",
        "Przejdź do konfiguracji, aby włączyć narzędzia": "",
        "Przekaż do właściciela": "Forward to owner",
        "Przekierowanie": "Redirect",
        "Przekieruj do dokumentu": "Redirect to document",
        "Przekieruj po kliknięciu w widget": "Redirect on widget click",
        "Przekieruj przycisk więcej do": "Redirect the more button to",
        "Przekroczono dozwoloną ilość znaków: %0.": "Allowed number of characters exceeded: %0.",
        "przekroczony dozwolony rozmiar pliku (%0 MB)": "exceeded the allowed file size (%0 MB)",
        "Przełącznik": "Switcher",
        "Przełożeni": "Superiors",
        "Przełożony": "Superior",
        "Przeniesienie wybranych elementów": "Transfer selected items",
        "przeniesiony do kosza": "transferred to basket",
        "Przenieś": "Move",
        "Przenieś${selected.length > 1 ? ' zaznaczone' : ''}": "Move${selected.length > 1 ? ' selected : ' }",
        "przeniósł do kosza": "transferred to trash",
        "Przepisz aktualne stawki za 1000 tokenów z cennika OpenAI.": "",
        "Przepisz limit konta OpenAI.": "",
        "Przepnij alias": "Rewrite alias",
        "Przesuń do góry": "Move up",
        "Przesuń na dół": "Move down",
        "Przesyłanie plików powiodło się.": "File transfer was successful.",
        "Przetestuj": "Test",
        "Przetestuj poprawność zapisanych kluczy": "Test the correctness of the stored keys",
        "Przetestuj ustawienia poczty": "Test your mail settings",
        "Przetestuj ustawienia Sms": "Test Sms settings",
        "Przetłumacz moduł": "Translate module",
        "Przetłumacz słownik": "Translate dictionary",
        "Przetwarzane": "Processing",
        "Przybornik": "Kit",
        "Przykład": "Example",
        "Przykład listy elementów pola typu tablica:": "An example of a list of elements of an array type field:",
        "Przykład tabelki z powtarzanymi elementami:": "An example of a table with repeated elements:",
        "Przykład użycia historii w formie tabeli": "Example of using a story in a table",
        "Przykład:": "Example:",
        "Przykład: domena\\użytkownik": "Example: domain user",
        "Przykład: użytkownik@domena": "Example: user@domain",
        "Przykładowa struktura danych": "Example data format",
        "Przykładowy JSON:": "Sample JSON:",
        "Przykładowy skrypt dla słownika z polami unit i type": "Example script for a dictionary with unit and type fields",
        "Przykładowy skrypt wywołujący akcję systemową o nazwie \"End\"": "A sample script that calls a system action named \"End\"",
        "Przykładowy skrypt:": "Example script:",
        "Przykłady": "Examples",
        "Przykłady konfiguracji": "Examples of configurations",
        "Przykłady odwołania się do pierwszego elementu w tablicy:": "Examples of referring to the first element in an array:",
        "Przykłady skryptów warunkowych": "Examples of conditional scripts",
        "Przypisanie do zespołu": "Assignment to team",
        "Przypisanie ról": "Role assignment",
        "Przypomnienie": "Reminders",
        "Przypomnienie hasła": "Remind password",
        "Przyrostek": "Suffix",
        "Przywrócenie": "Restore",
        "przywrócił z kosza": "brought back from the garbage can",
        "przywrócony z kosza": "restored from the garbage can",
        "Przywróć": "Reload",
        "Przywróć domyślną listę kolumn": "",
        "Przywróć domyślne": "Restore defaults",
        "Przywróć filtr": "Restore filter",
        "Przywróć ustawienia domyślne reguł i celów": "Restore default settings of rules and targets",
        "Przyznaj dostęp": "Grant access",
        "public (cache przeglądarki i proxy)": "public (browser cache and proxy)",
        "Publiczne": "Public",
        "Publikuj": "Publish",
        "PUT: Aktualizacja wpisu": "PUT: Update entry",
        "Pytania": "Questions",
        "Q1": "Q1",
        "Q2": "Q2",
        "Q3": "Q3",
        "Q4": "Q4",
        "Query string": "",
        "Radio": "Radio",
        "Ranga": "Rank",
        "Raporty": "Reports",
        "Realizuje obecnie zadanie w obiegu dokumentu": "It is currently performing a task in the document circulation",
        "ReCaptcha": "ReCaptcha",
        "Regex walidujący poprawność wartości w polu (tylko dla typu String)": "Regex validating the correctness of values in a field (only for String type)",
        "Region": "Region",
        "Regon": "Regon",
        "REGON": "Company ID",
        "REGON: ": "REGON:",
        "Reguła": "Rule",
        "Reguła filtracyjna": "Filter rule",
        "Reguła końcowa": "End rule",
        "Reguła został dodana.": "Rule has been added.",
        "reguła została usunięta.": "Rule has been removed.",
        "Reguła została zaktualizowana.": "Rule has been updated.",
        "Reguły": "Rules",
        "Rejestr": "Register",
        "Rejestr w pełni elektroniczny": "Fully electronic register",
        "Rejestr zmian": "Changelog",
        "Rejestr został dodany.": "The registry has been added.",
        "Rejestr zostały zapisany.": "The registry has been saved.",
        "Rejestry": "registers",
        "reklamacje": "complaints",
        "Relacyjne": "Relational",
        "Repozytorium plików": "File repository",
        "Request body": "",
        "Resetuj": "Reset",
        "Resetuj układ": "Reset Layout",
        "RESTful API": "RESTful API",
        "Rezygnacja": "Resignation",
        "Roczny": "Annual",
        "Rodzaj akcji": "Type of action",
        "Rodzaj odpowiedzi": "Type of response",
        "Rodzaj źródła": "Source type",
        "rok": "year",
        "Rok": "Year",
        "Rola": "Role",
        "Rola aktywna": "Active role",
        "Rola domyślna": "Default role",
        "Rola została dodana.": "The role has been added.",
        "Rola została usunięta.": "The role has been removed.",
        "Rola została zaktualizowana.": "The role has been updated.",
        "Role i uprawnienia": "Roles and permissions",
        "Role użytkownika": "User roles",
        "Role zespołu": "Team roles",
        "Role zostały przypisane": "Roles have been assigned",
        "Root": "Root",
        "Roszerzenia plików": "File expansions",
        "Roszerzenia plików rozdzielone przecinkami": "Comma-separated file extensions",
        "Rozdzielczość": "",
        "Rozdzielczość wygnerowanego obrazu.": "",
        "Rozgrupuj": "Ungroup",
        "Rozgrupuj pola": "Ungroup the fields",
        "Rozmiar": "Size",
        "Rozmiar okna modalnego": "Modal window size",
        "Rozmiar okna popup": "Popup window size",
        "Rozmiar pliku": "File size",
        "Rozmiar wydruku": "Print size",
        "Rozmiar zdjęcia został zmieniony.": "The image has been resized.",
        "Rozmieść równomiernie": "Distribute evenly",
        "Rozpoczęto tłumaczenie modułu. Może to potrwać do kilku minut.": "Module translation has begun. It can take up to several minutes.",
        "Rozpoznaj tekst wprowadzonego dokumentu (OCR) dla wskazanych pól modułu": "Recognize text in attached document (OCR) in selected module fields",
        "Rozszerzony - błedy ważne": "Extended - important errors",
        "Równość": "Equality",
        "Satysfakcja": "Satisfaction",
        "secondary": "secondary",
        "Sekcja": "Section",
        "Sentry": "",
        "Seria danych": "Data series",
        "Serie danych": "Data series",
        "Server Side Cache": "Server Side Cache",
        "Serwer bazy danych": "Database server",
        "Serwer dostępny": "Server available",
        "Serwer plików": "File server",
        "Serwer został dodany.": "Server has been added.",
        "Serwer został usunięty.": "Server has been removed.",
        "Serwer został zaktualizowany.": "Server has been updated.",
        "Serwery plików": "File servers",
        "Sesja": "Session",
        "Sesja nr %0": "Session No . %0",
        "Sesja użytkownika": "User's session has expired.",
        "Sheduler": "",
        "Sierpień": "August",
        "Silnik skryptowy": "Script engine",
        "Sitemap": "Sitemap",
        "Skala 1-5": "Scale 1-5",
        "Skan został dodany.": "The scan has been added.",
        "Skopiuj kod": "Copy code",
        "Skopiuj link": "Copy link",
        "Skrócona nazwa": "Short name",
        "Skrypt": "Script",
        "Skrypt dla wartości domyślnych": "Default value script",
        "Skrypt filtrujący": "Filter script",
        "Skrypt musi być napisany w JS i musi definiować obiekt o nazwie filter, który powinien składać się z pól o nazwach zgodnych z kluczami pól formularza i wartościami wg. których nastąpi filtrowanie.": "The script must be written in JS and must define an object named filter, which should consist of fields with names that match the keys of the form fields and the values by which the filtering will occur.",
        "Skrypt po dodaniu dokumentu": "Script after adding a document",
        "Skrypt po przetworzeniu dokumentu": "Script after document processing",
        "Skrypt powinien kończyć się wyrażeniem zwracającym wartość logiczną (true/false)": "",
        "Skrypt powinien zwrócić wartość typu bool, określającą czy dane pole powinno być widoczne czy nie.": "Script should return a boolean value, defining whether the field should be visible or not.",
        "Skrypt sprawdzający, czy data w polu jest w przeszłości i czy kwota jest większa niż 1000": "A script that checks if the date in the field is in the past and if the amount is greater than 1000",
        "Skrypt sprawdzający, czy kwota dokumentu przekroczyła 10000": "A script that checks if the document amount has exceeded 10000",
        "Skrypt sprawdzający, czy upłynął termin dokumentu w polu 1_Date1": "A script that checks for the expiration of the document date in the 1_Date field",
        "Skrypt sprawdzający, czy występuje słowo kluczowe \"ważne\" w tytule": "A script that checks for the presence of the keyword \"important\" in the title.",
        "Skrypt walidacyjny": "Validation script",
        "Skrypt współdzielony": "Shared script",
        "Skrypt zliczający sumą wartości z pola numerycznego": "Script that counts the sum of values from a numeric field",
        "Skrypty": "Scripts",
        "słowa": "words",
        "Słowa wykluczone": "Words excluded",
        "Słownik": "Dictionary",
        "Słownik dowolny": "Any dictionary",
        "Słownik Logito": "Logito Dictionary",
        "Słownik systemowy": "System dictionary",
        "Słownik został dodany.": "The dictionary has been added.",
        "Słownik został zaktualizowany.": "The dictionary has been updated.",
        "Słowniki": "Dictionaries",
        "Słowo": "Word",
        "Słowo bazowe": "Base word",
        "Słowo wykluczone": "The word excluded",
        "Słowo zostało dodane.": "Word has been added.",
        "Słowo zostało usunięte.": "The word has been removed.",
        "Słowo zostało zaktualizowane.": "Word has been updated.",
        "Sobota": "Saturday",
        "Sortowanie": "Sorting",
        "Sortowanie malejąco": "Sort descending ",
        "Sortowanie rosnąco": "Sort ascending",
        "Specjalizowane": "Specialized",
        "Specyfikacja": "Specification",
        "Sposób liczenia czasu": "Method of counting time",
        "Sposób prezentacji zakładek": "The way bookmarks are presented",
        "Sposób wyświetlania": "Display method",
        "Spólka": "Spólka",
        "Spółka": "Company",
        "Spółka została dezaktywowana.": "The company has been deactivated.",
        "Spółka została dodana.": "The company added.",
        "Spółka została zaktualizowana.": "The company has been updated.",
        "Spółki": "Companies",
        "Spółki użytkownika": "User companies",
        "Sprawdzenie czy pusty": "Check if empty",
        "Sprawdzenie, czy pole dokumentu ma wartość": "Checking whether a document field has a value",
        "Sprawdzenie, czy wybrano konkretną opcję w polu wyboru": "Checking whether a specific option has been selected in the checkbox",
        "Stała wartość": "Constant value",
        "Stan powiadomienia": "Notification status",
        "Standardowa szczegółowość": "",
        "Standardowe uprawnienia": "Standard entitlements",
        "Stanowiska pracy": "Job positions",
        "Stanowisko": "Position",
        "Stanowisko pracy": "Job position",
        "Start": "Start",
        "status": "Status",
        "Status": "Status",
        "Status dokumentu został zmieniony.": "The status of the document has been changed.",
        "Status edytowanej wersji dokumentu": "Status of the edited version of the document",
        "Status formularza został zmieniony.": "The status of the form has been changed.",
        "Status modułu": "Module status",
        "Status webhooka": "Webhook status",
        "Status został zapisany": "The status has been recorded",
        "Statusy": "Statuses",
        "Statusy do walidacji": "Statuses for validation",
        "Statusy do walidacji budżetu": "Statuses for budget validation",
        "Stawka VAT": "VAT rate",
        "Stopka": "Footer",
        "Strona": "Page",
        "Strona %0": "Page %0",
        "Strona główna": "Home",
        "Strona www": "Website",
        "Strona została usunięta.": "The page has been deleted.",
        "Struktura organizacyjna": "Organisational structure",
        "Studio": "Studio",
        "Stwierdzenie": "Statement",
        "Styczeń": "January",
        "Styl obrazu": "Image style",
        "Styl przycisku": "Button style",
        "success": "success",
        "Sukces": "Success",
        "Suma": "Total",
        "Suma danych wejściowych i wyjściowych podana w tokenach.": "",
        "Suma tokenów": "",
        "Sygnatura": "Signature",
        "Sygnatura skopiowana": "Signature copied",
        "Symbol": "Symbol",
        "Symbol ISO waluty domyślnej": "ISO symbol of the default currency",
        "Symbol jest wymagany": "The symbol is required",
        "Symbol jest zajęty": "The symbol is occupied",
        "Symbol klienta": "Customer symbol",
        "Symbol musi mieć co najmniej 3 znaki": "The symbol must have at least 3 characters",
        "Symbol musi mieć co najmniej 5 znaków": "The symbol must have at least 5 characters",
        "Symbol nie może być dłuższy niż 10 znaków": "The symbol must not be longer than 10 characters",
        "Symbol nie może być dłuższy niż 20 znaków": "The symbol must not be longer than 20 characters",
        "Symbol w adresie URL": "Symbol in the URL",
        "Synchronizowane z bazą AD": "Synchronized with AD database",
        "Synonimy": "Synonyms",
        "Synonimy zostały dodane.": "Synonyms have been added.",
        "Synonimy zostały usunięte.": "Synonyms have been removed.",
        "Synonimy zostały zaktualizowane.": "Synonyms have been updated.",
        "System": "System",
        "Szablon": "Template",
        "Szablon CSV": "CSV template",
        "Szablon modułu": "Module template",
        "Szablon numeracji": "Numbering template",
        "Szablon powiadomienia": "Notification template",
        "Szablon tabeli został dodany": "Table template was added",
        "Szablon tabeli został usunięty": "Table template was deleted",
        "Szablon tabeli został zaktualizowany": "Table template was updated",
        "Szablon word": "Word template",
        "Szablon XML": "XML template",
        "Szablon został dodany.": "The template has been added.",
        "Szablon został edytowany.": "The template has been edited.",
        "Szablon został usunięty": "The template has been removed",
        "Szablon został usunięty.": "The template has been removed.",
        "Szablon został zaktualizowany.": "The template has been updated.",
        "Szablony": "Templates",
        "Szablony dokumentów": "Document templates",
        "Szablony tabeli": "Table templates",
        "Szacowana kwota:": "Estimated amount:",
        "Szczególy importu": "Import details",
        "Szczegółowość obrazu": "",
        "Szczegółowy - błedy krytyczne": "Detailed - critical errors",
        "Szczegóły": "Details",
        "Szczegóły asystenta": "",
        "Szczegóły elementu": "Element details",
        "Szczegóły formularza": "Details of the form",
        "Szczegóły kontrahenta": "Counterparty details",
        "Szczegóły pliku": "File details",
        "Szczegóły spółki": "Details of the company",
        "Szczegóły terminu": "Details of the date",
        "Szczegóły zadania": "Task details",
        "Szczegóły zapytania": "",
        "Szczegóły zdarzenia": "Event details",
        "Szczegóły zdarzenia nr %0": "Details of event number %0",
        "Szerokość": "Width",
        "Szerokość (w calach)": "",
        "Szerokość dla etykiety": "Width for the label",
        "Szerokość niestandardowa etykiety": "Custom label width",
        "Szkic": "Draft",
        "Szkic można usunąć, tylko i wyłącznie wtedy gdy posiadamy chociaż 1 aktywną wersję. Czy chcesz kontynuować?": "You can delete the sketch, only if you have at least 1 active version. Do you want to continue?",
        "Szukaj": "Search",
        "Szukaj pełnotekstowo w plikach:": "Search full-text in files:",
        "Szukaj pełnotekstowo w polach modułu:": "Search full-text in module fields:",
        "Szukaj ponownie": "Search again",
        "Szukaj we wszystkich wersjach": "Search in all versions",
        "Szukana fraza": "Search term",
        "Szukane słowo": "Search word",
        "Średni": "Medium",
        "Średnia": "",
        "Średnia artymetyczna": "Artimetic average",
        "Środa": "Wednesday",
        "Środowisko": "Enviroment",
        "Ta operacja nie jest odwracalna i spowoduje utratę danych kontrahenta.": "This operation is not reversible and will result in the loss of contractor data.",
        "Tabela": "Table",
        "Tabela VAT": "VAT table",
        "Tabele wektorowe": "Vector tables",
        "Tablica elementów do wyboru, uzupełnienie tej właściwości spowoduje, że pole będzie traktowane jako select zamiast input": "An array of selectable items, completing this property will cause the field to be treated as a select instead of an input",
        "Tagi": "Tags",
        "Tak": "Yes",
        "Tak/Nie": "Yes/No",
        "Tekst": "Text",
        "Tekst alternatywny": "Alt text",
        "Tekst potwierdzający wymuszenie dodania dokumentu": "Text that confirms forcing the addition of a document",
        "Tekst zastępczy": "Alternative text",
        "Tel": "Tel",
        "Tel.": "Tel.",
        "Telefon": "Phone",
        "Telefon komórkowy": "Mobile no.",
        "Telefon wewnętrzny": "Internal phone",
        "Ten dashboard nie zawiera jeszcze żadnych widgetów": "This dashboard does not yet contain any widgets",
        "Ten element nie zawiera menu kontekstowego.": "This item does not contain a context menu.",
        "Ten kanał ma możliwość wysyłania tekstu sformatowanego.": "This channel provides a possibility to send formatted text.",
        "Ten kanał nie ma możliwości wysyłania tekstu sformatowanego.": "This channel does not provide a possibility to send formatted text.",
        "Tenant ID": "",
        "Teraz": "Now",
        "Termin": "Term",
        "Termin cykliczny": "Cyclical term",
        "Termin jednorazowy": "One-time deadline",
        "Termin prywatny": "Private term",
        "Termin publiczny": "Public date",
        "Termin został dodany pomyślnie.": "The term was added successfully.",
        "Termin został dodany.": "Deadline added.",
        "Termin został usunięty.": "The term has been removed.",
        "Termin został zaaktualizowany.": "The deadline has been updated.",
        "Termin został zaktualizowany.": "The deadline has been updated.",
        "Terminy do słownika będzie można dodawać dopiero po zapisaniu źródła danych.": "You won't be able to add terms to the dictionary until you save the data source.",
        "Terminy słowników": "Dictionary terms",
        "Test konfiguracji": "Configuration test",
        "Test ustawień pomyślnie zakończony.": "Settings test successfully completed.",
        "Testowy adres email": "Tested email address",
        "Testowy numer telefonu": "Test phone number",
        "Testuj": "Test",
        "Testuj zapytanie": "Test Query",
        "Tłumacz": "Translate",
        "Tłumaczenia": "Translations",
        "Tłumaczenie słownika rozpoczęte. Ta operacja może potrwać do kilku minut.": "Dictionary translation has started. This operation may take up to several minutes.",
        "To pole jest wymagane": "The field is required.",
        "Token": "Token",
        "Token został usunięty.": "The token has been removed.",
        "Tokeny": "Tokens",
        "Tokeny dostępu": "Access tokens",
        "Tokeny wejściowe": "",
        "Tokeny wyjściowe": "",
        "Tooltip": "",
        "Trace - najwięcej szczegółów": "",
        "Tracing": "",
        "Treści odpowiedzi są wymagane": "Answers are required",
        "Treści pytań są wymagane": "Questions are required",
        "Treść": "Content",
        "Treść szablonu": "Content of the template",
        "Trigger": "",
        "Trwa autentykacja z SimplySign...": "Authentication with SimplySign is underway....",
        "Trwa generowanie obrazów": "",
        "Trwa generowanie obrazu": "Image generation in progress",
        "Trwa generowanie opisu dla wybranego obrazu": "Description generation for the selected image is in progress",
        "Trwa przekierowanie...": "Redirecting...",
        "Tryb konserwacji systemu": "System maintenancemMode",
        "Tryb pracy": "Mode of operation",
        "Tryb walidacji": "Validation mode",
        "Tryb wysyłki": "Shipping mode",
        "Tryb wyświetlania": "Display mode",
        "Tryb wyświetlania formularza": "Form display mode",
        "Tryb zapisu dokumentu": "Document storage mode",
        "Trzy kolumny": "Three columns",
        "Twoje hasło wygasło. Zmień je na nowe.": "Your password has expired. Change it to new one.",
        "Tworzenie dokumentów": "Document creation",
        "Twój token": "Your token",
        "tydzień": "week",
        "Tydzień": "Week",
        "tydzień (tygodnie) w następującym dniu": "week(s) on the following day",
        "Tygodniowy": "Weekly",
        "tylko do nowo zaproszonych": "only to newly invited",
        "Tylko do odczytu": "Read-only",
        "Tylko dokumenty o wskazanym statusie są zliczane i porównywane z kwotą budżetu": "Only documents with the indicated status are counted and compared with the budget amount",
        "tylko dokumenty wersjonowane": "versioned documents only",
        "Tylko liderzy": "Leaders only",
        "Tylko serwer deweloperski. Nie używać w trybie produkcyjnym. ": "Development server only. Do not use in production mode. ",
        "Typ": "Type",
        "Typ danych": "Data type",
        "Typ dokumentu": "Document type",
        "Typ formularza": "Form type",
        "Typ kodu": "Code type",
        "Typ konta": "Account type",
        "Typ kontrahenta": "Vendor type",
        "Typ licznika": "Counter type",
        "Typ listy": "List type",
        "Typ logiczny": "Logical type",
        "Typ logowania": "Login type",
        "Typ mime": "Mime type",
        "Typ modułu": "Module type",
        "Typ obiektu": "Object type",
        "Typ odbiorcy": "Recipient type",
        "Typ pliku": "File type",
        "Typ pola": "Field type",
        "Typ powiadomienia": "Notification type",
        "Typ rejestru": "Registry type",
        "Typ serwera": "Server type",
        "Typ słownika": "Dictionary type",
        "Typ szablonu": "Template type",
        "Typ właściwy": "The right type",
        "Typ wykresu": "Chart type",
        "Typ wyszukiwania": "Search type",
        "Typ zawartości": "Content type",
        "Typ zmiennej": "Variable type",
        "Typ źródła": "Source type",
        "Typ źródła danych": "Data source type",
        "typu \"%0\" o ID \"%1\"": "of type \"%0\" with ID \"%1\"",
        "Typy grup": "Types of groups",
        "Typy użytkowników": "Types of users",
        "Tytuł": "Title",
        "Tytuł formularza": "Form title",
        "Tytuł formularza jest wymagany": "Form title is required",
        "Tytuł formularza nie może być dłuższy niż %0 znaków": "The title of the form cannot be longer than %0 znaków",
        "Tytuł sekcji": "Section title",
        "Uczestnicy": "Participants",
        "uczestnika": "participant",
        "Udało się pobrać dokument": "Successfully downloaded the document",
        "Udało się wyeksportować dokument": "Successfully exported the document",
        "Udało się zapisać ustawienia widgetów": "Successfully saved widget settings",
        "Udostępnianie zostało zatrzymane": "Sharing has been stopped",
        "Udostępnij": "Share",
        "Udostępnij dla": "Share for",
        "Udostępnij do": "",
        "Udostępnij dokument w OneDrive do": "Share a document in OneDrive to",
        "Udostępnij w OneDrive": "Share in OneDrive",
        "Udostępnił": "Provided by",
        "Udostępnione": "Shared",
        "Udostępniono dla": "Provided for",
        "Udostępniono dokument w OneDrive": "A document in OneDrive has been made available",
        "Układ": "Arrangement",
        "Układ domyślny": "Default layout",
        "Układ listy": "List layout",
        "Ukryj": "Hide",
        "Ukryj widok": "Hide view",
        "Ukryta": "Hidden",
        "Ukryty": "Hidden",
        "Ulica": "Street",
        "umowę": "contract",
        "Upload": "Upload",
        "Upload plików": "Upload files",
        "Upłynęła data": "The date has passed",
        "Uprawnieni użytkownicy": "Permitted users",
        "Uprawnienia": "Permissions",
        "Uprawnienia do dokumentów": "Entitlement to documents",
        "Uprawnienia działów": "Powers of the departments",
        "Uprawnienia indywidualne": "Individual entitlements",
        "Uprawnienia zespołów": "Eligibility of teams",
        "Uprawnione działy": "Permitted units",
        "Uprawnione zespoły": "Permitted teams",
        "Url": " - contains the BaseUrl field with the name of the license as it goes to the url",
        "Uruchom zadanie": "Run the task",
        "Uruchomić gdy": "Run when",
        "Uruchomienie procesu reindeksacji spowoduje czasową niedostępność danych dla użytkowników zależną od ilości dokumentów. Czy na pewno chcesz kontynuowac?": "Starting the reindexation process will cause a temporary unavailability of data. Are you sure you want to continue?",
        "Urządzenie zostało usunięte.": "The device has been removed.",
        "Usługi": "Services",
        "Ustaw szerokości proporcjonalnie": "Set widths proportionally",
        "Ustawienia": "Settings",
        "Ustawienia budżetu zostały zapisane.": "The budget settings have been saved.",
        "Ustawienia domyślne": "Default settings",
        "Ustawienia domyślne na filtrach": "Default filter settings",
        "Ustawienia domyślne na listach": "Default list settings",
        "Ustawienia domyślne zostały zaktualizowane": "Default settings have been updated",
        "Ustawienia domyślne zostały zmienione": "The default settings have been changed",
        "Ustawienia grida": "",
        "Ustawienia konta": "Account settings",
        "Ustawienia modułu": "Module settings",
        "Ustawienia ogólne": "General settings",
        "Ustawienia podstawowe": "Basic settings",
        "Ustawienia powiadomień zostały zaktualizowane.": "Your notification settings have been updated.",
        "Ustawienia sesji": "Session settings",
        "Ustawienia uprawnień zostały zapisane.": "The permission settings have been saved.",
        "ustawienia widgetu": "widget settings",
        "Ustawienia widgetu": "Widget settings",
        "Ustawienia wyszukiwania i OCR zostały zapisane.": "The search and OCR settings have been saved.",
        "Ustawienia zaawansowane": "Advanced settings",
        "Ustawienia zostały przywrócone.": "",
        "Ustawienia zostały zapisane.": "Settings have been saved.",
        "Ustawiono dokument jako prywatny": "Set the document as private",
        "Ustwienia szczegółów": "Details settings",
        "usunął": "removed",
        "Usunął": "Deleted",
        "Usunięcie": "Deletion",
        "Usunięcie dokumentu": "Document deletion",
        "Usunięcie formularzannOperacja jest nieodwracalna. Zostaną utracone wszystkie powiązane dokumenty!nnCzy na pewno chcesz usunąć wybrany formularz?": "",
        "Usunięcie indeksunnOperacja jest nieodwracalna. Czy na pewno chcesz usunąć wybrany indeks?": "Delete indexunnOperation is irreversible. Are you sure you want to delete the selected index?",
        "Usunięcie pola spowoduje usunięcie go z konfiguracji pól wspólnych na wszystkich modułach oraz ze wszystkich wielo-modułowych list dokumentów.": "Deleting the field will cause it to be deleted from shared fields configurations on all modules and from all multi-module document lists.",
        "Usunięcie wybranych elementów": "Delete selected items",
        "Usunięte": "Deleted",
        "Usunięto": "Deleted",
        "usunięty": "removed",
        "Usunięty": "Deleted",
        "Usuń": "Delete",
        "Usuń datę": "Delete date",
        "Usuń dział": "Delete department",
        "Usuń filtr": "Remove filter",
        "Usuń grupę": "Delete the group",
        "Usuń indeks": "Delete index",
        "Usuń kontrahenta": "Delete contractor",
        "Usuń obiekt": "Delete object",
        "Usuń plik": "Delete file",
        "Usuń plik z listy": "Remove the file from the list",
        "Usuń pozycję": "Delete item",
        "Usuń sekcję": "Delete section",
        "Usuń spacje": "Remove spaces",
        "Usuń urządzenie": "Remove device",
        "Usuń urządzenie aby odwołać zapamiętane automatyczne logowanie na swoje konto": "Remove device to cancel remembered automatic logins to your account",
        "Usuń z zespołu": "Remove from team",
        "Usuń${selected.length > 1 ? ' zaznaczone' : ''}": "Delete${selected.length > 1 ? ' selected : ''}",
        "Usuwanie dokumentów": "Deletion of documents",
        "Usuwanie użytkowników": "Deletion of users",
        "Usuwanie własnych dokumentów": "Deleting your own documents",
        "Usuwanie wszystkich dokumentów": "Deleting all documents",
        "Utworzono danę wyktorową": "A faculty dana has been created",
        "Utworzono kolekcję": "A collection has been created",
        "Utworzono powiadomienie weryfikacyjne": "A verification notification has been created",
        "Utworzony przez": "Created by",
        "Utworzył": "Created ",
        "Utwórz grupy": "Create groups",
        "Utwórz katalog": "Create a directory",
        "Utwórz kopię formularza": "Create a copy of the form",
        "Utwórz kopię z": "Create a copy from",
        "Utwórz nową wersję": "Create a new version",
        "Utwórz nowy": "Create new",
        "Utwórz nowy wątek": "",
        "Uwaga brak konto do przydzielenia": "Note no account to assign",
        "UWAGA: Brak obsługi TypeScript oraz w kodzie metody nie można robić importów.": "",
        "UWAGA! Funkcja umożliwia natychmiastowe wykonanie skryptu webhook bez dodawania go do kolejki. Pomijanie kolejkowania może prowadzić do przeciążeń systemu i timeoutów, zalecane jest używanie tej opcji tylko w sytuacjach, gdy natychmiastowe wykonanie jest absolutnie konieczne.": "WARNING! This allows for an immediate execution of webhook script without adding it to a queue. Skipping the queue might cause processing overloads and timeouts. This option is only recommended in situations, where immediate execution is absolutely necessary.",
        "Uwagi": "Notes",
        "Uwzględnij wydziały podrzędne": "Include sub-departments",
        "Użycie historii dokumentu w szablonie": "Using document history in a template",
        "Użycie pól dokumentu:": "Using document fields:",
        "Użyte narzędzie": "Used tool",
        "Użytkownicy": "Users",
        "Użytkownicy w tej roli": "Users in this role",
        "Użytkownik": "User",
        "Użytkownik \"%0\" %1 obiekt %2": "User \"%0\" %1 obiect %2",
        "Użytkownik jest liderem zespołu": "The user is a team leader",
        "Użytkownik nie może się zalogować dopóki konto nie jest aktywne.": "User cannot log in until the account is activated.",
        "Użytkownik nie należy do żadnego zespołu.": "User does not belong to any team.",
        "Użytkownik posiada pełne uprawnienia": "The user has full permissions",
        "Użytkownik został dodany do zespołu.": "The user has been added to the team.",
        "Użytkownik został dodany.": "The user has been added.",
        "Użytkownik został usunięty.": "User has been deleted.",
        "Użytkownik został wylogowany.": "The user has been logged out.",
        "Użytkownik został zaktualizowany.": "The user has been updated.",
        "Użyty Model": "",
        "VAT": "Tax",
        "W ciele metody walidującej dostępne są poniższe zmienne:": "",
        "W każdy dzień tygodnia": "On any day of the week",
        "W następującym dniu:": "On the following day:",
        "W obiekcie data dostępne są propki isEdit (bool, czy dokument jest w edycji) i processId": "The isEdit (bool, whether the document is in edit) and processId prop are available in the data object",
        "W obrębie skryptu dostępne są następujące obiekty:": "",
        "W obrębie skryptu możliwe jest korzystanie ze wszystkich serwisów dostępnych w skryptach np. w źródłach danych": "Within the script, it is possible to use all the services available in the scripts, such as data sources",
        "W organizacji nie ma jeszcze żadnych działów.": "There are no sections in the organization yet.",
        "W przyszłości": "In the future",
        "W skrupcie dostępny jest obiekt Model z następującymi właściwościami:": "",
        "W skrypcie dostępny jest obiekt \"document\", który zawiera dane dokumentu (tylko w przypadku edycji), obiekt \"rowIndex\", który w propce \"value\" zawiera index dodawanego wiersza oraz obiekt \"formId\", który w propce \"value\" zawiera id formularza:": "Objects accessible within the script: 'document' - contains document data (only in edit mode), 'rowIndex' - contains added row index in a 'value' property, 'formId' - contains form id in 'value' property",
        "W skrypcie dostępny jest obiekt CURRENTUSER, który zawiera dane zalogowanego użytkownika o następującej strukturze": "There is a CURRENTUSER object in the script, which contains the data of the logged-in user with the following structure",
        "W skrypcie korzystać można ze wszystkich serwisów zgodnie z dokumentacją w źródłach danych.": "All services are available according to documentation in data sources.",
        "W skryptach procesowych dostępne są następujące obiekty:": "The following objects are available in process scripts:",
        "W skryptach procesowych dostępny jest obiekt Model z następującymi właściwościami:": "In process scripts have access to a Model object with following properties:",
        "W zespole od %0": "In the team from %0",
        "W zespole od %0 do %1": "In the band from %0 do %1",
        "Walidacja": "Validation",
        "Walidacja błędów obejmuje tylko najczęściej występujące przypadki. Aby sprawdzić poprawność procesu należy go przetestować.": "Error validation covers only the most common cases. To validate a process, you need to test it.",
        "Walidacja certyfikatu SSL": "SSL certificate validation",
        "Walidacja danych": "Data validation",
        "Walidacja po stronie API": "API validation",
        "Walidator": "Validator",
        "Waliduj z tabelką VAT": "Validate with VAT table",
        "Warn - ostrzeżenia": "Warn - warnings",
        "warning": "warning",
        "Wartości": "Values",
        "Wartości od 0 - 1.0 (0 oznacza 0%, a 1 oznacza 100%)": "",
        "Wartości poszczególnych komórek tabeli są w formacie zgodnym z tym, co dana kontrolka zwraca na front.": "The values of each table cell are in the format according to what the control returns to the front.",
        "Wartość": "Value",
        "Wartość brutto": "Gross value",
        "Wartość domyślna": "Default value",
        "Wartość domyślna: (&(objectClass=organizationalUnit))": "Default value: (&(objectClass=organizationalUnit))",
        "Wartość domyślna: (&(objectClass=user)": "Default value: (&(objectClass=user)",
        "Wartość musi być liczbą całkowitą": "The value must be an integer",
        "Wartość musi być liczbą dodatnią": "The value must be a positive number",
        "Wartość musi być liczbą ujemną": "The value must be a negative number",
        "Wartość netto": "Net value",
        "Warunek": "",
        "Warunek porównania do daty bieżącej pola w wybranym kroku na wskazanym formularzu": "Comparison condition to the current date of the field in the selected step on the indicated form",
        "Warunek porównania do daty bieżącej pola z dokumentu": "Comparison condition to current date of field from document",
        "Warunek sprawdzający, czy pole typu 'Tak/Nie' ma wartość Nie": "A condition that checks whether a field of type 'Yes/No' has the value No",
        "Warunek sprawdzający, czy pole typu 'Tak/Nie' ma wartość Tak": "A condition that checks whether a field of type 'Yes/No' has the value Yes",
        "Warunek zmienne porównane do pola dokumentu": "Condition variables compared to document field",
        "Warunek zmienne porównane do pola w wybranym kroku na wskazanym fomrularzu": "Condition variables compared to the field in the selected step on the indicated fomruler",
        "Warunki logiczne": "Logical conditions",
        "Warunkowe wyświetlanie pól": "Conditional field visibility",
        "Warunkowo": "Conditionally",
        "Ważne informacje": "Important information",
        "Ważność": "Validity",
        "Wątek": "",
        "Wątek główny": "",
        "Wątek główny jest kontynuowany automatycznie przez asystenta.": "",
        "Wczytaj szablon": "Load a template",
        "Wczytanie szablonu nie powiodło się": "Loading template failed",
        "Webhook": "Webhook",
        "Webhook obsługuje argument JSON i XML": "Webhook supports JSON and XML argument",
        "Webhook został dodany.": "Webhook has been added.",
        "Webhook został usunięty.": "",
        "Webhook został zaktualizowany.": "",
        "Webhooki": "Webhooks",
        "Weekend": "Weekend",
        "Wersja": "Version",
        "Wersja API": "API version",
        "Wersja językowa": "Language version",
        "Wersja modułu": "Module version",
        "Wersja robocza": "Draft version",
        "Wersja utowrzona przez": "Version created by",
        "Wersje": "Versions",
        "Wersje dokumentu": "Document versions",
        "wersji zaakceptowanej": "accepted version",
        "Wersjonowanie": "Versioning",
        "Wersjonowanie dokumentów włączone": "",
        "Wersjonowanie dokumentów wyłączone": "",
        "Wersjonowanie można zmienić tylko podczas dodawania nowego formularza.": "",
        "Wewnętrznie": "Internally",
        "Wiadomość testowa została wysłana.": "A test message has been sent.",
        "Widget został dodany": "Widget has been added",
        "Widget został usunięty": "Widget has been removed",
        "Widget został zapisany": "The widget has been saved",
        "Widgety": "Widgets",
        "Widoczne w listach": "Visible in lists",
        "Widoczność": "Visibility",
        "Widoczność formatowania": "Formatting visibility",
        "Widoczność modułu w menu": "",
        "Widoczność sekcji w menu modułu": "",
        "Widok nieobsługiwany": "",
        "Widok ustawień": "Settings view",
        "Widzi wszystkie dokumenty w rejestrze i ich szczegóły": "Sees all documents in the register and their details",
        "Widzi wszystkie faktury w rejestrze i ich szczegóły.": "He can see all the invoices in the register and their details.",
        "Widzi wszystkie nieobecności w rejestrze i ich szczegóły": "Sees all absences in the register and their details",
        "Widzi wszystkie pisma w rejestrze i ich szczegóły": "Sees all letters in the register and their details",
        "Widzi wszystkie reklamacje w rejestrze i ich szczegóły": "Sees all complaints in the register and their details",
        "Widzi wszystkie umowy w rejestrze i ich szczegóły": "Sees all contracts on the register and their details",
        "Widzi wszystkie wnioski w rejestrze i ich szczegóły": "Sees all applications in the register and their details",
        "Widżety": "Widgets",
        "Wiele dokumentów": "Many documents",
        "Wielkość kroku": "Step size",
        "Wielokrotny wybór": "Multiple choice",
        "Wiersz": "Row",
        "Wiersze": "Rows",
        "Więcej": "More",
        "więcej...": "more...",
        "Więcej...": "More…",
        "Wizualizacja": "",
        "Wklej komponent": "Paste component",
        "Właściwości": "Characteristics",
        "Właściwości opisujące pole:": "Properties describing the field:",
        "Właściwości szablonu": "Template properties",
        "Włącz": "Turn on",
        "Włącz funkcję opisu dokumentów kodami kontrollingowymi": "Enable the function of describing documents with controlling codes",
        "Włącz obsługę wprowadzania dokumentów w module biuro": "Enable document entry support in the office module",
        "Włącz system powiadomień": "Enable the notification system",
        "Włącz walidację po stronie API": "",
        "Włącz wyszukiwanie pełnotekstowe w plikach": "Enable full-text search in files",
        "Włączenie moderacji danych wejściowych jest zalecane.": "Enabling input moderation is recommended.",
        "Włączone": "Enabled",
        "Włączony": "Enabled",
        "Województwo": "Province",
        "Wpis domyślny": "Default entry",
        "Wpis nadrzędny": "Master entry",
        "Wpis w sitemap": "Entry in sitemap",
        "Wpis został dodany.": "The post has been added.",
        "Wpis został usunięty.": "The post has been deleted.",
        "Wpis został zaakceptowany.": "The entry was accepted.",
        "Wpis został zaktualizowany.": "The post has been updated.",
        "Wpisz kod ikony Font Awesome, aby zobaczyć podgląd": "Enter the Font Awesome icon code to see a preview",
        "Wpisz komentarz...": "Enter comment...",
        "Wpisz wiadomość": "Enter message",
        "Wprowadzamy URL do seriwsów które chemy wykluczyć. Dane mogą być odzielone przecinkiem lub być w nowej linii": "",
        "Wprowadzona wartość jest nieprawidłowa": "The value you entered is invalid",
        "Wprowadzona wartość jest za długa": "The value you entered is too long",
        "Wprowadzona wartość jest za krótka": "The value you entered is too short",
        "Wprowadzona wartość jest za niska": "The value you entered is too low",
        "Wprowadzona wartość jest za wysoka": "The value you entered is too high",
        "Wprowadzona wartość nie jest poprawna": "The value you entered is not valid",
        "Wróć do logowania": "Back to login",
        "Wróć do strony głównej": "Back to Home Page",
        "Wrzesień": "September",
        "Wskazuje, jak długo odpowiedź pozostaje aktualna w pamięci serwera Redis.": "Indicates how long the response remains valid in Redis server memory.",
        "Wskaż pole z argumentem": "Point to the field with the argument",
        "Wstaw cztery kolumny 1/4": "Insert four columns 1/4",
        "Wstaw dwie kolumny 1/3 oraz 2/3": "Insert two columns 1/3 and 2/3",
        "Wstaw dwie kolumny 2/3 oraz 1/3": "Insert two columns 2/3 and 1/3",
        "Wstaw dwie równe kolumny": "Insert two equal columns",
        "Wstaw kontrolkę": "Insert control",
        "Wstaw stronę": "Insert page",
        "Wstaw szablon informacyjny ze zdjęciem": "Insert information template with photo",
        "Wstaw trzy kolumny 1/3": "Insert three columns 1/3",
        "Wstaw z dołu": "Insert from below",
        "Wstaw z góry": "Insert from above",
        "Wstaw z lewej strony": "Insert left",
        "Wstaw z prawej strony": "Insert right",
        "Wstecz": "Back",
        "Wstępne filtrowanie danych": "",
        "Wszyscy": "Everyone",
        "Wszyscy pracownicy mogą dodawać nieobecność.": "All employees can add an absence.",
        "Wszyscy pracownicy mogą dodawać wniosek.": "All employees can add an application.",
        "Wszystkie": "All",
        "Wszystkie działy": "All departments",
        "Wszystkie niezapisane zmiany zostaną utracone.": "Any unsaved changes will be lost.",
        "Wszystkie próby": "All attempts",
        "Wszystko": "All",
        "Wtorek": "Tuesday",
        "wybierz": "select",
        "Wybierz": "Select",
        "wybierz długość": "choose length",
        "Wybierz dokument": "Select document",
        "Wybierz dokument, aby zobaczyć szczegóły": "Select a document to see details",
        "Wybierz dokumenty aby zobaczyć dostępne akcje": "Select documents to see available actions",
        "Wybierz filtr": "Select a filter",
        "Wybierz formularz zawierający dodatkowe pola dla kont użytkownków": "Select a form with additional fields for user accounts",
        "Wybierz grupę": "Select group",
        "Wybierz katalog do którego chcesz przenieść wybrane elementy.": "Select the directory to which you want to move the selected items.",
        "wybierz model": "",
        "Wybierz moduł aby ograniczyć wiązanie do wybranego typu dokumentu": "Select a module to limit the binding to the selected document type",
        "Wybierz plik": "Select a file",
        "Wybierz plik do udostępnienia": "",
        "Wybierz plik lub katalog": "Select a file or directory",
        "Wybierz plik.": "Select file.",
        "Wybierz pliki": "Select files",
        "Wybierz pliki (do {0})": "Select files (to {0})",
        "Wybierz serwer": "Select server",
        "Wybierz shortcode": "Select a shortcode",
        "Wybierz silnik skryptowy, aby zobaczyć pomoc": "Select a scripting engine to see help",
        "Wybierz tryb kolekcje": "Select collections mode",
        "Wybierz tryb pracy": "Select the mode of operation",
        "Wybierz typ": "Select type",
        "Wybierz typ odbiorcy": "Select the type of recipient",
        "Wybierz typ powiadomienia aby zobaczyć dostępne klucze.": "Select the notification type to see the available keys.",
        "Wybierz użytkownika": "Select a user",
        "Wybierz użytkowników dla których chcesz wykonać akcje": "Select the users for whom you want to perform actions",
        "Wybierz wątek w którym chcesz prowadzić konwersację.": "",
        "Wybierz wersję": "Choose a version",
        "Wybierz zaznaczone": "Choose selected",
        "wybierz...": "select...",
        "Wybór": "Choice",
        "Wybór języka": "Language",
        "Wybór wersji": "Version selection",
        "Wybór załączników": "Attachments selection",
        "Wybór+": "Choice+",
        "Wybrana data jest mniejsza niż dozwolona %0.": "Selected date is before the permitted one %0.",
        "Wybrana data jest większa niż dozwolona %0.": "Selected date is above the permitted one %0.",
        "Wybrana liczba jest mniejsza niż dozwolona %0.": "Selected number is lesser than allowed %0.",
        "Wybrana liczba jest większa niż dozwolona %0.": "Selected number is greater then allowed %0.",
        "Wybrane": "Selected",
        "Wybrane %0 na %1": "Selected %0 na %1",
        "Wybrane dokumenty": "Selected documents",
        "Wybrane elementy zostały przeniesione.": "The selected items have been moved.",
        "Wybrane elementy zostały usunięte.": "The selected items have been removed.",
        "Wybrane pliki": "Selected files",
        "Wybrane pliki: {0}": "Selected files: {0}",
        "Wybrane pole zastąpi pole zgrupowane w konfiguracjach modułu": "The selected field will replace the field grouped in the module configurations",
        "Wybrane: %0": "Selected: %0",
        "Wybrano %0 użytkowników.": "%0 uusers selected.",
        "Wybrano dostawcę Azure AI.": "Azure AI provider has been selected.",
        "Wybrano dostawcę Azure OpenAI.": "Azure OpenAI provider has been selected.",
        "Wybrano dostawcę OpenAI.": "OpenAI's vendor has been selected.",
        "Wybrano jednego użytkownika.": "One user was selected.",
        "Wybrany język": "Selected language",
        "Wybrany użytkownik": "Selected user",
        "Wybróbuj możliwości AI": "",
        "Wyczyść": "Clear",
        "Wydarzenie": "Event",
        "Wydarzenie cykliczne": "Periodic event",
        "Wydział": "Department",
        "Wygenerowana odpowiedź przez model AI.": "",
        "Wygenerowane obrazy": "",
        "Wygeneruj dokumentację": "Generate documentation",
        "Wygeneruj metadane dla przesyłanych obrazów": "",
        "Wyklucz logowanie zależności": "",
        "Wykonaj": "Do",
        "Wykonywana przez skrypt robotyzujący niedostępna dla użytkownika": "Executed by a robotics script inaccessible to the user",
        "Wykorzystane": "Used",
        "Wykorzystanie": "Use of",
        "Wykresy": "Charts",
        "Wykryto zmiany w argumencie. Zapis usunie istniejący wektor w bazie. Kontynuować?": "Changes in the argument have been detected. The entry will delete the existing vector in the database. Continue?",
        "Wyliczone daty": "Calculated dates",
        "Wyloguj się": "Log out",
        "Wyloguj zalogowanego obecnie użytkownika": "Log out the currently logged in user",
        "Wyłaczone": "Disabled",
        "Wymagaj znaków specjalnych w hasłach": "Require special characters in passwords",
        "Wymagalność pola (true, false)": "Field requirements (true, false)",
        "Wymagane": "Required",
        "Wymagane jest ustawienie minimalnej długości hasła": "Minimum password length is required",
        "Wymagane jest wybranie formularza": "It is required to select the form",
        "Wymagane jest wybranie kategorii": "It is required to select a category",
        "Wymagane jest wybranie komponentu": "",
        "Wymagane jest wybranie modułu": "",
        "Wymagane jest wybranie powiązania": "It is required to select a link",
        "Wymagane jest wybranie sekcji": "A section must be selected",
        "Wymagane jest wybranie źródła danych": "Data source is required",
        "Wymagany": "Required",
        "Wymiary": "Dimensions",
        "Wymuś zmianę hasła": "Force password change",
        "Wynik testu": "Test result",
        "Wyniki": "Results",
        "Wyniki testu zawszę będą ograniczone do maksymalnie 10 pozycji.": "The test results will always be limited to a maximum of 10 items.",
        "Wypełnij formularz ostatnio zapisaną wersją": "Fill out the form with the last saved version",
        "Wyrażenia do pól wyliczanych": "Expressions for calculated fields",
        "Wyrażenia warunkowe": "Conditional expressions",
        "Wyrażenie (wymagane)": "Expression (required)",
        "Wyrażenie regularne": "Regular expression",
        "Wyrażenie w postaci \"/pattern/flag\" nie jest obsługiwane, użyj same wartości pattern": "Expression in the form \"/pattern/flag\" is not supported, use pattern values alone",
        "Wysłane": "Sent",
        "Wysoka szczegółowość": "",
        "Wysokość": "Height",
        "Wysokość (w calach)": "",
        "Wysokość wiersza": "",
        "wystąpieniach": "speeches",
        "Wystąpił błąd podczas ładowania obrazu. Spróbuj ponownie.": "An error occurred while loading the image. Try again.",
        "Wystąpił błąd podczas próby exportu użytkowników.": "",
        "Wystąpił błąd podczas próby importu definicji modułu.": "An error occurred while trying to import a module definition.",
        "Wystąpił błąd podczas próby importu użytkowników.": "",
        "Wystąpił błąd podczas usuwania dokumentu.": "An error occurred while deleting a document.",
        "Wystąpił błąd podczas zwektoryzowania": "An error occurred while vectorizing",
        "Wystąpił błąd połączenia. Upewnij się że masz włączony internet i spróbuj ponownie.": "A connection error has occurred. Make sure you are online and try again.",
        "Wystąpił błąd serwera podczas obsługiwania wybranej operacji. Spróbuj ponownie.": "A server error occurred while handling the selected operation. Please try again.",
        "Wystąpił problem podczas pobierania szablonu": "",
        "Wystąpił problem podczas przesyłania obrazu.": "",
        "Wystąpił problem w komunikacji z Api dostawcy podpisów. Kliknij ponownie Podpisz.": "",
        "Wystąpiły błędy podczas importu procesu": "There were errors during the process import",
        "Wysyłaj dane o użytkowniku": "",
        "Wysyłaj tym kanałem": "Send through this channel",
        "Wyszukaj": "Search",
        "Wyszukaj (Ctrl+Q)": "Search (Ctrl+Q)",
        "Wyszukaj aplikacje": "Search for applications",
        "Wyszukaj w działach podrzędnych": "Search in subdivisions",
        "Wyszukaj widget": "Search widget",
        "Wyszukaj wszędzie": "Search everywhere",
        "Wyszukaj...": "Search...",
        "Wyszukiwanie": "Search",
        "Wyszukiwanie pełnotekstowe": "Full-text search",
        "Wyszukiwanie plików": "File search",
        "Wyszukiwanie plików umożliwia asystentowi korzystanie z wiedzy z plików. Po przesłaniu pliku asystent automatycznie decyduje, kiedy pobrać treść, na podstawie żądań użytkownika.": "",
        "Wyszukiwany ciąg znaków": "Search set of characters",
        "Wyszukiwarka adresów ADE": "ADE Address Finder",
        "Wyszukiwarka dokumentów": "Document Finder",
        "Wyślij": "Send",
        "Wyślij do": "Send to",
        "Wyślij kod": "Send the code",
        "Wyślij link": "Send link",
        "Wyślij powiadomienie": "Send notification",
        "Wyślij wiadomość Microsoft Teams": "Send Microsoft Teams Message",
        "Wyświetl wszystko": "View all",
        "Wyświetlaj tylko dokumenty których użytkownik:": "Display only the documents of which the user:",
        "Wyświetlam": "Displaying",
        "Wyświetlamy pierwsze znalezione 10 tyś rekordów, wyników może być więcej, zawęź kryteria wyszukiwania": "Showing first 10000 records, there might be more results, narrow search criteria",
        "Wyświetlanie dokumentów": "Displaying documents",
        "Wyświetlany układ / dane": "",
        "Wytnij": "Cut",
        "Wywołanie funkcji": "",
        "Wyzeruj licznik": "Reset counter",
        "Wzorzec": "Pattern",
        "Wzorzec cyklu": "Cycle pattern",
        "Z uprawnieniami": "With permissions",
        "Z uprawnieniami do modułu": "With permission to module",
        "Zaakceptowane": "Accepted",
        "Zaakceptowano": "Accepted",
        "Zaakceptowany": "Accepted",
        "Zaawansowane uprawnienia": "Advanced powers",
        "Zabierz dostęp": "Take away access",
        "Zablokowany": "Blocked",
        "Zablokuj": "Block",
        "Zachowaj oryginalny format obrazów": "",
        "zacznij pisać aby wyszukać użytkownika...": "start typing to search for a user...",
        "Zacznij pisać Form lub Entry aby uruchomić podpowiadanie.": "Start typing Form or Entry to start prompting.",
        "Zaczyna sie od": "It starts with",
        "Zadanie": "Task",
        "Zadanie przebudowania indeksów zostało rozpoczęte.": "The task of rebuilding the indexes has begun.",
        "Zadanie przeindeksowania danych zostało rozpoczęte.": "The task of reindexing the data has begun.",
        "Zadanie zostało uruchomione.": "The task has started.",
        "Zadanie zostało włączone": "The task was included",
        "Zadanie zostało wyłączone": "The task was excluded",
        "Zagrożenie": "Threat",
        "Zaimportowane dane nadpiszą istniejące dane w module": "Imported data will overwrite existing data",
        "Zaimportowane dane zostaną dopisane do istniejących danych w module": "Imported data will be appended to existing module data",
        "Zaimportowano": "Imported",
        "Zaimportowano definicję modułu.": "A module definition has been imported.",
        "Zaimportowano listę użytkowników.": "",
        "Zakładka": "Bookmark",
        "Zakładki": "Bookmarks",
        "Zakolejkowane": "Pending",
        "Zakończ edycję": "Finish the edition",
        "Zakończ sesję": "End the session",
        "Zakończ sesję aby wylogować użytkownika": "End session to log out user",
        "Zakończone": "Completed",
        "Zakres": "Scope",
        "Zakres cyklu": "Scope of the cycle",
        "Zakres czasu": "Time range",
        "Zakres dat": "Date range",
        "Zaktualizuj formularz": "Update form",
        "Zależna od kontrolki": "Control dependent",
        "Zalogowany": "Logged in",
        "Zalogowany użytkownik": "Logged in user",
        "Zaloguj do SimplySign": "Log in to SimplySign",
        "Zaloguj się": "Sign In",
        "Załączniki": "Attachments",
        "Zamiany zostały zapisane.": "The changes have been saved.",
        "Zamknij": "Close",
        "Zamknij edycje": "Close editions",
        "Zamknij linię": "Close the line",
        "Zaokrąglenie": "Rounding",
        "Zapamiętaj logowanie": "Remember login",
        "Zapamiętane urządzenia": "Memorized devices",
        "Zapisano formularz": "The form has been saved",
        "Zapisuj do celu": "",
        "Zapisywanie obrazów zakończone.": "",
        "Zapisz": "Save",
        "Zapisz diagram": "Save the diagram",
        "Zapisz diagram w celu wyświetlenia błędów oraz przetestuj dokument": "Save the diagram to display errors and test the document",
        "Zapisz dokument jako nowy w PDF": "Save document as new in PDF",
        "Zapisz filtr": "Save filter",
        "Zapisz filtry": "Save filters",
        "Zapisz i wróć": "Save and return",
        "Zapisz i zamknij": "Save and close",
        "Zapisz jako": "Save As..",
        "Zapisz układ": "Save the layout",
        "Zapisz ustawienia": "Save settings",
        "Zapisz ustawienia widgetów": "Save widget settings",
        "Zapisz widget": "Save widget",
        "Zapisz wybrane": "Save selected",
        "Zapisz wynik we wskazanym polu": "Write the result in the indicated field",
        "Zapisz wynik wysyłki e-maila (powodzenie/niepowodzenie) we wskazanym polu": "Save e-mail sending result (success/fail) in selected field",
        "Zapisz zmiany": "Save changes",
        "Zaplanowane": "Scheduled",
        "Zapomniałeś hasła?": "Forgot your password?",
        "Zapoznał się": "Familiarized",
        "Zaproszenia do akceptacji": "Invitations for approval",
        "Zaproszenia usunięte/odrzucone": "Invitations removed/rejected",
        "Zaproszenie zostało odrzucone": "The invitation has been declined",
        "Zaproszenie zostało zaakceptowane": "The invitation has been accepted",
        "Zaproś uczestników": "Invite participants",
        "Zapytanie SQL do pobrania po ID": "",
        "Zapytanie SQL do wyszukiwania": "",
        "Zarchiwizowane": "Archived",
        "Zarządzaj filtrami": "Manage filters",
        "Zarządzaj kolumnami": "Manage columns",
        "Zasięg": "Range",
        "Zastępował": "Replaced",
        "zastępował: ": "substituted:",
        "Zastępstwa": "Substitutions",
        "Zastępstwo": "Add a substitution",
        "Zastępstwo wygasło.": "The substitution has expired.",
        "Zastępstwo wygaśnie za 10 minut.": "The substitution will expire in 10 minutes.",
        "Zastępstwo wygaśnie za 5 minut.": "The substitution will expire in 5 minutes.",
        "Zastępstwo wygaśnie za minutę.": "The substitution will expire in a minute.",
        "Zastępstwo zostało dodane.": "The substitution has been added.",
        "Zastępstwo zostało usunięte.": "The substitution has been removed.",
        "Zastępstwo zostało zaktualizowane.": "The substitution has been updated.",
        "Zastępuje osobę": "Substituting",
        "Zastosuj zmiany w aplikacji": "",
        "Zatrzymaj": "Stop",
        "Zatrzymaj udostępnianie": "Stop sharing",
        "Zatwierdź": "Approve",
        "Zawęź listę dokumentów do dokumentów powiązanych": "Restrict document list to related documents",
        "Zawęż listę dokumentów na podstawie kontrahenta": "Restrict document list based on contractor",
        "Zawiera": "Contains",
        "Zawsze": "Always",
        "Zaznacz": "Select",
        "Zaznacz wszystkie": "Select all",
        "Zbieracz kosztów": "Cost collector",
        "Zdarzenie": "Event",
        "Zdjęcie": "Photo",
        "Zdjęcie użytkownika": "User's photo",
        "Zespoły": "Teams",
        "Zespól": "",
        "Zespół": "Team",
        "Zespół aktywny": "Active team",
        "Zespół został dodany.": "The team has been added.",
        "Zespół został usunięty.": "The team has been removed.",
        "Zespół został zaktualizowany.": "The team has been updated.",
        "Zgoda": "Consent",
        "Zgrupuj pola": "Group the fields",
        "Złożenie podpisu zostało zakończone sukcesem": "Signing has been successfull",
        "Zmiana akceptantów": "Change of acceptors",
        "Zmiana hasła": "Password change",
        "Zmiana prywatności": "Change in privacy",
        "Zmiana rozmiaru obrazu": "Resize an image",
        "Zmiany ustawień monitoringu wymagają restartu aplikacji.": "",
        "Zmiany zaczną obowiązywać dopiero po przebudowaniu indeksów.": "The changes will not take effect until the indexes are rebuilt.",
        "Zmiany zostały zapisane.": "Changes saved.",
        "Zmiany zostaną zastosowane w aplikacji w przeciągu kikunastu sekund": "",
        "Zmieniono aktywną wersję": "The active version has been changed",
        "Zmieniono danę wyktorową": "Changed the dana vector",
        "Zmieniono kolekcję": "The collection has been changed",
        "Zmieniono konfigurację list": "Configuration of lists has been changed",
        "Zmieniono nazwę wersji": "Renamed version",
        "Zmieniono pola wspólne": "Common fields have been changed",
        "Zmieniono powiadomienie weryfikacyjne": "Revised verification notification",
        "Zmieniono ustawienia widgetu": "Widget settings changed",
        "Zmienna środowiskowa została usunięta": "Enviroment variable has been deleted.",
        "Zmienna została dodana.": "The variable has been added.",
        "Zmienna została zaktualizowana.": "The variable has been updated.",
        "Zmienna została zapisana": "The variable was written",
        "zmienną": "variable",
        "Zmienne": "Variables",
        "Zmienne środowiskowe": "Environment variables",
        "Zmień hasło": "Change password",
        "Zmień nazwę": "Rename",
        "Zmień nazwę katalogu": "Rename a directory",
        "Zmień nazwę pliku": "Rename a file",
        "Zmień rozmiar": "Resize",
        "Zmień status edytowanej wersji": "Change the status of the edited version",
        "Zmień wersję procesu": "Change process version",
        "Zmień widok": "Change view",
        "Zmniejsz": "Reduce",
        "zmodyfikował": "modified",
        "Zmodyfikował": "Modified by",
        "Zmodyfikowano": "Modified",
        "zmodyfikowany": "modified",
        "znaki": "characters",
        "Znaleziono kontrahentów o podanym NIPie.": "Contractors with the specified TIN are found.",
        "Zobacz sesję użytkownika": "View user session",
        "Zobacz szczegóły sesji użytkownika": "View user session details",
        "Zobacz szczegóły zdarzenia": "View event details",
        "Zobacz wszystkie": "See all",
        "Zobacz wszystkie zastępstwa": "See all substitutions",
        "zobaczył/a": "seen",
        "Został przywrócony oryginalny harmonogram": "The original schedule has been restored",
        "Zresetuj hasło": "Reset password",
        "Zrestartuj aplikacje": "",
        "Zwektoryzowane": "Vectorized",
        "Zwektoryzowano": "Vectorized",
        "Zwektoryzuj": "Vectorize",
        "Zwrócono": "Returned",
        "Zwrócony": "Returned",
        "Zwróć do edycji": "Return to edit",
        "Zwróć do opisu": "Return to description",
        "Źródła danych": "Data Sources",
        "Źródła LDAP": "LDAP sources",
        "Źródło": "Source",
        "Źródło aktywne": "Active source",
        "Źródło danych": "Data source",
        "Źródło danych jest wymagane": "Data source is required",
        "źródło danych zostało dodane.": "the data source has been added.",
        "Źródło danych zostało dodane.": "Data source added.",
        "Źródło danych zostało zaktualizowane.": "The data source has been updated.",
        "Źródło danych zostało zapisane.": "The source of the data has been recorded.",
        "Źródło LDAP zostało dodane.": "LDAP source has been added.",
        "Źródło LDAP zostało zaktualizowane.": "The LDAP source has been updated.",
        "źródło zostało usunięte.": "the source has been removed.",
        "Żaden kanał komunikacji nie został włączony w ustawieniach powiadomień.": "No communication channel has been enabled in the notification settings.",
        "Żródło danych": "Data source"
    }
};

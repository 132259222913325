<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { KeyValue, KeyValuePair } from '@/helpers/Interfaces';
import { Resource } from '@/modules/core/files/services/StorageService';
import { Options, Vue } from 'vue-class-component';
import IdeoImage from '@/components/ideo/image/IdeoImage.vue';
import FileList from '@/components/common/FileList.vue';
import ShareOneDriveModal from '@/modules/low-code/components/modals/ShareOneDriveModal.vue';
import { formatFileSize } from '@/helpers/Utils';
import { Attachment } from '@/modules/low-code/services/ShareOneDriveService';

@Options({
    name: 'ResourceFormatter',
    components: { IdeoImage, FileList, ShareOneDriveModal },
})
export default class ResourceFormatter extends Vue
{
    @Prop({ default: null }) public property: Resource | null;
    @Prop({ default: (): KeyValuePair[] => ([]) }) public features: KeyValuePair[];
    @Prop({ default: false }) public isOnGrid: boolean;
    @Prop({ default: null }) public itemKey: string;
    @Prop({ default: false }) public actions: Record<string, any>;
    @Prop({ default: null }) public oneDriveSharedDocuments: Array<Attachment> | null;

    public get canShare(): boolean
    {
        return this.actions?.canUploadOneDrive?.can ?? false;
    }

    public get isShared(): boolean
    {
        return this.unsharedfileArray.length == 0 && !this.isEmpty;
    }

    public get isEmpty(): boolean
    {
        if (this.property == null)
            return true;

        if (Array.isArray(this.property) && (this.property as Resource[]).length == 0)
            return true;

        return false;
    }

    public get unsharedfileArray(): KeyValue<string, string>[]
    {
        if (Array.isArray(this.property))
            return (this.property as Resource[])
                .filter(p => !(this.oneDriveSharedDocuments ?? []).some(x => x.attachmentId === p.publicId))
                .map(p =>
                {
                    return { key: p.publicId, value: p.name };
                });

        if ((this.oneDriveSharedDocuments ?? []).some(x => x.attachmentId === this.property.publicId))
            return [];

        return [{ key: this.property.publicId, value: this.property.name }];
    }

    public fileSizeBeautifier(sizeInBytes: number): string
    {
        return formatFileSize(sizeInBytes);
    }

    public showModal(): void
    {
        this.$events.$emit('share-document-one-drive-modal', this.unsharedfileArray, this.itemKey);
    }
}
</script>

<template>
    <div class="d-flex gap-1 align-items-center" :class="{ 'attachment-wrapper': isOnGrid }">
        <FileList v-if="Array.isArray(property)" :files="property" :is-on-grid="isOnGrid" />
        <FileList v-else-if="property" :file="property" :is-on-grid="isOnGrid" />
        <span v-else>-</span>

        <IdeoButton
            v-if="!isOnGrid && canShare && !isShared && !isEmpty"
            variant="outline-light"
            :title="$t('[[[Udostępnij w OneDrive]]]')"
            class="share__icon d-flex align-items-center"
            @click="showModal"
        >
            <i class="fas fa-cloud-arrow-up one-drive-button icon-btn-bigger"></i>
        </IdeoButton>
        <i v-else-if="!isOnGrid && isShared" v-tooltip="$t('[[[Dokument już jest udostępniony]]]')" class="fa-solid fa-circle-info p-1"></i>
    </div>
</template>

<style lang="scss" scoped>
.attachment-wrapper {
    max-width: 200px;
}
.one-drive-button
{
    font-size: 20px;
    color: #094ab2;
}
</style>

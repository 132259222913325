<template>
    <div v-if="!$auth.ready()">
        <loader></loader>
    </div>
    <router-view v-else />
    <vue-progress-bar></vue-progress-bar>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Watch } from '@/helpers/Decorators';
import Loader from '@/components/common/Loader.vue';
import CustomToast from './CustomToast.vue';
import axios from 'axios';
import { useThemeStore } from '@/store/theme';

@Options({
    components: {
        'loader': Loader
    }
})
export default class App extends Vue
{
    public appHash: string = '';
    public frequency: number = 2 * 60 * 1000;
    public themeStyles: string[] = ['light', 'dark'];

    public async created(): Promise<void>
    {
        if (import.meta.env.PROD)
        {
            await this.checkVersion(`${location.origin}/manifest.webmanifest`);
        }

        useThemeStore().initGrid();
        await useThemeStore().initColors();
    }

    public async checkVersion(url: string): Promise<void>
    {
        this.appHash = await this.loadManifest(url);

        setInterval(async () =>
        {
            const result = await this.loadManifest(url);

            if (result)
                this.appHash = result;
        },
        this.frequency);
    }

    public async loadManifest(url: string): Promise<string>
    {
        try
        {
            const http = axios.create();
            const time = new Date();

            time.setSeconds(0);
            time.setMilliseconds(0);

            return (await http.get(`${url}?t=${time.getTime()}`)).data.hash;
        }
        catch (ex)
        {
            this.handleException(ex, {
                0: (ex: any) => this.$alert.danger(ex.message)
            });

            return null;
        }
    }

    @Watch('appHash')
    public onHashChanged(value: string, old: string): void
    {
        if (value != old && old)
        {
            const content = {
                component: CustomToast,
                props: {
                    message: this.$t('[[[Dostępna jest nowa aktualizacja. Proszę zapisać postęp pracy i klinąć przycisk "Aktualizuj". Możesz również zaakceptować aktualizację poprzez odświeżenie okna przeglądarki. Brak aktualizacji może spowodować błędy.]]]'),
                    update: this.$t('[[[Aktualizuj]]]')
                },
                listeners: {
                    reload: () =>
                    {
                        location.reload();
                    }
                }
            };

            this.$alert.show(content, {
                type: "info",
                timeout: false,
                closeButton: false,
                closeOnClick: false,
                draggable: false
            });
        }
    }
}
</script>

<style lang="scss">
    // Import Main styles for this application
    @import "@/assets/scss/style";
</style>

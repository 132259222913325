<script lang="ts" setup>
import { ref, computed, onMounted, nextTick, watch, useSlots } from 'vue';
import { useModalStore } from '@/modules/core/common/store/modal';
import { normalizeClasses } from '@/helpers/Utils';

defineOptions({
    name: 'ideo-modal',
    inheritAttrs: false
});

const props = defineProps({
  "modelValue": { type: Boolean,  },
  "title": { default: null },
  "static": { type: Boolean,  },
  "centered": { type: Boolean,  },
  "scrollable": { type: Boolean,  },
  "fullscreen": { type: Boolean,  },
  "size": { default: 'md' },
  "hideHeader": { type: Boolean,  },
  "hideFooter": { type: Boolean,  },
  "hideClose": { type: Boolean,  },
  "modalClass": { default: () => ({}) },
  "backdropClass": { default: () => ({}) },
  "contentClass": { default: () => ({}) },
  "headerClass": { default: () => ({}) },
  "bodyClass": { default: () => ({}) },
  "footerClass": { default: () => ({}) }
});

const emit = defineEmits(["ok", "close", "hidden", "shown", "update:modelValue"]);

const slots = useSlots();
const modalStore = useModalStore();

const isMounted = ref(false);
const isVisible = ref(false);
const modalZIndex = ref(10313);

const backdropClasses = computed(() => ({
    'd-none': !isVisible.value,
    ...normalizeClasses(props.backdropClass)
}));

const modalClasses = computed(() => ({
    'd-block': isVisible.value,
    'scroll scroll--modal': true,
    ...normalizeClasses(props.modalClass)
}));

const flag = (value: any): boolean =>
{
    return value !== false;
};

const dialogClasses = computed(() => ({
    'modal-dialog-centered': flag(props.centered),
    'modal-dialog-scrollable': flag(props.scrollable),
    'modal-fullscreen': flag(props.fullscreen),
    [`modal-${props.size}`]: props.size != 'md'
}));

const contentClasses = computed(() => normalizeClasses(props.contentClass));
const headerClasses = computed(() => normalizeClasses(props.headerClass));
const bodyClasses = computed(() => normalizeClasses(props.bodyClass));
const footerClasses = computed(() => normalizeClasses(props.footerClass));

const hasHelperSlot = computed(() => !!slots.helper);

onMounted(() =>
{
    isMounted.value = true;
});

const updateModel = (): void =>
{
    emit('update:modelValue', isVisible.value);
};

const hide = (): void =>
{
    modalStore.decrementOpenedModalCounter();

    modalZIndex.value = 10313;
    isVisible.value = false;
    updateModel();

    nextTick(() =>
    {
        emit('hidden');
    });
};

const show = (): void =>
{
    const counter = modalStore.incrementOpenedModalCounter();

    modalZIndex.value = modalZIndex.value + counter;
    isVisible.value = true;
    updateModel();

    nextTick(() =>
    {
        emit('shown');
    });
};

const onOk = (): void =>
{
    hide();
    emit('ok');
};

const onClose = (): void =>
{
    hide();
    emit('close');
};

watch(() => props.modelValue, (value: boolean, old: boolean) =>
{
    if (value != old)
        isVisible.value = value;
}, { immediate: true });

const toggle = (): void =>
{
    if (isVisible.value)
        hide();
    else
        show();
};

const onOutOfFocus = (): void =>
{
    if (!flag(props.static))
        hide();
};

defineExpose({
    toggle,
    show,
    hide
});
</script>

<template>
    <teleport to="body" v-if="isMounted && isVisible">
        <div class="modal-backdrop" :class="backdropClasses"></div>
        <div class="modal" v-bind="$attrs" :class="modalClasses" @mousedown.self="onOutOfFocus">
            <div class="modal-dialog" :class="dialogClasses">
                <div class="modal-content" :class="contentClasses" @click.stop>
                    <div class="modal-header" :class="headerClasses" v-if="!flag(hideHeader)">
                        <slot name="modal-header">
                            <h5 class="modal-title">{{ title }}</h5>
                            <button type="button" class="close" @click.stop="onClose" v-if="!flag(hideClose)">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </slot>
                    </div>
                    <div class="modal-body" :class="bodyClasses">
                        <div class="row">
                            <div class="mb-3 mb-lg-0 d-flex flex-column flex-fill" :class="hasHelperSlot ? 'col-lg-7' : 'col-lg-12'">
                                <slot name="default"></slot>
                            </div>
                            <div v-if="hasHelperSlot" class="col-lg-5">
                                <slot name="helper"></slot>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer" :class="footerClasses" v-if="!flag(hideFooter)">
                        <slot name="modal-footer" :ok="() => onOk()" :cancel="() => onClose()">
                            <button type="button" class="btn btn-secondary" @click.stop="onClose">
                                <slot name="btn-close">
                                    {{ $t('[[[Zamknij]]]') }}
                                </slot>
                            </button>
                            <button type="button" class="btn btn-primary" @click.stop="onOk">
                                <slot name="btn-submit">
                                    {{ $t('[[[Ok]]]') }}
                                </slot>
                            </button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>

<style lang="scss">
.modal-backdrop {
    opacity: 0.5;
    z-index: calc(v-bind(modalZIndex) - 1) !important;
}

.modal {
    overflow-x: hidden !important;
    overflow-y: auto !important;
    z-index: v-bind(modalZIndex) !important;

    button.close {
        background-color: transparent;
        border: 0;
        font-size: 1.21875rem;
        font-weight: 700;
        line-height: 1;
    }
}

.modal-dialog-sm {
    max-width: 300px !important;
}

.modal-dialog-lg {
    max-width: 800px !important;
}

.modal-dialog-xl {
    max-width: 1140px !important;
}

.modal-xxl {
    max-width: 1600px !important;
}
</style>

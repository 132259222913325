<script lang="ts" setup>
import { ref, reactive, computed } from 'vue';
import { useAlerts } from '@/plugins/alerts';
import { useLocalization } from '@/plugins/localization';
import Pager from '@/helpers/Pager';
import { Form } from '@/helpers/Form';
import ShareOneDriveService from '@/modules/low-code/services/ShareOneDriveService';
import StopShareOneDriveModal from './StopShareOneDriveModal.vue';

const props = defineProps({
  "parentType": null,
  "documentId": null,
  "actions": { default: (): Record<string, any> => ({}) },
  "blueprintName": null
});

const { $alert } = useAlerts();
const { $t } = useLocalization();

const url = 'one-drive';
const modal = ref<InstanceType<typeof StopShareOneDriveModal> | null>(null);
const pager = reactive(new Pager(1, 10, 'Id', 'DESC')) as Pager;
const filter = reactive(Form.create({}));

const endpoint = computed(() => `${props.parentType}/${props.documentId}/${url}`);

pager.setTotalRows(0);

const redirectToDocument = (fileUrl: string) =>
{
    window.open(fileUrl, '_blank');
};

const stopShareDocument = (publicId: string) =>
{
    modal.value?.showModal(publicId);
};

const downloadDocument = async (publicId: string, fileName: string) =>
{
    try
    {
        const result = await ShareOneDriveService.downloadDocument(publicId);
        const a = document.createElement('a');

        a.href = URL.createObjectURL(result);
        a.download = fileName;
        a.click();

        $alert.success($t('[[[Dokument został pobrany]]]'));
    }
    catch (ex: any)
    {
        if (ex.code === 400) $alert.warning($t('[[[Błąd podczas pobierania]]]'));
    }
};
</script>

<template>
    <div>
        <dynamic-grid
            :pager="pager"
            :filter="filter"
            :endpoint="endpoint"
            :row-click="(item: any) => redirectToDocument(item.result.fileUrl)"
            :scroll-click="(item: any) => redirectToDocument(item.result.fileUrl)"
            :manual-init="true"
        >
            <template #header>
                <ideo-panel :column="false" :stretch="false" :start="desktop">
                    <template #end>
                        <export-manager
                            :excel="{ endpoint }"
                            :filter="filter"
                            :pager="pager"
                        />
                    </template>
                </ideo-panel>
            </template>

            <template #actions="{ item }">
                <row-actions>
                    <ideo-dropdown-item-button
                        v-if="item.result.status?.key !== 'Readonly' && actions?.canUploadOneDrive?.can"
                        @click="stopShareDocument(item.result.publicId)"
                    >
                        <i class="fas fa-stop"></i>
                        <span>{{ $t('[[[Zatrzymaj udostępnianie]]]') }}</span>
                    </ideo-dropdown-item-button>
                    <ideo-dropdown-item-button @click="downloadDocument(item.result.publicId, item.result.fileName)">
                        <i class="fas fa-download"></i>
                        <span>{{ $t('[[[Pobierz]]]') }}</span>
                    </ideo-dropdown-item-button>
                </row-actions>
            </template>

            <template #modal>
                <stop-share-one-drive-modal ref="modal" />
            </template>
        </dynamic-grid>

        <portal v-if="blueprintName" :to="`tab-counter-${blueprintName}`">
            {{ pager.getTotalRows() }}
        </portal>
    </div>
</template>

<script lang="ts" setup>
import { computed, useSlots } from 'vue';
import { useMedia } from '@/plugins/media';

const props = defineProps({
  "label": { default: '' },
  "settings": { default: () => ({
            boldLabel: false,
            boldValue: false,
            colorLabel: '',
            colorValue: '',
            tooltip: '',
            icon: '',
        }) }
});

const slots = useSlots();
const media = useMedia();

const hasDefaultSlot = computed(() => slots.default);

const label = computed(() => props.label || '');
const boldLabel = computed(() => props.settings.boldLabel || false);
const boldValue = computed(() => props.settings.boldValue || false);
const colorLabel = computed(() => props.settings.colorLabel);
const colorValue = computed(() => props.settings.colorValue);
const tooltip = computed(() => props.settings.tooltip || '');
const icon = computed(() => props.settings.icon || '');
const showLabelAboveValue = computed(() => props.settings.showLabelAboveValue || false);
const isLabelCustomWidth = computed(() => props.settings.isLabelCustomWidth || false);
const labelWidth = computed(() => props.settings.labelWidth || 0);

const labelStyles = computed(() => ({
    color: colorLabel.value,
    width: isLabelCustomWidth.value && labelWidth.value && !media.phone() ? `${labelWidth.value}px` : '',
}));

const valueStyles = computed(() => ({
    color: colorValue.value,
}));

const isIconCodeProper = computed(() =>
{
    const iconRegex = new RegExp('^fa[a-z-\\s]+$');

    return iconRegex.test(icon.value);
});
</script>

<template>
    <div class="details-label" :class="showLabelAboveValue ? 'flex-column' : ''">
        <div :style="labelStyles" class="key" :class="{ bold: boldLabel }">
            <span v-tooltip.right="tooltip" class="pe-1">{{ label }}:</span>
        </div>
        <div :style="valueStyles" class="value" :class="{ bold: boldValue }">
            <div v-tooltip.right="tooltip" class="overflow-hidden">
                <slot v-if="hasDefaultSlot" />
                <span v-else>-</span>
            </div>
            <i v-if="isIconCodeProper" v-tooltip.right="tooltip" class="ms-2 pe-1" :class="icon" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.details-label {
    display: flex;
    flex-direction: column;
    width: 100%;
    white-space: break-spaces;
    font-size: 0.8125rem;
    container-type: inline-size;

    .key {
        flex-shrink: 0;
        width: 100%;
    }

    .value {
        display: flex;
        align-items: center;
        overflow: hidden;
        flex: 1 1 100%;
    }

    .bold {
        font-weight: 600;
    }

    &:not(.flex-column) {
        @include media-breakpoint-up(sm) {
            flex-direction: row;

            .key {
                width: 100px;
                margin-right: 10px;
            }

            @container (width > 300px) {
                .key {
                    width: 150px;
                    margin-right: 20px;
                }
            }

            @container (width > 500px) {
                .key {
                    width: 200px;
                }
            }

            @container (width > 700px) {
                .key {
                    width: 300px;
                }
            }

            @container (width > 900px) {
                .key {
                    width: 350px;
                }
            }

            @container (width > 1200px) {
                .key {
                    width: 400px;
                }
            }
        }
    }
}
</style>

<script lang="ts" setup>
import { ref, computed } from 'vue';
// import { TableType } from '../blueprints/table';
import { v4 as uuidv4 } from 'uuid';
import { Codemirror } from 'vue-codemirror';
import { javascript } from '@codemirror/lang-javascript';

const model = defineModel<string>();

defineProps({
  "label": null,
  "showLabel": { type: Boolean,  },
  "type": { default: 'text' }
});

const modal = ref(false);
const buffer = ref<string>(null);
const errorMessage = ref<string>(null);

const uid = computed(() => `form-field-${uuidv4()}`);

const openEditor = () =>
{
    modal.value = true;
};

const saveExpression = () =>
{
    model.value = buffer.value;
    closeEditor();
};

const closeEditor = () =>
{
    modal.value = false;
};

const init = () =>
{
    buffer.value = model.value;
};

init();
</script>

<template>
    <div class="form-group">
        <label :for="uid" v-if="showLabel">{{ label }}</label>
        <div class="input-group">
            <input
                type="text"
                class="form-control pointer"
                :readonly="true"
                :id="uid"
                :value="model"
                :placeholder="showLabel ? '' : label"
                @click="openEditor"
            />
            <span class="btn btn-secondary" @click="openEditor">
                <i class="fas fa-cog"></i>
            </span>
        </div>
        <small class="form-text text-danger" v-if="errorMessage">
            {{ $t(errorMessage) }}
        </small>
        <ideo-modal id="field-with-helper-modal" v-model="modal" :title="label" size="xxl" centered scrollable>
            <template #default>
                <div class="row">
                    <div class="col-lg-7 mb-3 mb-lg-0">
                        <Codemirror
                            v-model="buffer"
                            :style="{ maxHeight: '800px;', minHeight: '800px;' }"
                            :autofocus="true"
                            :indent-with-tab="false"
                            :tab-size="4"
                            :extensions="[javascript()]"
                        />
                    </div>
                    <div class="col-lg-5">
                        <slot name="helper"></slot>
                    </div>
                </div>
            </template>
            <template #modal-footer>
                <button class="btn btn-primary" @click.stop.prevent="saveExpression">
                    {{ $t('[[[Zapisz]]]') }}
                </button>
                <ideo-button variant="secondary" @click.stop.prevent="closeEditor">
                    {{ $t('[[[Anuluj]]]') }}
                </ideo-button>
            </template>
        </ideo-modal>
    </div>
</template>

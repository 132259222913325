import { axios } from '@/plugins/axios';
import { Resource, KeyValuePair, Statement, Pagination } from "@/helpers/Interfaces";
import Pager from '@/helpers/Pager';
import { merge } from 'lodash';
import { DateTime } from 'luxon';

export default class RegisterDocumentsService
{
    public static async saveRegister(form: CreateModel): Promise<Statement>
    {
        return (await axios.post('simple-command/registers', form)).data;
    }

    public static async saveModuleRegister(form: CreateModel, moduleId: string): Promise<Statement>
    {
        return (await axios.post(`simple-command/modules/${moduleId}/module-registers`, form)).data;
    }

    public static async editRegister(id: string, form: CreateModel): Promise<boolean>
    {
        return (await axios.put(`simple-command/registers/${id}`, form)).data;
    }

    public static async getRegister(id: string): Promise<Resource<RegisterDetailsModel>>
    {
        return (await axios.get<Resource<RegisterDetailsModel>>(`simple-fetch/registers/${id}`)).data;
    }

    public static async getCounters(id: string, pager: Pager): Promise<Pagination<Resource<CountersModel>>>
    {
        return (await axios.get(`simple-query/registers/${id}/counters`, {
            params: merge({}, pager.data())
        })).data;
    }

    public static async openCounter(id: string, year: string): Promise<CountersModel>
    {
        return (await axios.post<CountersModel>(`simple-command/registers/${id}/counter-open`, {
            year
        })).data;
    }

    public static async closeCounter(id: string, year: string): Promise<CountersModel>
    {
        return (await axios.post<CountersModel>(`simple-command/registers/${id}/counter-close`, {
            year
        })).data;
    }

    public static async reserveSignature(registerId: string, issueDate: DateTime): Promise<string>
    {
        return (await axios.post(`office/registers-reservations`, {
            registerId,
            issueDate
        })).data;
    }

    public static async getReservation(registerId: string, issueDate: DateTime): Promise<string>
    {
        return (await axios.get('office/registers-reservations', {
            params: { registerId, issueDate }
        })).data;
    }
}

export interface RegistersListItemModel {
    publicId: string;
    companyId: KeyValuePair;
    isActive: boolean;
    symbol: string;
    name: string;
    documentType: KeyValuePair;
}

export interface FilterModel {
    status: any;
}

export interface CreateModel {
    companyId: KeyValuePair | number;
    documentType: KeyValuePair | string;
    processes: number[];
    objectType: KeyValuePair | string;
    type: KeyValuePair | string;
    validTo: string;
    symbol: string;
    name: Record<string, string>;
    formId: KeyValuePair | number;
    numbericTemplate: string;
    signatureNumberDigits: number;
    isFullyElectronic: boolean;
    canSeeAllUsers: number[];
    canAddUsers: number[];
    canSeeAllTeams: number[];
    canAddTeams: number[];
    canSeeAllUnits: number[];
    canAddUnits: number[];
    everyoneCanAdd: boolean;
    archiveCategoryParentCell: number;
    archiveCategoryOtherCells: number;
}

export interface CountersModel {
    canClose: boolean,
    canOpen: boolean
    isClosed: boolean;
    nextNumber: number;
    year: number;
}

export interface RegisterDetailsModel {
    publicId: string;
    validTo: string;
    symbol: string;
    name: string;
    numbericTemplate: string;
    companyId: KeyValuePair | number;
    documentType: KeyValuePair | number;
    objectType: KeyValuePair | string;
    isFullyElectronic: boolean;
    canSeeAll: number[];
    canAdd: number[];
    archiveCategoryParentCell: KeyValuePair | string;
    archiveCategoryOtherCells: KeyValuePair | string;
}

<script lang="ts" setup>
import { computed } from 'vue';
import { KeyValuePair } from '@/helpers/Interfaces';
import { useLocalization } from '@/plugins/localization';

const { $t } = useLocalization();

const props = defineProps({
  "property": { type: Boolean, default: false },
  "features": { default: (): KeyValuePair[] => [] },
  "isOnGrid": { type: Boolean, default: false }
});

const isBadge = computed(() =>
{
    const badge = props.features.find((feature) => feature.key === 'Badge');

    return !!badge;
});

const getContent = computed(() =>
{
    const type = props.features.find((elem) => elem.key === 'Badge');

    let func = '';

    if (type)
    {
        func = type.name;

        if (func === 'enabledBadge')
            return props.property ? $t('[[[Opublikowany]]]') : $t('[[[Nieopublikowany]]]');
    }

    return props.property ? $t('[[[Tak]]]') : $t('[[[Nie]]]');
});

const getVariant = computed(() =>
{
    return props.property ? 'success' : 'danger';
});

</script>

<template>
    <div class="boolean-formatter d-flex" v-if="property !== null">
        <span v-if="!isBadge">{{ getContent }}</span>
        <ideo-badge v-else :variant="getVariant" class="status-badge" :title="getContent">
            {{ getContent }}
        </ideo-badge>
    </div>
</template>

<template>
    <div class="filter-select filter-dropdown-object">
        <ideo-dropdown
            boundry="body"
            :variant="variant"
            no-caret
            dropdown
            @show="dropdownShown"
            @hide="
                () => {
                    opened = false;
                    clearSearchData();
                }
            "
            :toggle-class="toggleClasses"
            :menu-class="menuClasses"
            :container-class="containerClasses"
            :top-offset="6"
            ref="select"
        >
            <template #button-content>
                <template v-if="!multiselect">
                    <div class="label-value">
                        <span v-if="label" class="label" :class="{ 'label--bigger': !currentValue }">
                            {{ label }}
                            {{ currentValue ? ':' : '' }}
                        </span>
                        <span v-if="currentValue" class="value">{{ currentValue[textField] }}</span>
                    </div>
                    <i class="fas fa-chevron-down arrow ms-2" :class="{ 'opened-arrow': opened }"></i>
                    <div v-if="currentValue" class="btn-remove ms-2" @click.stop="clearModelData">
                        <i class="fas fa-times"></i>
                    </div>
                </template>
                <template v-else>
                    <div class="label-value">
                        <span v-if="label && currentValue.length" class="label">{{ label }}</span>
                        <span class="value">{{ currentValue.length ? currentValue[0][textField] : placeholder }}</span>
                    </div>
                    <span v-if="currentValue.length > 1" class="label-badge ms-2">+ {{ currentValue.length - 1 }}</span>
                    <i class="fas fa-chevron-down arrow ms-2" :class="{ 'opened-arrow': opened }"></i>
                    <div v-if="currentValue.length" class="btn-remove ms-2" @click.stop="clearModelData">
                        <i class="fas fa-times"></i>
                    </div>
                </template>
            </template>
            <div style="min-width: 230px" class="radius-4" :class="{ show: opened }">
                <div class="p-2">
                    <ideo-form-radio-group stacked>
                        <ideo-form-radio
                            v-model="mode"
                            value="currentUser"
                            @change="onModeChanged"
                        >
                            {{ $t('[[[Zalogowany użytkownik]]]') }}
                        </ideo-form-radio>
                        <ideo-form-radio
                            v-model="mode"
                            value="selectedUser"
                            @change="onModeChanged"
                        >
                            {{ $t('[[[Wybrany użytkownik]]]') }}
                        </ideo-form-radio>
                    </ideo-form-radio-group>
                </div>
                <div class="dropdown__input-row" v-if="mode == 'selectedUser'">
                    <div class="dropdown__input-wrapper">
                        <input
                            v-model="filter.search"
                            type="text"
                            :placeholder="$t('[[[Wyszukaj...]]]')"
                            class="me-2 search-bar flex-grow-1"
                            ref="search-bar"
                            @input="searchData"
                            @keydown="focusOnList"
                        />
                        <span class="border-0 bg-transparent p-1">
                            <i v-if="!filter.search" class="fas fa-search"></i>
                            <button
                                v-else
                                class="border-0 p-0 bg-transparent clear-button"
                                style="color: inherit"
                                @click.stop="clearSearchData"
                            >
                                <span class="fas fa-times"></span>
                            </button>
                        </span>
                    </div>
                </div>
                <div class="additional-options-section py-2" v-if="additionalOptionsForSelect.length">
                    <span class="d-block mb-2 ms-2" style="font-weight: 500">{{ $t('[[[Dodatkowe opcje]]]') }}:</span>
                    <div class="p-2 d-flex" v-for="(item, index) in additionalOptionsForSelect" :key="index">
                        <component :is="item.component" :id="`options-${index}`" v-model="globalFilter[item.model]" />
                        <label class="m-0 ms-2 text-nowrap" :for="`options-${index}`">{{ item.label }}</label>
                    </div>
                    <hr class="my-2" />
                </div>
                <div v-if="mode === 'selectedUser'" class="dropdown__list-content scroll" @scroll="listHasBeenScrolled">
                    <template v-if="!fetching && optionsToRender.length">
                        <div
                            v-for="(item, index) in optionsToRender"
                            tabindex="0"
                            class="dropdown__list-content__item"
                            :key="index"
                            :class="{ 'selected-option': isSelected(item) && !multiselect }"
                            @click="setNewValue(item)"
                            @keydown="reactToKeys($event, item)"
                        >
                            <template v-if="!multiselect">
                                {{ item[textField] || $t('[[[Brak wartości wyświetlanej]]]') }}
                            </template>
                            <template v-else>
                                <span class="fas fa-check-square text-primary me-2" v-if="isSelected(item)"></span>
                                <span v-else class="far fa-square me-2"></span>
                                {{ item[textField] || $t('[[[Brak wartości wyświetlanej]]]') }}
                            </template>
                        </div>
                    </template>
                    <template v-else-if="optionsToRender.length">
                        <div class="fetching-indicator">
                            <div class="lds-dual-ring"></div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="d-flex flex-column align-items-center p-3">
                            <i class="fas fa-question-square fa-2x"></i>
                            <span class="mt-2 h5">{{ $t('[[[Brak wyników!]]]') }}</span>
                        </div>
                    </template>
                </div>
            </div>
        </ideo-dropdown>
    </div>
</template>

<script lang="ts">
import { Debounce, Emit, Prop, Ref, Watch } from '@/helpers/Decorators';
import { Options, Vue } from 'vue-class-component';
import AutocompleteService, {
    AutocompleteFilterModel,
    FetchSettings,
    IAdditionalOptions,
} from '@/modules/core/common/services/AutocompleteService';
import { Form, FormType } from '@/helpers/Form';
import Pager from '@/helpers/Pager';
import IdeoDropdown from '@/components/ideo/dropdown/IdeoDropdown.vue';
@Options({
    name: 'UserAutocomplete',
    emits: ['update:modelValue'],
})
export default class UserAutocomplete extends Vue
{
    @Ref('search-bar')
    public input: () => HTMLInputElement;

    @Ref('select')
    public select: () => InstanceType<typeof IdeoDropdown> | null;

    @Prop({ default: null })
    public modelValue: Record<string, any> | null;

    @Prop({ default: '' })
    public placeholder: string;

    @Prop({ default: '' })
    public label: string;

    @Prop({ default: (): any[] => [] })
    public options: Record<string, any>[];

    @Prop({ default: 'key' })
    public valueField: string;

    @Prop({ default: 'value' })
    public textField: string;

    @Prop({ default: 10 })
    public howManyMoreLoad: number;

    @Prop({ default: false })
    public initialLoad: boolean;

    @Prop({ default: false })
    public multiselect: boolean;

    @Prop({ default: null, required: true })
    public fetchSettings: FetchSettings;

    @Prop({ default: (): any => [] })
    public additionalOptions: IAdditionalOptions[];

    @Prop({ default: {} })
    public globalFilter: FormType<any>;

    @Prop({ default: '' })
    public customEndpoint: string;

    @Prop({ default: null })
    public sitemapId: string;

    @Prop({ default: null })
    public extraParams: any;

    @Prop({ default: null })
    public filterEmitId: string;

    public currentValue: Record<string, any> | null = null;
    public opened: boolean = false;
    public fetchedOptions: Record<string, any>[] = [];
    public fetching: boolean = false;
    public internalOptions: Record<string, any>[] = [];
    public alreadyFetched: boolean = false;
    public mode: string = 'selectedUser';

    public filter: FormType<AutocompleteFilterModel> = Form.create<AutocompleteFilterModel>({
        search: '',
    });
    public pager: Pager = new Pager(1, 10, '', 'ASC');

    public openOnFocus(): void
    {
        this.select().show();
    }

    public get toggleClasses(): Record<string, boolean>
    {
        return {
            'filter-button': true,
            'bg-primary border-primary':
                (!this.multiselect && (!!this.modelValue || this.opened)) ||
                (this.multiselect && this.modelValue?.length),
        };
    }

    public get menuClasses(): Record<string, boolean>
    {
        return {
            'p-0': true,
            'filter-dropdown-object__dropdown': true,
            'border-0': true,
        };
    }

    public get containerClasses(): Record<string, boolean>
    {
        return {
            'filter-container': true,
        };
    }

    public get optionsToRender(): Record<string, any>[]
    {
        if (this.options.length) return this.internalOptions;

        return this.fetchedOptions;
    }

    public get additionalOptionsForSelect(): any[]
    {
        const componentsToGenerate: any = [];

        this.additionalOptions.forEach((item) =>
        {
            componentsToGenerate.push({ component: item.type, model: item.field, label: item.label });
        });

        return componentsToGenerate;
    }

    public get variant()
    {
        if (this.opened) return "primary";

        if (this.multiselect && this.currentValue?.length) return "primary";

        if (!this.multiselect && this.currentValue) return "primary";

        return "secondary";
    }

    public async dropdownShown(): Promise<void>
    {
        this.opened = true;

        if (!this.alreadyFetched) this.refetchData();

        await this.$nextTick();

        this.input()?.focus?.();
    }

    public searchData(): void
    {
        if (!this.options.length) this.refetchData();
        else
        {
            if (!this.filter.search) this.internalOptions = this.options;

            this.internalOptions = this.options.filter((option) =>
            {
                return option[this.textField].toLowerCase().includes(this.filter.search.toLowerCase());
            });
        }
    }

    @Debounce(300)
    public async refetchData(): Promise<void>
    {
        this.fetching = true;
        await this.fetchOptions();
        this.fetching = false;
        this.alreadyFetched = true;
    }

    public async fetchOptions(): Promise<void>
    {
        try
        {
            const { totalRows, items } = this.customEndpoint
                ? await AutocompleteService.fetchOptionsByCustomEndpoint(
                    this.customEndpoint,
                    this.filter.data(),
                    this.pager,
                    null,
                    this.sitemapId,
                    this.extraParams
                )
                : await AutocompleteService.fetchOptions(
                    this.fetchSettings.module,
                    'filter',
                    this.fetchSettings.propType,
                    this.filter.data(),
                    this.pager,
                    null,
                    null,
                    this.fetchSettings.actionName,
                    this.sitemapId,
                    this.extraParams
                );

            this.pager.setTotalRows(totalRows);

            const options = items.map((item) =>
            {
                return { key: item.result.key, value: item.result.value };
            });

            this.fetchedOptions = options;
        }
        catch (ex)
        {
            this.fetchedOptions = [];
        }
    }

    public isSelected(option: Record<string, any>): boolean
    {
        if (this.multiselect)
        {
            const item = this.currentValue?.find(
                (x: Record<string, unknown>) => x[this.valueField] === option[this.valueField]
            );

            if (this.currentValue?.includes(option) || item) return true;

            return false;
        }

        if (this.currentValue && this.currentValue[this.valueField] === option[this.valueField]) return true;

        return false;
    }

    public reactToKeys(event: KeyboardEvent, option: Record<string, any>): void
    {
        const focusableOptions = Array.from<HTMLDivElement>(
            document.querySelectorAll('.dropdown__list-content__item:not(.disabled)')
        );
        const index = focusableOptions.indexOf(document.activeElement as HTMLDivElement);

        // prevent in every specific case to not stop default while tab is pressed
        if (event.key === 'Enter')
        {
            event.preventDefault();
            this.setNewValue(option);

            if (this.filterEmitId)
                this.$events.$emit(this.filterEmitId);
            else
                this.$events.$emit('applyFilters');
        }

        if (event.key === 'ArrowUp')
        {
            // move focus up
            event.preventDefault();

            if (index === 0 && this.input()) this.input().focus();
            else if (index === 0) focusableOptions[0].focus();
            else focusableOptions[index - 1].focus();
        }

        if (event.key === 'ArrowDown')
        {
            // move focus down
            event.preventDefault();

            if (index === focusableOptions.length - 1) focusableOptions[focusableOptions.length - 1].focus();
            else focusableOptions[index + 1].focus();
        }
    }

    // listen for scroll to reach bottom line of container
    public listHasBeenScrolled(e: Event): void
    {
        const container = e.target as HTMLDivElement;
        const triggerHeight = container.scrollTop + container.offsetHeight;
        const buffer = 20;

        if (triggerHeight + buffer >= container.scrollHeight)
        {
            if (!(this.pager.getPageSize() >= this.pager.getTotalRows()))
            {
                const oldPageSize = this.pager.getPageSize();

                this.pager.setPageSize(this.pager.getPageSize() + this.howManyMoreLoad);

                const newPageSize = this.pager.getPageSize();

                if (oldPageSize !== newPageSize) this.fetchOptions();
            }
        }
    }

    public focusOnList(event: KeyboardEvent): void
    {
        if (event.key === 'ArrowDown')
        {
            event.preventDefault();

            const focusableOptions = Array.from<HTMLDivElement>(
                document.querySelectorAll('.dropdown__list-content__item:not(.disabled)')
            );

            focusableOptions[0].focus();
        }
    }

    public clearSearchData(): void
    {
        if (this.filter.search)
        {
            this.filter.reset();
            this.alreadyFetched = false;
            this.fetchedOptions = [];
        }
    }

    public created(): void
    {
        if (this.multiselect)
        {
            this.modelValue?.length ? (this.currentValue = this.modelValue) : (this.currentValue = []);
        }
        else
        {
            if (this.modelValue && typeof this.modelValue === 'object') this.currentValue = this.modelValue;
            else
                this.currentValue =
                    this.optionsToRender.find(
                        (option) => option[this.valueField] === this.modelValue[this.valueField]
                    ) || null;
        }

        if (!this.options.length && this.initialLoad) this.fetchOptions();
    }

    public clearModelData(): void
    {
        if (this.multiselect) this.currentValue = [];
        else this.currentValue = null;

        this.mode = 'selectedUser';

        this.$emit('update:modelValue', this.currentValue);
        this.$events.$emit('refetchData', { resetPageIndex: true });
    }

    @Emit('update:modelValue')
    public onModeChanged(mode: string): Record<string, any>
    {
        if (mode == 'currentUser')
        {
            if (this.multiselect) this.currentValue = [{ key: 'CurrentUser', value: 'Zalogowany użytkownik' }];
            else this.currentValue = { key: 'CurrentUser', value: 'Zalogowany użytkownik' };
        }
        else this.currentValue = null;

        return this.currentValue;
    }

    @Emit('update:modelValue')
    public setNewValue(option: Record<string, any>): Record<string, any>
    {
        if (this.multiselect)
        {
            const item = this.currentValue.find(
                (x: Record<string, unknown>) => x[this.valueField] === option[this.valueField]
            );

            if (this.currentValue?.includes(option) || item)
            {
                this.currentValue = this.currentValue.filter(
                    (item: any) => item[this.valueField] !== option[this.valueField]
                );

                return this.currentValue;
            }
            else
            {
                if (this.currentValue.length >= 10)
                {
                    this.$alert.warning(this.$t('[[[Możesz wybrać maksymalnie 10 elementów]]]'));

                    return this.currentValue;
                }

                this.currentValue.push(option);

                return this.currentValue;
            }
        }
        else
        {
            this.currentValue = option;
        }

        this.select().hide();

        return this.currentValue;
    }

    @Watch('options', { immediate: true })
    public optionsChanged(options: Record<string, any>[]): void
    {
        options ? (this.internalOptions = options) : (this.internalOptions = []);
    }

    @Watch('modelValue')
    public modelChanged(newValue: any): void
    {
        if (this.multiselect && !newValue) this.currentValue = [];
        else if (!newValue) this.currentValue = null;
        else this.currentValue = newValue;

        const value = this.multiselect ? newValue?.[0]?.key : newValue?.key;

        this.mode = value === 'CurrentUser' ? 'currentUser' : 'selectedUser';
    }
}
</script>

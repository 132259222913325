<script lang="ts" setup>
import { ref } from 'vue';
import { useEvents } from '@/plugins/events';
import { KeyValuePair, Resource } from '@/helpers/Interfaces';
import { FormBuilderContract } from '@/components/builder/form';
import { RootEntry } from '@/components/builder/form/entries/RootEntry';
import { TableEntry, Definition as TableDefinition, TableType } from '@/components/forms/blueprints/logito-table';
import TableTemplatesService from '@/modules/low-code/table-templates/services/TableTemplatesService';

const props = defineProps({
  "form": null,
  "entry": null,
  "blueprintName": null
});

const { $events } = useEvents();

const modal = ref(null);
const items = ref<Resource<KeyValuePair>[]>([]);

const onRowClick = async (item: Resource<KeyValuePair>) =>
{
    const publicId = item.result.key;
    const response = await TableTemplatesService.get(publicId);

    const blueprints = props.form.schema.components(TableDefinition.type);
    const blueprint = blueprints.find((b) => b.name === props.blueprintName) as TableType;

    if (blueprint == null) return;

    // TODO: Do not mutate props
    props.entry[blueprint.name] = blueprint.createEntry(response.template) as TableEntry; // eslint-disable-line

    $events.$emit('form-reload');
    modal.value.hide();
};

const showModal = async (templates: Resource<KeyValuePair>[]) =>
{
    items.value = templates;
    modal.value.show();
};

defineExpose({
    showModal,
});
</script>

<template>
    <ideo-modal ref="modal" :title="$t('[[[Szablony tabeli]]]')" size="lg" centered>
        <template #default>
            <list-view :items="items" :preload="false">
                <template #row="{ item }">
                    <list-view-header :title="$t('[[[Nazwa]]]')">
                        {{ item.result.value }}
                    </list-view-header>
                    <list-view-item width="100">
                        <ideo-button variant="info" @click="onRowClick(item)">{{ $t('[[[Wybierz]]]') }}</ideo-button>
                    </list-view-item>
                </template>
            </list-view>
        </template>
        <template #modal-footer="{ cancel }">
            <ideo-button variant="secondary" @click="cancel()">
                {{ $t('[[[Anuluj]]]') }}
            </ideo-button>
        </template>
    </ideo-modal>
</template>

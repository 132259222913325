<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Ref } from '@/helpers/Decorators';
import { KeyValuePair } from '@/helpers/Interfaces';
import { Form } from '@/helpers/Form';
import { $t } from '@/plugins/localization';
import { nextTick } from 'vue';
import BpmnService, { BpmnEmitOptions, BpmnOwnerFormModel } from '@/modules/low-code/process/services/BpmnService';
import { FrontActionModal } from '@/components/common/dynamic-grid/helpers/GridEnums';
import IdeoModal from '@/components/ideo/modal/IdeoModal.vue';
import AddAttachments from '@/views/partials/components/attachments/AddAttachments.vue';

@Options({
    components: { AddAttachments },
})
export default class BPMNOwnerModal extends Vue
{
    @Ref('modal') public modal: () => IdeoModal;

    public department: KeyValuePair = null;
    public departmentOptions: KeyValuePair[] = [];
    public usersOptions: KeyValuePair = null;

    public form = Form.create<BpmnOwnerFormModel>({
        comment: '',
        attachments: [],
        userId: null,
    });

    public action = Form.create<BpmnEmitOptions>({
        id: '',
        licence: '',
        actionName: '',
        buttonId: '',
        displayMode: 'Modal',
        displaySize: 'Medium',
        title: $t('[[[Przekaż do właściciela]]]'),
        buttonName: $t('[[[Akceptuj]]]'),
        buttonVariant: 'success',
        buttonIcon: '',
        groupAction: false,
    });

    public get endpointUsers()
    {
        const guids = this.action.id.split(',')[0];

        return `simple-dictionary/${this.action.licence}/form/${guids}/${this.action.buttonId}/${this.action.actionName}/userId`;
    }

    public async mounted(): Promise<void>
    {
        this.$events.$on(FrontActionModal.ToOwnerModel, (action: BpmnEmitOptions) =>
        {
            this.action.withData(action);
            this.loadDepartments();
            this.modal().show();
        });
    }

    public unmounted(): void
    {
        this.$events.$off(FrontActionModal.ToOwnerModel);
    }

    public async loadDepartments(): Promise<void>
    {
        try
        {
            const result = await BpmnService.getDepartments();

            this.departmentOptions = result.items?.map?.((item) => item.result);
            this.setDefaultDepartment();
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
            });
        }
    }

    public async submit(): Promise<void>
    {
        try
        {
            this.form.wait();

            // Group action
            if (this.action.groupAction)
            {
                const endpoint = `${this.action.licence}/${this.action.buttonId}/bpmn-execute/${this.action.id}`;
                const response: any = await BpmnService.execute(endpoint, this.form.formatData());

                if (response?.length)
                {
                    this.$alert.warning(`${this.$t('[[[Dla dokumentów o sygnaturach]]]')}: ${response.join(', ')} ${this.$t('[[[nie udało się wykonać akcji]]]')}`);
                }
                else
                {
                    this.$alert.success(this.$t('[[[Akcja wykonana pomyślnie]]]'));
                }
            }
            else
            {
                const endpoint = `${this.action.licence}/${this.action.id}/${this.action.buttonId}/bpmn-execute`;

                await BpmnService.execute(endpoint, this.form.formatData());
                this.$alert.success(this.$t('[[[Akcja wykonana pomyślnie]]]'));
            }

            this.modal().hide();

            setTimeout(() =>
            {
                this.$emit('reload');
                this.$events.$emit('refetchActions');
                this.form.reset();
            }, 500);
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
                422: (ex: any) => [
                    this.form.$errors.record(ex.data.errors),
                    this.$alert.warning(this.$t('[[[Nie wszystkie pola zostały wypełnione prawidłowo.]]]')),
                ],
            });
        }
        finally
        {
            this.form.continue();
        }
    }

    public setDefaultDepartment(): void
    {
        this.department = this.departmentOptions?.find((item) => item.key === 'Subordinate');
    }

    public async updateDepartment(): Promise<void>
    {
        this.form.$errors.clear('jobPosition');
        this.form.userId = null;

        await nextTick();

        if (this.department == null)
        {
            this.setDefaultDepartment();
        }
    }

    public restartDefault(): void
    {
        this.department = null;
        this.updateDepartment();
    }
}
</script>

<template>
    <IdeoModal ref="modal" :title="action.title" centered @close="form.reset()" @hidden="form.reset()">
        <template #default>
            <div class="row">
                <IdeoFormGroup
                    :label="$t('[[[Użytkownik]]]')"
                    :invalid-feedback="form.$errors.first('userId')"
                    :state="form.$errors.state('userId')"
                    class="user"
                    required
                >
                    <div class="row">
                        <IdeoSelect
                            v-model="form.userId"
                            :placeholder="$t('[[[Użytkownik]]]')"
                            :endpoint="endpointUsers"
                            :filters="{ userFeatures: department?.key }"
                            :multiselect="true"
                            :clear-button="false"
                            class="col-sm-6 pe-sm-0"
                            custom-classes="border-radius-right-sm-none"
                            @update:modelValue="form.$errors.clear('userId')"
                        >
                            <template #option="{ option }">
                                <UserAvatar
                                    :src="$filters.baseurl(`storage/files/${option.pictureUrl}`)"
                                    :username="option.value"
                                    rounded="circle"
                                />
                                <span class="ms-2 me-1">{{ option.value }}</span>
                                <span v-if="option.unit">({{ option.unit }})</span>
                            </template>
                        </IdeoSelect>
                        <div class="d-flex col-sm-6 ps-sm-0 mt-1 mt-sm-0">
                            <IdeoSelect
                                v-model="department"
                                :placeholder="$t('[[[Dział]]]')"
                                :deselect="false"
                                :options="departmentOptions"
                                :clear-button="false"
                                class="flex-fill"
                                custom-classes="border-radius-right-none border-radius-left-sm-none"
                                @update:modelValue="updateDepartment"
                            />
                            <div class="input-group-append">
                                <button
                                    type="button"
                                    class="btn btn-light border-radius-left-none"
                                    @click.stop="restartDefault"
                                >
                                    <i class="fas fa-fw fa-xmark"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </IdeoFormGroup>
            </div>
            <IdeoFormGroup :label="$t('[[[Dodaj załączniki]]]')">
                <AddAttachments v-model="form.attachments" :container="'documents'" />
            </IdeoFormGroup>
            <IdeoFormGroup
                :label="$t('[[[Uwagi]]]')"
                :invalid-feedback="form.$errors.first('comment')"
                :state="form.$errors.state('comment')"
            >
                <IdeoFormTextarea v-model="form.comment" rows="6" @input="form.$errors.clear('comment')" />
            </IdeoFormGroup>
        </template>
        <template #modal-footer="{ cancel }">
            <IdeoButton :variant="action.buttonVariant" :disabled="!form.active()" @click="submit">
                <i v-if="action.buttonIcon" :class="action.buttonIcon" style="margin-right: 10px"></i>
                <span> {{ action.buttonName }} </span>
            </IdeoButton>
            <IdeoButton variant="secondary" @click="cancel()">
                {{ $t('[[[Anuluj]]]') }}
            </IdeoButton>
        </template>
    </IdeoModal>
</template>

<style lang="scss" scoped>
.user {
    .user-input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}
</style>

<script lang="ts" setup>
import { computed, inject, watch } from 'vue';
import lodashLast from 'lodash/last';
import properties from '@/components/forms/properties';
import { RootEntry } from '@/components/builder/form/entries/RootEntry';
import { RowContract } from '../row';
import { SectionType, SectionColors } from '.';
import { useLocalization } from '@/plugins/localization';
import { Option } from '@/helpers/Interfaces';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';

const { $t } = useLocalization();

defineOptions({
    name: 'section-blueprint',
    components: {
        ...properties
    }
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null
});
const blueprint = computed(() => props.blueprint);

if (props.blueprint.components.length == 0)
{
    props.form.layout.addRow(props.blueprint);
}

const canEdit = inject('canEdit', false);
const design = computed(() => props.form.designMode());
const title = computed(() => props.form.localization.translate(blueprint.value.title) || $t('[[[Sekcja]]]'));
const description = computed(() => props.form.localization.translate(blueprint.value.description));

const readonly = computed(() => props.form.expressions.readonly(props.blueprint));

const rows = computed<RowContract[]>(() => props.blueprint.components as RowContract[]);
const last = computed<RowContract>(() => lodashLast(rows.value));

const colorOptions: Option<SectionColors>[] = [
    {value: SectionColors.None, text: $t('[[[Brak]]]')},
    {value: SectionColors.Primary, text: $t('[[[Podstawowy]]]')},
    {value: SectionColors.Secondary, text: $t('[[[Drugorzędny]]]')},
    {value: SectionColors.Success, text: $t('[[[Sukces]]]')},
    {value: SectionColors.Info, text: $t('[[[Informacja]]]')},
    {value: SectionColors.Warning, text: $t('[[[Ostrzeżenie]]]')},
    {value: SectionColors.Danger, text: $t('[[[Zagrożenie]]]')},
    {value: SectionColors.Light, text: $t('[[[Jasny]]]')},
    {value: SectionColors.Dark, text: $t('[[[Ciemny]]]')},
];

const addRow = (before: RowContract): void =>
{
    props.form.layout.addRow(props.blueprint, before);
};

const addComponent = (definition: BlueprintDefinition, before: RowContract): void =>
{
    const row = props.form.layout.addRow(props.blueprint, before);

    props.form.layout.addComponent(row, definition);
};

const dropComponent = (component: Blueprint, before: RowContract): void =>
{
    const row = props.form.layout.addRow(props.blueprint, before);

    props.form.clipboard.cut(component);
    props.form.clipboard.paste(row);
};

const lastComponentsLength = computed(() => last.value.components.length);
const blueprintWidth = computed(() => props.blueprint.width);

watch(lastComponentsLength, (length: number): void =>
{
    if (length && length > 0)
    {
        props.form.layout.addRow(props.blueprint);
    }
});

watch(blueprintWidth, (width: number): void =>
{
    props.blueprint.components.forEach((row: any) => props.form.dimensions.autoarrange(row, false));
});
</script>

<template>
    <form-component-wrapper class="section-component" :form="form" :parent="parent" :blueprint="blueprint" :disabled="false">
        <template #default>
            <fieldset :disabled="readonly" :class="{'design': design, [`alert alert-${blueprint.color}`]: blueprint.color != 'none'}">
                <legend v-if="(blueprint.title && blueprint.showTitle) || design" :class="{'text-secondary': !blueprint.showTitle}">
                    {{ title }}
                </legend>
                <p class="description" v-if="description">{{ description }}</p>
                <div class="rows">
                    <template v-for="(component, index) in rows" :key="component.id">
                        <design-ruller :title="$t('[[[Wstaw kontrolkę]]]')" :form="form" :before="component" @insert="addRow" @add="addComponent" @drop="dropComponent" v-if="component.components.length > 0 && canEdit" />
                        <component :is="form.schema.designer(component.type)" :form="form" :parent="blueprint" :blueprint="rows[index]" :entry="entry"></component>
                    </template>
                </div>
            </fieldset>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.title[locale]" :label="$t('[[[Tytuł sekcji]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showTitle" :label="$t('[[[Pokaż tytuł sekcji]]]')" />
            <ideo-form-localize v-slot="{ locale }">
                <field-textarea v-model="blueprint.description[locale]" :label="$t('[[[Opis]]]')" />
            </ideo-form-localize>
            <field-select v-model="blueprint.color" :options="colorOptions" :label="$t('[[[Akcent]]]')" />
            <field-visible :form="form" :blueprint="blueprint" :label="$t('[[[Widoczność]]]')" />
            <field-readonly :form="form" :blueprint="blueprint" :label="$t('[[[Tylko do odczytu]]]')" />
            <field-error :form="form" :blueprint="blueprint" :label="$t('[[[Niestandardowy błąd]]]')" />
        </template>
    </form-component-wrapper>
</template>

<style lang="scss" scoped>
.section-component {
    padding: 0px;

    fieldset {
        display: block;
        padding: 1rem 0.75rem 0 0.75rem;
        margin: 0 0.75rem 1rem 0.75rem;

        &:not(.alert) {
            border: 1px solid var(--bs-border-color);
        }

        > legend {
            display: block;
            font-size: 1rem;
            font-weight: 400;
            margin: 0 0 1rem 0;
        }

        &.design {
            padding: 0;
            margin: 0;

            > legend {
                padding: 0 0.75rem;
                margin: 0.75rem 0 0.25rem 0;
            }

            > p {
                padding: 0.75rem;
                margin: 0;
            }
        }
    }
}
.section-component:deep(> .wrapper) {
    padding-top: 0px !important;
}
</style>

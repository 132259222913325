import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { BlueprintDefinition } from '@/components/builder/details/blueprints/BlueprintDefinition';

export const Definition: BlueprintDefinition = {
    type: 'spacer',
    name: '[[[Odstęp]]]',
    icon: 'fa-arrows-alt-h',
    group: 'system-elements',
    position: 1,
};

export interface SpacerContract extends Blueprint, HasWidth {}

export class SpacerType implements SpacerContract
{
    public id: string;
    public type: string;
    public name: string;
    public width: number;
    public minWidth: number;

    public constructor(id: string, name: string)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.width = 0;
        this.minWidth = 1;
    }

    public setDefaultWidth(width: number): void
    {
        this.width = Math.max(this.minWidth, width);
    }
}

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop, Ref } from "@/helpers/Decorators";
import { Form } from "@/helpers/Form";
import ShareOneDriveService, { FormModel } from "@/modules/low-code/services/ShareOneDriveService";
import IdeoModal from "@/components/ideo/modal/IdeoModal.vue";
import { KeyValue } from "@/helpers/Interfaces";

@Options({
    name: "ShareDocumentOneDriveModal",
})
export default class ShareDocumentOneDriveModal extends Vue
{
    @Prop()
    public licence: string;

    @Prop()
    public publicId: string;

    @Ref("share-document-one-drive-modal")
    public modal: () => IdeoModal;

    public filePublicId: KeyValue<string, string> = null;

    public form = Form.create<FormModel>({
        users: null,
        comment: "",
        documentField: null,
    });

    public files: KeyValue<string, string>[] = [];

    public mounted(): void
    {
        this.$events.$on("share-document-one-drive-modal", (files: KeyValue<string, string>[], documentField: string) =>
        {
            this.files = files;
            this.filePublicId = files[0];
            this.form.documentField = documentField;
            this.modal().show();
        });
    }

    public unmounted(): void
    {
        this.$events.$off("share-document-one-drive-modal");
    }

    public async submit(): Promise<void>
    {
        try
        {
            await ShareOneDriveService.shareDocument(this.filePublicId.key, this.form.formatData());

            this.$alert.success(this.$t("[[[Udostępniono dokument w OneDrive]]]"));
            this.$emit("reload");

            this.modal().hide();
            this.form.clear();
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
                422: (ex: any) => this.form.$errors.record(ex.data.errors),
            });
        }
    }
}
</script>

<template>
    <IdeoModal ref="share-document-one-drive-modal" :title="$t('[[[Udostępnij]]]')" centered @close="form.clear()">
        <template #default>
            <form id="share-one-drive-modal" @submit.prevent="submit">
                <IdeoFormGroup
                    :label="$t('[[[Wybierz plik do udostępnienia]]]')"
                    :invalid-feedback="form.$errors.first('filePublicId')"
                    :state="form.$errors.state('filePublicId')"
                >
                    <IdeoSelect
                        v-model="filePublicId"
                        :options="files"
                        :clear-button="false"
                    />
                </IdeoFormGroup>
                <IdeoFormGroup
                    :label="$t('[[[Udostępnij dokument w OneDrive do]]]')"
                    :invalid-feedback="form.$errors.first('users')"
                    :state="form.$errors.state('users')"
                >
                    <IdeoSelect
                        v-model="form.users"
                        :placeholder="$t('[[[Użytkownik]]]')"
                        :multiselect="true"
                        :endpoint="`simple-dictionary/files/form/${filePublicId.key}/one-drive/users`"
                        @update:model-value="form.$errors.clear('users')"
                    >
                        <template #option="{ option }">
                            <UserAvatar :src="$filters.baseurl(`storage/files/${option.pictureUrl}`)" :username="option.value" rounded="circle" />
                            <span class="ms-2 me-1">{{ option.value }}</span>
                            <span v-if="option.unit">({{ option.unit }})</span>
                        </template>
                    </IdeoSelect>
                </IdeoFormGroup>
                <IdeoFormGroup :label="$t('[[[Uwagi]]]')" :invalid-feedback="form.$errors.first('comment')" :state="form.$errors.state('comment')">
                    <IdeoFormTextarea v-model="form.comment" rows="6" @update:model-value="form.$errors.clear('comment')" />
                </IdeoFormGroup>
            </form>
        </template>
        <template #modal-footer="{ cancel }">
            <IdeoButton variant="success" type="submit" form="share-one-drive-modal" :disabled="!form.active()">
                {{ $t("[[[Udostępnij]]]") }}
            </IdeoButton>
            <IdeoButton variant="secondary" @click="cancel()">
                {{ $t("[[[Anuluj]]]") }}
            </IdeoButton>
        </template>
    </IdeoModal>
</template>

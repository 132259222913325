<script lang="ts" setup>
import { ref, computed, inject, Ref, onMounted } from 'vue';
import { useScroll, useElementSize, UseScrollReturn } from '@vueuse/core';
import { useMedia } from '@/plugins/media';
import { DocumentPreviewType } from '.';
import { Resource as FileResource } from "@/modules/core/files/services/StorageService";
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { DetailsBuilderContract } from '@/components/builder/details';
import FileReader from "@/views/partials/components/attachments/FIleReader.vue";
import properties from '../../properties';

defineOptions({
    name: 'DocumentPreviewBlueprint',
    components: {
        ...properties,
    },
});

const props = defineProps({
  "blueprint": null,
  "details": null,
  "parent": null
});

const { desktop, tablet } = useMedia();

const wrapperRef = ref<HTMLElement | null>(null);
const documentPreviewRef = ref<HTMLElement | null>(null);
const documentPreviewContentRef = ref<HTMLElement | null>(null);

const { height: heightContainer } = useElementSize(documentPreviewRef);
const { height: heightContent, } = useElementSize(documentPreviewContentRef);
const scrollData = ref<UseScrollReturn>(null);

const filePreview = inject<Ref<FileResource>>('file-preview', null);

const isScrollAvailable = computed(() => desktop() || tablet());
const offset = computed(() =>
{
    if (isScrollAvailable.value && scrollData.value)
    {
        const height = heightContainer.value - heightContent.value;
        const scrollY = scrollData.value.y;

        return scrollY > height ? height : scrollY;
    }

    return 0;
});
const blueprint = computed(() => props.blueprint);
const design = computed(() => props.details.designMode());

onMounted(() =>
{
    if (!design.value)
    {
        wrapperRef.value = document.querySelector('[data-name="wrapper"]');
        scrollData.value = useScroll(wrapperRef.value, { behavior: 'smooth' }) as any;
    }
});
</script>

<template>
    <dynamic-details-component-wrapper :details="details" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div v-if="!design" ref="documentPreviewRef" class="h-100">
                <div ref="documentPreviewContentRef" class="position-relative" :style="{ 'top': `${offset}px` }">
                    <file-reader v-if="filePreview && filePreview.url" :src="filePreview.url" />
                </div>
            </div>
            <div v-else class="d-flex justify-content-center align-items-center h-100">
                <i class="far fa-ballot-check fa-8x"></i>
            </div>
        </template>
        <template #properties>
            <field-name v-model="blueprint.name" />
            <dynamic-details-accordion>
                <dynamic-details-accordion-item icon="far fa-table" :header="$t('[[[Prezentacja]]]')">
                    <field-visible :details="details" :blueprint="blueprint" />
                </dynamic-details-accordion-item>
            </dynamic-details-accordion>
        </template>
    </dynamic-details-component-wrapper>
</template>

<style lang="scss" scoped>
.fa-ballot-check {
    color: #edeff1;
}
</style>

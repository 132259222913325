import { Option } from '@/helpers/Interfaces';
import { $t } from "@/plugins/localization";

enum BPCommonType {
    Organisation = 0,
    Person = 1
}

enum ContractorRole {
    Contractor = 0,
    Supplier = 1,
    Both = 2
}

const BPCommonTypeOptions: () => Option<number>[] = () => [
    { value: BPCommonType.Organisation, text: $t('[[[Organizacja]]]') },
    { value: BPCommonType.Person, text: $t('[[[Osoba]]]') }
];

const ContractorRoleOptions: () => Option<number>[] = () => [
    { value: ContractorRole.Contractor, text: $t('[[[Odbiorca]]]') },
    { value: ContractorRole.Supplier, text: $t('[[[Dostawca]]]') },
    { value: ContractorRole.Both, text: $t('[[[Kontrahent i dostawca]]]') },
];

export {
    BPCommonType,
    BPCommonTypeOptions,
    ContractorRole,
    ContractorRoleOptions
};

// ----------------------------------------------------------------------------------------------------

enum BusinessPartnerSource{
    Manual = 0,
    External = 1
}

export {
    BusinessPartnerSource
};

// ----------------------------------------------------------------------------------------------------

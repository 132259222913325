<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import { Resource } from '@/modules/core/files/services/StorageService';
import { formatFileSize } from '@/helpers/Utils';

@Options({
    name: 'file-list',
})
export default class FileList extends Vue
{
    // use 'files' when working with array of files
    @Prop({ default: () => [] as Resource[] }) public files: Resource[];
    // use 'file' when working with single file
    @Prop({ default: null }) public file: Resource;
    @Prop({ default: false }) public isOnGrid: boolean;

    public fileSizeBeautifier(sizeInBytes: number): string
    {
        return formatFileSize(sizeInBytes);
    }
}
</script>

<template>
    <div>
        <div class="card-body">
            <!-- Multiple files -->
            <div v-if="files && files.length" class="d-flex align-items-center flex-wrap">
                <a
                    v-for="file in files"
                    :key="file.publicId"
                    class="item-action"
                    v-tooltip="isOnGrid ? fileSizeBeautifier(file.contentLength / 1024) : file.name"
                    :href="$filters.download(file.url)"
                    target="_blank"
                    @click.stop
                >
                    <i class="fas fa-download"></i>
                    <span class="file-name-area ms-2">
                        <span>{{ file.name }}</span>
                        <template v-if="file.contentLength && !isOnGrid">
                            ({{ fileSizeBeautifier(file.contentLength / 1024) }})
                        </template>
                    </span>
                </a>
            </div>

            <!-- Single file -->
            <div v-if="file" class="d-flex align-items-center gap-1 flex-wrap">
                <a
                    v-tooltip="isOnGrid ? fileSizeBeautifier(file.contentLength / 1024) : file.name"
                    class="item-action"
                    :href="$filters.download(file.url)"
                    target="_blank"
                    @click.stop
                >
                    <i class="fas fa-download"></i>
                    <span class="file-name-area ms-2">
                        <span>{{ file.name }}</span>
                        <template v-if="file.contentLength && !isOnGrid">
                            ({{ fileSizeBeautifier(file.contentLength / 1024) }})
                        </template>
                    </span>
                </a>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.list-group {
    display: inline-block;
}

.card-body {
    padding: 0px;
    min-width: 130px;
}

.item-action {
    display: flex;
    width: auto;
    padding: 5px;
    margin: 1px;
    font-size: 11px;
    align-items: center;
    font-weight: bold;
    border: 1px solid var(--bs-border-color);
    border-radius: 4px;

    .file-name-area {
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }
}

.dark-theme {
    .item-action {
        border: 1px solid var(--file-border);
        color: var(--color);
    }
}

.text-danger {
    .item-action {
        color: $danger;
    }
}

.text-success {
    .item-action {
        color: $success;
    }
}
</style>

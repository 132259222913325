<script lang="ts" setup>
import { computed, inject, watch, getCurrentInstance, ref, onMounted, onUnmounted } from 'vue';
import lodashLast  from 'lodash/last';
import { RootEntry } from '@/components/builder/form/entries/RootEntry';
import { PageContract } from '../page';
import { RowType } from '.';
import { FormBuilderContract } from '@/components/builder/form';
import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { Events } from '@/components/builder/base/managers/EventManager';

defineOptions({
    name: 'row-blueprint',
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null
});

const refreshKey = ref(1);
const canEdit = inject('canEdit', false);
const design = computed(() => props.form.designMode());
const last = computed(() => lodashLast(props.parent.components) == props.blueprint);
const selected = computed(() => refreshKey.value > 0 && props.form.clipboard.isRowSelected(props.blueprint) && design.value);

onMounted(() =>
{
    props.form.events.subscribe(Events.FOCUS, onFocus);
});

onUnmounted(() =>
{
    props.form.events.release(Events.FOCUS, onFocus);
});

function onFocus()
{
    if (props.form.clipboard.isRowSelected(props.blueprint))
    {
        refreshKey.value++;
    }
}

const selectPlaceholder = (component: Blueprint = null): void =>
{
    props.form.clipboard.selectPlaceholder(props.blueprint, component);
};

const addComponent = (definition: BlueprintDefinition, before: Blueprint): void =>
{
    props.form.layout.addComponent(props.blueprint, definition, before);
};

const dropComponent = (component: Blueprint, before: Blueprint): void =>
{
    props.form.clipboard.cut(component);
    props.form.clipboard.paste(props.blueprint, before);
};

const instance = getCurrentInstance();

watch(selected, (value: boolean): void =>
{
    if (value)
    {
        props.form.dom.offsetTop(instance);
    }
    else
    {
        if (props.blueprint.components.length == 0 && !selected.value && !last.value)
        {
            props.form.layout.removeComponent(props.blueprint);
        }
    }
});

const components = computed(() => props.blueprint.components);

watch(components, (value: Blueprint[], old: Blueprint[]): void =>
{
    if (value.length == 0 && old.length > 0)
    {
        props.form.layout.removeComponent(props.blueprint);
    }
});
</script>

<template>
    <form-component class="row-component row flex-md-nowrap" :class="{'g-0': design}" :form="form" :blueprint="blueprint">
        <template v-for="(component, index) in blueprint.components" :key="component.id">
            <design-placeholder :title="$t('Wstaw kontrolkę')" :form="form" :parent="blueprint" :before="component" @select="selectPlaceholder(component)" @add="addComponent" @drop="dropComponent" v-if="canEdit" />
            <component :is="form.schema.designer(component.type)" :form="form" :parent="blueprint" :blueprint="blueprint.components[index]" :entry="entry"></component>
        </template>
        <design-placeholder :title="$t('Wstaw kontrolkę')" :form="form" :parent="blueprint" :last="true" @select="selectPlaceholder()" @add="addComponent" @drop="dropComponent" />
    </form-component>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useMixins } from '@/plugins/mixins';
import { isImage } from "@/helpers/Utils";

const props = defineProps({
  "src": null
});

const { $filters } = useMixins();

const fileUrl = computed(() =>
{
    const fileUrl = $filters.baseurl(props.src);
    const canRead = /\.(gif|jpe?g|tiff?|png|webp|bmp|svg|pdf)$/i.test(fileUrl);

    return canRead && fileUrl ? fileUrl : null;
});
const isImg = computed(() => isImage(fileUrl.value));
</script>

<template>
    <embed
        v-if="fileUrl"
        :src="fileUrl"
        :class="isImg ? 'image-viewer' : 'pdf-viewer'"
        width="100%"
    />
</template>

<style scoped>
.pdf-viewer {
    min-height: 800px;
    object-fit: contain;
}
</style>

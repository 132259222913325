<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Ref, Prop } from '@/helpers/Decorators';
import { Column, ColumnDefinitionModel } from '@/modules/core/common/services/GridService';
import { KeyValue, Resource } from '@/helpers/Interfaces';
import { entries, over } from 'lodash';
import { DisplaySize } from '@/modules/low-code/process/services/BpmnService';
import ModulesService from '@/modules/low-code/services/ModulesService';
import { TypeOfData } from '@/components/common/dynamic-grid/helpers/TypeOfData';
import DetailsLabel from '@/components/common/DetailsLabel.vue';
import IdeoModal from '@/components/ideo/modal/IdeoModal.vue';

// Presenters
import DateTimePresenter from '@/components/dynamic-details/blueprints/field/presenters/DateTime.vue';
import UserWithAvatarPresenter from '@/components/dynamic-details/blueprints/field/presenters/UserWithAvatar.vue';
import UserWithAvatarArrayPresenter from '@/components/dynamic-details/blueprints/field/presenters/UserWithAvatarArray.vue';
import TextPresenter from '@/components/dynamic-details/blueprints/field/presenters/Text.vue';
import StatusPresenter from '@/components/dynamic-details/blueprints/field/presenters/StatusWithVariant.vue';
import MoneyPresenter from '@/components/dynamic-details/blueprints/field/presenters/Money.vue';
import BooleanPresenter from '@/components/dynamic-details/blueprints/field/presenters/Boolean.vue';
import GenericKeyValuePairPresenter from '@/components/dynamic-details/blueprints/field/presenters/GenericKeyValuePair.vue';
import GenericKeyValuePairArrayPresenter from '@/components/dynamic-details/blueprints/field/presenters/GenericKeyValuePairArray.vue';
import DecimalPresenter from '@/components/dynamic-details/blueprints/field/presenters/Decimal.vue';
import AddressPresenter from '@/components/dynamic-details/blueprints/field/presenters/Address.vue';
import MainFilePresenter from '@/components/dynamic-details/blueprints/field/presenters/File.vue';
import ListPresenter from '@/components/dynamic-details/blueprints/field/presenters/List.vue';
import SimplePartnerPresenter from '@/components/dynamic-details/blueprints/field/presenters/SimplePartner.vue';
import SimplePartnerArrayPresenter from '@/components/dynamic-details/blueprints/field/presenters/SimplePartnerArray.vue';
import DurationPresenter from '@/components/dynamic-details/blueprints/field/presenters/Duration.vue';
import ResourcePresenter from '@/components/dynamic-details/blueprints/field/presenters/Resource.vue';
import TablePresenter from '@/components/dynamic-details/blueprints/field/presenters/Table.vue';
import TableVatPresenter from '@/components/dynamic-details/blueprints/field/presenters/TableVat.vue';
import UserGroupArrayPresenter from '@/components/dynamic-details/blueprints/field/presenters/UserGroupArray.vue';
import ExchangeRatePresenter from '@/components/dynamic-details/blueprints/field/presenters/ExchangeRate.vue';
import DynamicDocumentPresenter from '@/components/dynamic-details/blueprints/field/presenters/DynamicDocument.vue';
import IsPrivatePresenter from '@/components/dynamic-details/blueprints/field/presenters/IsPrivate.vue';
import RichTextPresenter from '@/components/dynamic-details/blueprints/field/presenters/RichText.vue';

@Options({
    name: 'ArchiveDataModal',
    components: {
        IdeoModal,
        DetailsLabel,
        DateTimePresenter,
        UserWithAvatarPresenter,
        UserWithAvatarArrayPresenter,
        TextPresenter,
        StatusPresenter,
        MoneyPresenter,
        BooleanPresenter,
        GenericKeyValuePairPresenter,
        DecimalPresenter,
        AddressPresenter,
        MainFilePresenter,
        ListPresenter,
        SimplePartnerPresenter,
        SimplePartnerArrayPresenter,
        DurationPresenter,
        ResourcePresenter,
        TablePresenter,
        TableVatPresenter,
        GenericKeyValuePairArrayPresenter,
        UserGroupArrayPresenter,
        ExchangeRatePresenter,
        DynamicDocumentPresenter,
        IsPrivatePresenter,
        RichTextPresenter,
    },
})
export default class ArchiveDataModal extends Vue
{
    @Prop() public licence: string;
    @Prop() public documentId: string;

    @Ref('archive-data-modal')
    public modal: () => IdeoModal;

    private schema: ColumnDefinitionModel = null;
    public detailsData: Resource<Record<string, any>> = null;
    public detailsArray: [string, Column][];
    public displaySize: DisplaySize = 'Medium';

    public get title(): string
    {
        return this.$t('[[[Szczegóły formularza]]]');
    }

    public get hasData(): boolean
    {
        return this.detailsData && Object.keys(this.detailsData.result).length > 0;
    }

    public get size(): string
    {
        return this.displaySize === 'Large' ? 'xl' : this.displaySize === 'Medium' ? 'lg' : 'md';
    }

    public async mounted(): Promise<void>
    {
        this.$events.$on(
            'archive-data-modal',
            async (historyId: number, modalDisplaySize: KeyValue<DisplaySize, string>) =>
            {
                this.displaySize = modalDisplaySize?.key ?? 'Medium';
                await this.getArchiveData(historyId);
                this.modal()?.show();
            }
        );
    }

    public unmounted(): void
    {
        this.$events.$off('archive-data-modal');
    }

    public async getArchiveData(historyId: number): Promise<void>
    {
        try
        {
            const result = await ModulesService.fetchArchiveData(this.licence, this.documentId, historyId);

            this.schema = result.schema;
            this.detailsData = result.data;

            const f = entries(this.schema);

            this.detailsArray = f
                .filter(
                    (column) =>
                        column[1].visibility && column[0] !== 'id' && column[0] !== 'publicId' && column[0] !== 'userId'
                )
                .sort((a, b) =>
                {
                    if (b[1].order < a[1].order) return 1;

                    return -1;
                });
        }
        catch (error)
        {
            this.$alert.danger(this.$t('[[[Błąd podczas pobierania danych]]]'));

            throw error;
        }
    }

    public TypeOfData(item: Resource<any>, header: [string, Column]): Record<string, any>
    {
        return TypeOfData(item, header, { schema: this.schema });
    }
}
</script>

<template>
    <IdeoModal ref="archive-data-modal" :title="title" :size="size" centered :hide-footer="true">
        <div v-if="hasData" class="col-md-12">
            <DetailsLabel
                v-for="detail in detailsArray"
                :key="detail[1].headerName"
                :label="detail[1].headerName"
                class="mb-2"
            >
                <component :is="TypeOfData(detailsData, detail).is" v-bind="{ ...TypeOfData(detailsData, detail) }" />
            </DetailsLabel>
        </div>
    </IdeoModal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import { TabContract } from '../tab';
import { TabsContract } from '.';
import properties from '../../properties';
import { DetailsBuilderContract } from '@/components/builder/details';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { Definition as TabDefinition } from '@/components/dynamic-details/blueprints/tab';
import { TabOption } from '@/modules/low-code/inc/Options';

@Options({
    name: 'TabsBlueprint',
    components: {
        ...properties,
    },
})
export default class TabsBlueprint extends Vue
{
    @Prop({ default: null }) public details!: DetailsBuilderContract;
    @Prop({ default: null }) public parent!: AggregateBlueprint;
    @Prop({ default: null }) public blueprint!: TabsContract;

    public actualTab: string = null;

    public get tabs(): TabOption[]
    {
        return this.blueprint.components.map((item) =>
        {
            return {
                icon: `fas ${item.icon}`,
                text: this.details.localization.translate(item.text),
                value: item.id,
                options: {
                    isCounter: item.isCounter,
                    counterBindWith: item.counterBindWith?.value,
                },
            };
        });
    }

    public get design(): boolean
    {
        return this.details.designMode();
    }

    public created(): void
    {
        if (this.blueprint.components.length == 0)
        {
            this.addTab();
        }

        if (!this.blueprint.defaultTab)
        {
            const component = this.blueprint.components[0] as TabContract;

            this.setDefaultTab(component.id);
        }
        else
        {
            this.setTab(this.blueprint.defaultTab, true);
        }
    }

    public updated(): void
    {
        if (!this.blueprint.defaultTab)
        {
            const component = this.blueprint.components[0] as TabContract;

            this.setDefaultTab(component.id);
        }
        else
        {
            this.setTab(this.blueprint.defaultTab, true);
        }
    }

    public addTab(item?: TabContract): void
    {
        this.details.layout.addComponentAfter(this.blueprint, TabDefinition, item);
    }

    public setTab(tab: string, isDefaultTab: boolean = false): void
    {
        this.setComponentsAdditionalData(tab, isDefaultTab);
        this.actualTab = tab;
    }

    public setComponentsAdditionalData(tab: string, isDefaultTab: boolean):void
    {
        this.blueprint.components.forEach((component: any) =>
        {
            const isActive = component.id == tab;

            this.setComponentAdditionalData(component, isActive, component.isCounter, isDefaultTab);
        });
    }

    public setComponentAdditionalData(currentComponent: any, isActive: boolean, isCounter: boolean, isDefaultTab: boolean):void
    {
        if (!currentComponent.hasOwnProperty('isCounter'))
            currentComponent.isCounter = isCounter;

        if (!currentComponent.hasOwnProperty('isDefaultTab'))
            currentComponent.isDefaultTab = isDefaultTab && isActive;

        if (currentComponent.hasOwnProperty('components'))
            currentComponent.components.forEach((component: any) => this.setComponentAdditionalData(component, isActive, isCounter, isDefaultTab));

        currentComponent.isActive = isActive;
    }

    public updateTab(text: string, prop: string, index: number, lang: string): void
    {
        if (lang)
            (this.blueprint.components as any[])[index][prop][lang] = text;
        else
            (this.blueprint.components as any[])[index][prop] = text;
    }

    public deleteTab(item: TabContract): void
    {
        this.details.layout.removeComponent(item);
    }

    public setDefaultTab(defaultTab: string): void
    {
        this.blueprint.defaultTab = defaultTab;
        this.setTab(defaultTab, true);
    }

    public updateDefaultTab(tab: string): void
    {
        this.setDefaultTab(tab);
    }
}
</script>

<template>
    <dynamic-details-component-wrapper :details="details" :parent="parent" :blueprint="blueprint" :disabled="false">
        <template #default>
            <ideo-nav-tabs
                v-slot="{ item }"
                v-model="actualTab"
                :tabs="tabs"
                @change-tab="(tab: TabOption) => setTab(tab.value)"
            >
                <i :class="item.icon"></i>
                {{ item.text }}
                <span v-if="item.options?.isCounter" class="ms-1">
                    (<portal-target :name="`tab-counter-${item.options?.counterBindWith}`">0</portal-target>)
                </span>
            </ideo-nav-tabs>

            <template v-for="(component, index) in blueprint.components" :key="component.id">
                <div v-show="component.id === actualTab" :class="design ? 'mt-2' : 'mt-1'">
                    <component
                        :is="details.schema.designer(`dynamic-details-${component.type}`)"
                        :details="details"
                        :parent="blueprint"
                        :blueprint="blueprint.components[index]"
                    ></component>
                </div>
            </template>
        </template>
        <template #properties>
            <field-name v-model="blueprint.name" />
            <dynamic-details-accordion>
                <dynamic-details-accordion-item icon="far fa-table" :header="$t('[[[Prezentacja]]]')">
                    <field-tabs
                        :blueprint="blueprint"
                        :details="details"
                        :label="$t('[[[Zakładki]]]')"
                        @add-tab="addTab"
                        @update-tab="updateTab"
                        @delete-tab="deleteTab"
                        @update-default-tab="updateDefaultTab"
                    />
                    <field-visible :details="details" :blueprint="blueprint" />
                </dynamic-details-accordion-item>
            </dynamic-details-accordion>
        </template>
    </dynamic-details-component-wrapper>
</template>

import { Plugin, getCurrentInstance } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import { DateTime } from 'luxon';
import { scrollRedirect } from '@/components/common/dynamic-grid/helpers/scrollRedirect';
import { trimEnd } from 'lodash';
import { formatDateTime, baseurl, handleException, isImage } from '@/helpers/Utils';
import { I18n } from '@/plugins/localization';

export const useMixins = (): {$handleException: any, $redirect: any, $back: any, $filters: any, $abort: any} =>
{
    const app = getCurrentInstance();

    return {
        $handleException: app.appContext.config.globalProperties.handleException,
        $redirect: app.appContext.config.globalProperties.redirect,
        $back: app.appContext.config.globalProperties.back,
        $filters: app.appContext.config.globalProperties.$filters,
        $abort: app.appContext.config.globalProperties.abort
    };
};

interface Filters
{
    datetime(value: DateTime|Date|string, format?: string, zone?: string): string;
    date(value: DateTime|Date|string, format?: string, zone?: string): string;
    time(value: DateTime|Date|string, format?: string, zone?: string): string;
    baseurl(value: string): string;
    image(value: string, presetName: string): string;
    filesize(size: number): string;
    number(value: any, precision?: number): string;
    currencyFormat(value: any, precision?: number): string;
    download(value: string): string;
    dashIfEmpty(value: string): string;
    default(value: string, defaultValue: string): string;
    polishPlural(value: number, singularNominativ: string, pluralNominativ: string, pluralGenitive: string): string
    isImage(fileUrl: string): boolean
}

class FiltersHelper implements Filters
{
    private i18n: I18n;

    public constructor(i18n: I18n)
    {
        this.i18n = i18n;
    }

    public datetime(value: DateTime|Date|string, format: string = 'yyyy-MM-dd HH:mm:ss', zone: string = null): string
    {
        return formatDateTime(value, format, zone);
    }

    public date(value: DateTime|Date|string, format: string = 'yyyy-MM-dd', zone: string = null): string
    {
        return formatDateTime(value, format, zone);
    }

    public time(value: DateTime|Date|string, format: string = 'HH:mm:ss', zone: string = null): string
    {
        return formatDateTime(value, format, zone);
    }

    public baseurl(value: string): string
    {
        return baseurl(value);
    }

    public download(value: string): string
    {
        return `${trimEnd(import.meta.env.VITE_APP_PUBLIC_URL, '/')}/${value}`;
    }

    public polishPlural(value: number, singularNominativ: string, pluralNominativ: string, pluralGenitive: string): string
    {
        if (value === 1)
        {
            return `${value} ${singularNominativ}`;
        }
        else if (value % 10 >= 2 && value % 10 <= 4 && (value % 100 < 10 || value % 100 >= 20))
        {
            return `${value} ${pluralNominativ}`;
        }
        else
        {
            return `${value} ${pluralGenitive}`;
        }
    }

    public dashIfEmpty(value: string): string
    {
        if (!value)
            return '-';

        return value;
    }

    public currencyFormat(value: any, precision: number = 2): string
    {
        if (value === null || isNaN(value))
            value = 0;

        return parseFloat(value).toFixed(precision).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ',');
    }

    public number(value: any, precision: number = 2): string
    {
        if (value === null || isNaN(value))
            value = 0;

        const locale = this.i18n.locale();

        return Intl.NumberFormat(locale, { useGrouping: false, minimumFractionDigits: precision }).format(value);
    }

    public image(value: string, presetName: string): string
    {
        return baseurl(`${value}?p=${presetName}`);
    }

    public filesize(size: number): string
    {
        const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        let i = 0;

        for (i = 0; size > 1024; i++)
        {
            size = size / 1024;
        }

        return `${size.toFixed(2)} ${units[i]}`;
    }

    public default(value: string, defaultValue: string): string
    {
        return value || defaultValue;
    }

    public isImage(fileUrl: string): boolean
    {
        return isImage(fileUrl);
    }
}

const MixinsPlugin: Plugin =
{
    install(app, options)
    {
        const vue = app.config.globalProperties;

        // Helpers
        vue.abort = (code: number, message: string): void =>
        {
            throw (() =>
            {
                return {
                    code: code,
                    message: message,
                    data: null as any,
                    inner: null as any
                };
            })();
        };

        vue.redirect = (location: RouteLocationRaw, onComplete?: () => void): void =>
        {
            vue.$router.push(location).catch(() => {}).finally(() => onComplete ? onComplete() : null);
        };

        vue.back = (): void =>
        {
            const { back, current } = vue.$router.options.history.state ?? {};

            if (back === current)
                vue.$router.push({ name: 'dashboard' });
            else
                vue.$router.back();
        };

        vue.silentRedirect = (location: RouteLocationRaw): void =>
        {
            const result = vue.$router.resolve(location);

            history.replaceState(history.state, '', result.href);
        };

        vue.handleException = (ex: any, rules: Record<number, (ex: any) => void>): void => handleException(vue.$log, ex, rules);

        // Filters
        vue.$filters = new FiltersHelper(vue.$i18n);

        vue.scrollRedirect = scrollRedirect;
    }
};

export default MixinsPlugin;

declare module "@vue/runtime-core"
{
    interface ComponentCustomProperties
    {
        // Computed properties
        mq: string;
        phone: boolean;
        tablet: boolean;
        mobile: boolean;
        laptop: boolean;
        desktop: boolean;

        // Helpers
        abort(code: number, message: string): void;
        redirect(location: RouteLocationRaw, onComplete?: () => void): void;
        back(): void;
        silentRedirect(location: RouteLocationRaw): void;
        handleException(ex: any, rules: Record<number, (ex: any) => void>): void;
        scrollRedirect(to: RouteLocationRaw, newTab?: boolean): void;
        // Filters
        $filters: Filters;
    }
}

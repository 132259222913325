<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { computedAsync } from '@vueuse/core';
import Pager from '@/helpers/Pager';
import {
    Definition as RelatedDocumentDefinition,
    RelatedDocumentEntry,
    RelatedDocumentType,
} from '../logito-related-documents';
import properties from '../../properties';
import { FormEntry } from '../form';
import { useLocalization } from '@/plugins/localization';
import { DocumentDetailsMode, LogitoDocumentDetailsType } from '.';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import AutocompleteService from '@/modules/core/common/services/AutocompleteService';
import { getAttributes } from '@/components/common/dynamic-grid/helpers/TypeOfData';
import BpmnService from '@/modules/low-code/process/services/BpmnService';
import { Column } from '@/modules/core/common/services/GridService';
import FormsService from '@/modules/studio/forms/services/FormsService';
import ColumnsCustomizer from '@/components/common/dynamic-grid/ColumnsCustomizer.vue';
import DetailsLabel from '@/components/common/DetailsLabel.vue';

// Presenters
import DateTimePresenter from '@/components/dynamic-details/blueprints/field/presenters/DateTime.vue';
import UserWithAvatarPresenter from '@/components/dynamic-details/blueprints/field/presenters/UserWithAvatar.vue';
import UserWithAvatarArrayPresenter from '@/components/dynamic-details/blueprints/field/presenters/UserWithAvatarArray.vue';
import TextPresenter from '@/components/dynamic-details/blueprints/field/presenters/Text.vue';
import StatusPresenter from '@/components/dynamic-details/blueprints/field/presenters/StatusWithVariant.vue';
import MoneyPresenter from '@/components/dynamic-details/blueprints/field/presenters/Money.vue';
import BooleanPresenter from '@/components/dynamic-details/blueprints/field/presenters/Boolean.vue';
import GenericKeyValuePairPresenter from '@/components/dynamic-details/blueprints/field/presenters/GenericKeyValuePair.vue';
import GenericKeyValuePairArrayPresenter from '@/components/dynamic-details/blueprints/field/presenters/GenericKeyValuePairArray.vue';
import DecimalPresenter from '@/components/dynamic-details/blueprints/field/presenters/Decimal.vue';
import AddressPresenter from '@/components/dynamic-details/blueprints/field/presenters/Address.vue';
import MainFilePresenter from '@/components/dynamic-details/blueprints/field/presenters/File.vue';
import ListPresenter from '@/components/dynamic-details/blueprints/field/presenters/List.vue';
import SimplePartnerPresenter from '@/components/dynamic-details/blueprints/field/presenters/SimplePartner.vue';
import SimplePartnerArrayPresenter from '@/components/dynamic-details/blueprints/field/presenters/SimplePartnerArray.vue';
import DurationPresenter from '@/components/dynamic-details/blueprints/field/presenters/Duration.vue';
import ResourcePresenter from '@/components/dynamic-details/blueprints/field/presenters/Resource.vue';
import TablePresenter from '@/components/dynamic-details/blueprints/field/presenters/Table.vue';
import TableVatPresenter from '@/components/dynamic-details/blueprints/field/presenters/TableVat.vue';
import UserGroupArrayPresenter from '@/components/dynamic-details/blueprints/field/presenters/UserGroupArray.vue';
import ExchangeRatePresenter from '@/components/dynamic-details/blueprints/field/presenters/ExchangeRate.vue';
import DynamicDocumentPresenter from '@/components/dynamic-details/blueprints/field/presenters/DynamicDocument.vue';
import IsPrivatePresenter from '@/components/dynamic-details/blueprints/field/presenters/IsPrivate.vue';
import RichTextPresenter from '@/components/dynamic-details/blueprints/field/presenters/RichText.vue';

defineOptions({
    name: 'logito-document-details-blueprint',
    components: {
        DateTimePresenter,
        UserWithAvatarPresenter,
        UserWithAvatarArrayPresenter,
        TextPresenter,
        StatusPresenter,
        MoneyPresenter,
        BooleanPresenter,
        GenericKeyValuePairPresenter,
        DecimalPresenter,
        AddressPresenter,
        MainFilePresenter,
        ListPresenter,
        SimplePartnerPresenter,
        SimplePartnerArrayPresenter,
        DurationPresenter,
        ResourcePresenter,
        TablePresenter,
        TableVatPresenter,
        GenericKeyValuePairArrayPresenter,
        UserGroupArrayPresenter,
        ExchangeRatePresenter,
        DynamicDocumentPresenter,
        IsPrivatePresenter,
        RichTextPresenter,
        ...properties,
    },
});

const props = defineProps({
  "blueprint": null,
  "form": null,
  "entry": null,
  "parent": null,
  "index": null
});

const { $t } = useLocalization();

const blueprint = computed(() => props.blueprint);
const design = computed(() => props.form.designMode());
const moduleOptions = ref<any[]>([]);
const filters = ref<any>(null);

const detailsModeOptions = computed(() => [
    {
        value: DocumentDetailsMode[DocumentDetailsMode.Field],
        text: $t('[[[Dokument z pola]]]'),
    },
    {
        value: DocumentDetailsMode[DocumentDetailsMode.Single],
        text: $t('[[[Pojedynczy dokument]]]'),
    },
    {
        value: DocumentDetailsMode[DocumentDetailsMode.Multi],
        text: $t('[[[Wiele dokumentów]]]'),
    },
]);

const isFieldMode = computed(() => blueprint.value.mode == DocumentDetailsMode[DocumentDetailsMode.Field]);
const isQueryMode = computed(
    () =>
        blueprint.value.mode == DocumentDetailsMode[DocumentDetailsMode.Single] ||
        blueprint.value.mode == DocumentDetailsMode[DocumentDetailsMode.Multi]
);
const isSingleDocumentMode = computed(() => blueprint.value.mode == DocumentDetailsMode[DocumentDetailsMode.Single]);

const moduleId = computed(() =>
{
    if (blueprint.value.moduleId) return blueprint.value.moduleId;

    if (blueprint.value.fieldName)
        return (
            props.form.schema.components().find((p) => p.name == blueprint.value.fieldName) as RelatedDocumentType
        ).modules.first();

    return null;
});
const fieldOptions = computedAsync(async () =>
{
    if (!design.value || moduleId.value == null) return [];

    const fields = await AutocompleteService.fetchOptionsByCustomEndpoint(
        `admin/forms/module-fields/${moduleId.value}`,
        null,
        new Pager(1, 999, '', 'ASC')
    );

    if (!fields) return [];

    return fields.items.map((item) => item.result).map((p) => ({ ...p, key: p.key, value: `${p.value} (${p.key})` }));
});

const relatedDocumentComponents = computed(() =>
    props.form.schema
        .components(RelatedDocumentDefinition.type)
        .filter((p) => (p as RelatedDocumentType).modules.length == 1)
        .map((p) => ({
            value: p.name,
            text: `${props.form.localization.translate((p as HasLabel).label)} (${p.name})`,
        }))
);

const moduleSelectOptions = computed(() => moduleOptions.value.map((p) => ({ value: p.key, text: p.value })));

const selectedModule = computed(() =>
{
    if (!moduleId.value) return null;

    return moduleOptions.value.find((p) => p.key == moduleId.value);
});

const documentDetails = computedAsync(async () =>
{
    if (design.value) return null;

    const selectedDocuments =
        blueprint.value.mode == DocumentDetailsMode[DocumentDetailsMode.Field]
            ? (props.entry.find(blueprint.value.fieldName) as RelatedDocumentEntry).data.map((p) => p.key)
            : [];

    const fieldFilters: Record<string, string> = {};

    for (const item in blueprint.value.filterMappings)
    {
        const blueprint = props.form.schema.find(item) as EntryFactory<any>;
        const parent = props.form.schema.parent(blueprint);

        if (!blueprint) continue;

        const element = parent?.type === 'table' ? props.entry[parent.name].data[props.index][item] : props.entry[item];

        if (element && 'getValues' in element)
        {
            fieldFilters[item] = element.getValues(blueprint, props.form);
        }
    }

    return await BpmnService.getDocumentsDetails(
        props.entry.module,
        props.entry.formId,
        blueprint.value.name,
        selectedDocuments,
        fieldFilters
    );
});

const schema = computed(() => documentDetails.value?.schema ?? {});
const data = computed(() => documentDetails.value?.data ?? []);

const fetchModuleOptions = async () =>
{
    const response = (await FormsService.getModuleOptions(new Pager(1, 999))).items.map((p) => p.result);

    moduleOptions.value = response;
};

const typeOfData = (value: any, config: [string, Column]) =>
{
    const [itemKey, headerOptions] = config;
    const property = value;
    const type = headerOptions.type.baseType;
    const features = headerOptions.type.features;

    const attributes = {
        property,
        features,
        ...getAttributes(type, {
            schema: schema.value,
            itemKey,
            property,
        }),
    };

    return attributes;
};

watch(
    () => filters.value,
    () => (blueprint.value.filtersJson = filters.value ? JSON.stringify(filters.value) : null),
    { deep: true }
);
watch(
    () => moduleId.value,
    async () =>
    {
        if (!moduleId.value) blueprint.value.fields = {};

        blueprint.value.fields = await FormsService.getModuleDetailsFields(moduleId.value as number);
        filters.value = {};
    }
);

const init = async () =>
{
    if (design.value)
    {
        await fetchModuleOptions();
        filters.value = blueprint.value.filtersJson ? JSON.parse(blueprint.value.filtersJson) : null;
    }
};

init();
</script>

<template>
    <form-component-wrapper
        class="logito-document-details-component"
        :form="form"
        :parent="parent"
        :blueprint="blueprint"
    >
        <template #default>
            <div class="form-group">
                <form-label :form="form" :blueprint="blueprint" />
                <div v-if="!design">
                    <template v-if="isFieldMode || isSingleDocumentMode">
                        <div v-for="(value, key) in schema" :key="key" class="py-1">
                            <details-label :label="value.headerName">
                                <template #default>
                                    <component
                                        v-if="!design && value"
                                        :is="typeOfData(data?.[0]?.[key], [key as string, value]).is"
                                        v-bind="{ ...typeOfData(data?.[0]?.[key], [key as string, value]) }"
                                    />
                                </template>
                            </details-label>
                        </div>
                        <p v-if="!Object.keys(schema).length && isFieldMode" class="mb-0">{{ $t('[[[Wybierz dokument, aby zobaczyć szczegóły]]]') }}</p>
                    </template>
                    <div v-else class="scroll scroll-x">
                        <table class="table table-striped mb-1">
                            <thead>
                                <tr>
                                    <th v-for="(value, key) in schema" :key="key">{{ value.headerName }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in data" :key="index">
                                    <td v-for="(value, key) in schema" :key="key">
                                        <component
                                            v-if="!design && item"
                                            :is="typeOfData(item[key], [key as string, value]).is"
                                            v-bind="{ ...typeOfData(item[key], [key as string, value]) }"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div v-if="design">
                    <p class="mb-0">{{ $t('[[[Podgląd niedostępny w trybie projektowania]]]') }}</p>
                </div>
                <form-help :form="form" :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.label[locale]" :label="$t('[[[Etykieta]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <ideo-form-localize v-slot="{ locale }">
                <field-textarea v-model="blueprint.help[locale]" :label="$t('[[[Pomoc]]]')" />
            </ideo-form-localize>
            <field-select
                v-model="blueprint.mode"
                :options="detailsModeOptions"
                :label="$t('[[[Tryb wyświetlania]]]')"
                :required="true"
            />
            <field-select
                v-if="isFieldMode"
                v-model="blueprint.fieldName"
                :options="relatedDocumentComponents"
                :label="$t('[[[Pole z dokumentem]]]')"
                :required="true"
                :invalid-feedback="() => form.schema.errorMessage(blueprint, 'fieldName')"
            />
            <template v-if="isQueryMode">
                <field-select
                    v-model="blueprint.moduleId"
                    :options="moduleSelectOptions"
                    :label="$t('[[[Moduł]]]')"
                    :invalid-feedback="() => form.schema.errorMessage(blueprint, 'moduleId')"
                    required
                />
                <field-filters
                    v-if="blueprint.moduleId"
                    v-model="filters"
                    :selected-modules="[selectedModule]"
                    :label="$t('[[[Filtry]]]')"
                />
                <field-map
                    v-if="blueprint.moduleId"
                    v-model="blueprint.filterMappings"
                    :label="$t('[[[Filtry wartościami pól formularza]]]')"
                    :form="form"
                    :blueprint="blueprint"
                    :options="fieldOptions"
                    :selected-modules="[selectedModule]"
                    is-filters
                />
            </template>
            <ideo-form-group>
                <portal-target name="grid-config" ref="target" />
                <columns-customizer
                    :columns="blueprint.fields"
                    portal-name="grid-config"
                    :title="$t('[[[Pola do wyświetlenia]]]')"
                />
            </ideo-form-group>
            <field-visible :form="form" :blueprint="blueprint" :label="$t('[[[Widoczność]]]')" />
        </template>
    </form-component-wrapper>
</template>

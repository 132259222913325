import { axios } from '@/plugins/axios';
import merge from 'lodash/merge';
import Pager from '@/helpers/Pager';
import { Pagination, Statement, Resource, EntityVersion, KeyValuePair } from '@/helpers/Interfaces';
import { PublicationEnum } from '@/helpers/Enums';
import { DateTime } from 'luxon';
import { FormContract } from '@/components/forms/blueprints/form';
import { Column, IMetaModel } from '@/modules/core/common/services/GridService';

export default class FormsService
{
    public static async getList(filter: FilterModel, pager: Pager): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<ListItemModel>>>('admin/studio/forms', {
            params: merge({}, filter, pager.data())
        })).data;
    }

    public static async getActions(id: string): Promise<Record<string, IMetaModel>>
    {
        return (await axios.get(`simple-query/forms/${id}/actions`)).data;
    }

    public static async fetch(id: string): Promise<Resource<FormModel>>
    {
        // return (await axios.get<Resource<FormModel>>(`admin/studio/forms/${id}`)).data;
        return (await axios.get<Resource<FormModel>>(`simple-fetch/forms/${id}`)).data;
    }

    public static async getOptions(pager: Pager, categoryId: number | null, id: number | null, search: string = null): Promise<Pagination<FormOptionModel>>
    {
        return (await axios.get<Pagination<FormOptionModel>>('admin/studio/forms/options', {
            params: merge({}, pager.data(), { category: categoryId, id: id, search: search })
        })).data;
    }

    public static async fetchDefinition(versionId: number): Promise<FormModel>
    {
        return (await axios.get<FormModel>(`admin/studio/forms/definition/${versionId}`)).data;
    }

    public static async fetchPublished(originId: number): Promise<FormModel>
    {
        return (await axios.get<FormModel>(`admin/studio/forms/definition/${originId}/published`)).data;
    }

    public static async create(model: UpsertModel, processId?: string, moduleId?: string): Promise<string>
    {
        if (processId !== null && processId !== '')
            return (await axios.post(`simple-command/process/${processId}/dynamic-forms`, model)).data;

        if (moduleId !== null && moduleId !== '')
            return (await axios.post(`simple-command/modules/${moduleId}/dynamic-forms`, model)).data;

        return (await axios.post(`admin/studio/forms`, model)).data;
    }

    public static async update(id: string, model: UpsertModel): Promise<FormModel>
    {
        return (await axios.put(`admin/forms/${id}`, model)).data;
    }

    public static async remove(id: number): Promise<Statement>
    {
        return (await axios.delete(`admin/studio/forms/${id}`)).data as Statement;
    }

    public static async status(id: number, status: PublicationEnum): Promise<Statement>
    {
        return (await axios.patch(`admin/studio/forms/${id}/status`, {
            status: status
        })).data;
    }

    public static async getVersions(id: number, pager: Pager): Promise<Pagination<EntityVersion>>
    {
        return (await axios.get<Pagination<EntityVersion>>(`admin/studio/forms/${id}/versions`, {
            params: pager.data()
        })).data;
    }

    public static async getModuleOptions(pager: Pager, search: string = null): Promise<Pagination<Resource<ModuleOption>>>
    {
        return (await axios.get(`admin/forms/module-options`, {
            params: merge({}, pager.data(), { search: search })
        })).data;
    }

    public static async getModuleBaseUrl(moduleId: number): Promise<string>
    {
        return (await axios.get(`modules/${moduleId}/baseUrl`)).data;
    }

    public static async getModuleDetailsFields(moduleId: number): Promise<Record<string, Column>>
    {
        return (await axios.get(`admin/forms/module-details/${moduleId}`)).data;
    }

    public static async copy(model: CopyFormModel): Promise<any>
    {
        return (await axios.post<CopyFormModel>(`admin/studio/forms/copy`, model)).data;
    }
}

export interface ListItemModel
{
    id: number;
    dateCreatedUtc: DateTime;
    dateModifiedUtc: DateTime;
    createdBy: string;
    modifiedBy: string;
    title: string;
    category: string;
    status: PublicationEnum;
    isArchived: boolean;
    ownerId: number;
    resourceId: number;
}

export interface FilterModel
{
    categoryId?: number;
    userId: number;
    status?: PublicationEnum;
    title: string;
    dateFromUtc?: DateTime;
    dateDueUtc?: DateTime;
    showArchived: boolean;
    onlyMine: boolean;
    moduleId: number | KeyValuePair;
    processId: number | KeyValuePair;
}

export interface ParentListFilterModel
{
    categoryId: number,
    title: string,
    isGlobal: boolean
}

export interface UpsertModel
{
    id: number;
    definition: FormContract;
    isVersionable: boolean;
    isArchived: boolean;
    allowAnonymous: boolean;
    publicId: string,
}

export interface FormModel extends UpsertModel
{
    status: PublicationEnum;
    ownerId: number;
    resourceId: number;
}

export interface FormOptionModel
{
    value: number;
    text: string;
    isDisabled: boolean;
}



export interface ModuleOption
{
    key: string;
    value: string;
    licence: string;
}

export interface CopyFormModel
{
    formOrigin: number;
    formId: number;
    name: string;
    type: 'accepted'|'version'
}

export interface Contractor {
    address: string;
    id: number;
    name: string;
    nip: string;
}

export interface RelatedDocument {
    amount: number;
    canSee: boolean;
    company: string;
    contractor: Array<Contractor>;
    createdBy: any;
    dateCreatedUtc: string;
    dueDate: string;
    id: string;
    key: string;
    licence: string;
    module: string;
    register: string;
    signature: string;
    title: string;
    value: string;
    additionalFields: Record<string, any>;
}

export interface ModuleDetailsField
{
    headerName: string;
    order: number;
    visibility: boolean;
}

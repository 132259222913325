<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Ref } from '@/helpers/Decorators';
import { $t } from '@/plugins/localization';
import { Form } from '@/helpers/Form';
import BpmnService, { BpmnEmitOptions, BpmnFormModel } from '@/modules/low-code/process/services/BpmnService';
import { FrontActionModal } from '@/components/common/dynamic-grid/helpers/GridEnums';
import AddAttachments from '@/views/partials/components/attachments/AddAttachments.vue';
import IdeoModal from '@/components/ideo/modal/IdeoModal.vue';

@Options({
    components: { AddAttachments },
})
export default class BPMNReturnModal extends Vue
{
    @Ref('modal') public modal: () => IdeoModal;

    public form = Form.create<BpmnFormModel>({
        comment: '',
        attachments: [],
    });

    public action = Form.create<BpmnEmitOptions>({
        id: '',
        licence: '',
        actionName: '',
        buttonId: '',
        displayMode: 'Modal',
        displaySize: 'Medium',
        title: $t('[[[Akceptuj]]]'),
        buttonName: $t('[[[Akceptuj]]]'),
        buttonVariant: 'success',
        buttonIcon: '',
        groupAction: false,
    });

    public async mounted(): Promise<void>
    {
        this.$events.$on(FrontActionModal.ReturnModel, (action: BpmnEmitOptions) =>
        {
            this.action.withData(action);
            this.modal().show();
        });
    }

    public unmounted(): void
    {
        this.$events.$off(FrontActionModal.ReturnModel);
    }

    public async submit(): Promise<void>
    {
        try
        {
            this.form.wait();

            // Group action
            if (this.action.groupAction)
            {
                const endpoint = `${this.action.licence}/${this.action.buttonId}/bpmn-execute/${this.action.id}`;
                const response: any = await BpmnService.execute(endpoint, this.form.formatData());

                if (response?.length)
                {
                    this.$alert.warning(`${this.$t('[[[Dla dokumentów o sygnaturach]]]')}: ${response.join(', ')} ${this.$t('[[[nie udało się wykonać akcji]]]')}`);
                }
                else
                {
                    this.$alert.success(this.$t('[[[Akcja wykonana pomyślnie]]]'));
                }
            }
            else
            {
                const endpoint = `${this.action.licence}/${this.action.id}/${this.action.buttonId}/bpmn-execute`;

                await BpmnService.execute(endpoint, this.form.formatData());
                this.$alert.success(this.$t('[[[Akcja wykonana pomyślnie]]]'));
            }

            this.modal().hide();

            setTimeout(() =>
            {
                this.$emit('reload');
                this.$events.$emit('refetchActions');
                this.form.reset();
            }, 500);
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
                422: (ex: any) => [
                    this.form.$errors.record(ex.data.errors),
                    this.$alert.warning(this.$t('[[[Nie wszystkie pola zostały wypełnione prawidłowo.]]]')),
                ],
            });
        }
        finally
        {
            this.form.continue();
        }
    }
}
</script>

<template>
    <IdeoModal ref="modal" :title="action.title" centered @close="form.reset()" @hidden="form.reset()">
        <template #default>
            <IdeoFormGroup :label="$t('[[[Dodaj załączniki]]]')">
                <AddAttachments v-model="form.attachments" :container="'documents'" />
            </IdeoFormGroup>
            <IdeoFormGroup
                :label="$t('[[[Uwagi]]]')"
                :invalid-feedback="form.$errors.first('comment')"
                :state="form.$errors.state('comment')"
                required
            >
                <IdeoFormTextarea v-model="form.comment" rows="6" @input="form.$errors.clear('comment')" />
            </IdeoFormGroup>
        </template>
        <template #modal-footer="{ cancel }">
            <IdeoButton :variant="action.buttonVariant" :disabled="!form.active()" @click="submit">
                <i v-if="action.buttonIcon" class="me-2" :class="action.buttonIcon"></i>
                <span>{{ action.buttonName }} </span>
            </IdeoButton>
            <IdeoButton variant="secondary" @click="cancel()">
                {{ $t('[[[Anuluj]]]') }}
            </IdeoButton>
        </template>
    </IdeoModal>
</template>

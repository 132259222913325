import { defineStore } from 'pinia';
import { ref } from 'vue';
import { RelatedDocument } from '@/modules/studio/forms/services/FormsService';

export const useLowCodeStore = defineStore('low-code', () =>
{
    const fieldKey = ref('');
    const document = ref<RelatedDocument>(null);

    function setFieldKey(value: string): void
    {
        fieldKey.value = value;
    }

    function setDocument(value: any): void
    {
        document.value = value;
    }

    return {
        fieldKey,
        document,
        setFieldKey,
        setDocument,
    };
});

import { Resource } from "@/helpers/Interfaces";
import { AttributesModel, Column, ColumnDefinitionModel } from "@/modules/core/common/services/GridService";
import { ComponentTypeEnum } from "@/components/common/dynamic-grid/helpers/GridEnums";
import { Attachment } from "@/modules/low-code/services/ShareOneDriveService";
import { get } from "lodash";

export function TypeOfData(
    item: Resource<any>,
    header: [string, Column],
    options?: { schema?: ColumnDefinitionModel; isOnGrid?: boolean; actions?: any; oneDriveSharedDocuments?: Array<Attachment> }
): Record<string, any>
{
    const [itemKey, headerOptions] = header;
    const property = get(item.result, itemKey);
    const type = headerOptions.type.baseType;
    const features = headerOptions.type.features;

    const attributes: AttributesModel = {
        property,
        features,
        ...getAttributes(type, {
            schema: options?.schema,
            itemKey,
            property,
            actions: options?.actions,
            oneDriveSharedDocuments: options?.oneDriveSharedDocuments,
        }),
    };

    options.isOnGrid && (attributes.isOnGrid = true);

    return attributes;
}

export function getAttributes(
    type: string,
    data?: { schema?: ColumnDefinitionModel; itemKey?: string; property?: any; actions?: any; oneDriveSharedDocuments?: Array<Attachment>, isInTable?: boolean, canCopy?: boolean }
): AttributesModel
{
    const attributes: AttributesModel = {} as AttributesModel;

    switch (type)
    {
        case ComponentTypeEnum.DateTime: {
            attributes.is = "DateTimePresenter";
            break;
        }
        case ComponentTypeEnum.UserAssingment:
        case ComponentTypeEnum.UserAvatar: {
            attributes.is = "UserWithAvatarPresenter";
            break;
        }
        case "UserDisplayModel[]":
        case "ActiveTargetFront[]": {
            attributes.is = "UserWithAvatarArrayPresenter";
            break;
        }
        case ComponentTypeEnum.StatusKeyValuePair: {
            attributes.is = "StatusPresenter";
            break;
        }
        case ComponentTypeEnum.Partner: {
            attributes.is = "SimplePartnerPresenter";
            break;
        }
        case "SimplePartner[]": {
            attributes.is = "SimplePartnerArrayPresenter";
            break;
        }
        case ComponentTypeEnum.Address: {
            attributes.is = "AddressPresenter";
            break;
        }
        case ComponentTypeEnum.MainFile: {
            attributes.is = "MainFilePresenter";
            break;
        }
        case ComponentTypeEnum.Money: {
            attributes.is = "MoneyPresenter";
            break;
        }
        case ComponentTypeEnum.Duration: {
            attributes.is = "DurationPresenter";
            break;
        }
        case ComponentTypeEnum.Decimal: {
            attributes.is = "DecimalPresenter";
            attributes.isInTable = data?.isInTable;
            attributes.canCopy = data?.canCopy;
            break;
        }
        case ComponentTypeEnum.GenericKeyValuePair:
        {
            attributes.is = "GenericKeyValuePairPresenter";
            break;
        }
        case "GenericKeyValuePair[]":
        case "DynamicDictionaryFront[]": {
            attributes.is = "GenericKeyValuePairArrayPresenter";
            break;
        }
        case ComponentTypeEnum.Boolean: {
            attributes.is = "BooleanPresenter";
            break;
        }
        case "Ideo.Core.App.Areas.Office.Outgoings.Models.ContractorDetails[]":
        case "Ideo.Core.App.Areas.Office.Outgoings.Models.ContractorWithShipmentDetails[]": {
            attributes.is = "ListPresenter";
            break;
        }
        case "ResourceConfidential":
        case "ResourceConfidential[]": {
            attributes.is = "ResourcePresenter";
            attributes.actions = data?.actions;
            attributes.oneDriveSharedDocuments = data?.oneDriveSharedDocuments;
            attributes.itemKey = data?.itemKey;
            break;
        }
        case "DynamicDocumentFront":
        case "DynamicDocumentFront[]": {
            attributes.is = "DynamicDocumentPresenter";
            break;
        }
        case ComponentTypeEnum.IControlAggregate: {
            attributes.is = "TablePresenter";
            attributes.canCopy = data?.canCopy;

            if (data?.schema === undefined || data?.itemKey === undefined) break;

            const keys = data.property?.length && Object.keys(data.property[0]);
            const tableSchema = {
                head: data.schema[data.itemKey],
                body: Object.fromEntries(
                    Object.entries(data.schema)
                        .filter(([key]) => (key.startsWith(data.itemKey) && key !== data.itemKey) || (Array.isArray(keys) && keys.includes(key)))
                        .map(([key, value]) => [key.split("_").last(), value])
                ),
            };

            attributes.tableSchema = tableSchema;

            break;
        }
        case ComponentTypeEnum.TableVatPresenter: {
            attributes.is = "TableVatPresenter";
            break;
        }
        case ComponentTypeEnum.UserGroupRawArray: {
            attributes.is = "UserGroupArrayPresenter";
            break;
        }
        case ComponentTypeEnum.ExchangeRateFront: {
            attributes.is = "ExchangeRatePresenter";
            break;
        }
        case "RichText": {
            attributes.is = "RichTextPresenter";
            break;
        }
        default: {
            attributes.is = "TextPresenter";
        }
    }

    return attributes;
}

<script lang="ts" setup>
import __MACROS_useVModel from "/vue-macros/define-models/use-vmodel";
defineEmits(["update:modelValue"]);
import { computed } from 'vue';
import { useLocalization } from '@/plugins/localization';
import { mapKeys } from 'lodash';
import { typeName } from '@/helpers/Utils';
import { KeyValuePair } from '@/helpers/Interfaces';
import { FormBuilderContract } from '@/components/builder/form';
import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { Definition as TextDefinition } from '../blueprints/text';
import { Definition as LogitoChoiceDefinition } from '../blueprints/logito-choice';
import { Definition as BooleanDefinition } from '../blueprints/boolean';
import { Definition as DateDefinition } from '../blueprints/date';
import { Definition as NumericDefinition } from '../blueprints/numeric';
import { Definition as LogitoAttachmentsDefinition } from '../blueprints/logito-attachments';
import { Definition as EmailDefinition } from '../blueprints/email';
import { Definition as LogitoCompanyDefinition } from '../blueprints/logito-company';
import { Definition as LogitoRegisterDefinition } from '../blueprints/logito-register';
import { Definition as LogitoUserDefinition } from '../blueprints/logito-user';
import { Definition as LogitoContractorDefinition } from '../blueprints/logito-contractor';
import { Definition as LogitoUserGroupDefinition } from '../blueprints/logito-user-group';
import { Definition as LogitoDocumentDefinition } from '../blueprints/logito-document';
import { Definition as LogitoDictionaryDefinition } from '../blueprints/logito-dictionary';
import { Definition as LogitoRelatedDocumentsDefinition } from '../blueprints/logito-related-documents';
import { Definition as LogitoTableDefinition } from '../blueprints/logito-table';
import { Definition as LogitoExchangeRatesDefinition } from '../blueprints/logito-exchange-rates';
import { Definition as LogitoVatTableDefinition } from '../blueprints/logito-vat-table';
import { ModuleOption } from '@/modules/studio/forms/services/FormsService';

const { modelValue } = __MACROS_useVModel("modelValue");

const props = defineProps({
  "modelValue": null,
  "form": null,
  "blueprint": null,
  "label": null,
  "selectedModules": null,
  "options": null,
  "isFilters": { type: Boolean,  }
});

const { $i18n } = useLocalization();

const fields = computed(() =>
{
    return Object.entries(modelValue.value || {}).map(([key, value]) => ({
        bindFrom: components.value.find((item) => item.key === key),
        bindTo: props.options.find((item) => item.key === value),
    }));
});

const components = computed(() =>
{
    return props.form.schema
        .components(
            !props.isFilters ?
                [
                    TextDefinition.type,
                    LogitoChoiceDefinition.type,
                    BooleanDefinition.type,
                    DateDefinition.type,
                    NumericDefinition.type,
                    LogitoAttachmentsDefinition.type,
                    EmailDefinition.type,
                    LogitoCompanyDefinition.type,
                    LogitoRegisterDefinition.type,
                    LogitoUserDefinition.type,
                    LogitoContractorDefinition.type,
                    LogitoUserGroupDefinition.type,
                    LogitoDocumentDefinition.type,
                    LogitoDictionaryDefinition.type,
                    LogitoRelatedDocumentsDefinition.type,
                    LogitoTableDefinition.type,
                    LogitoExchangeRatesDefinition.type,
                    LogitoVatTableDefinition.type,
                ] : [
                    BooleanDefinition.type,
                    LogitoChoiceDefinition.type,
                    LogitoRelatedDocumentsDefinition.type,
                    LogitoDictionaryDefinition.type,
                    LogitoUserDefinition.type
                ],
            props.blueprint
        )
        .map((p) => ({
            key: p.name,
            value: `${$i18n.currentTranslation((p as HasLabel).label)} (${p.name})`,
            type: p.type,
        }));
});

const keyOptions = (option?: KeyValuePair) =>
{
    return components.value.filter(
        (p) => Object.keys(modelValue.value).includes(p.key) === false || p.key === option?.key
    );
};

const filtredValueOptions = (option: KeyValuePair & { type: string }) =>
{
    return props.options.filter((p) => typeName(option.type) === p.type);
};

const addField = () =>
{
    if (!keyOptions().length) return;

    modelValue.value = {
        ...modelValue.value,
        [`${keyOptions()[0].key}`]: null,
    };
};

const updateFieldKey = (fromKey: KeyValuePair, toKey: KeyValuePair) =>
{
    fromKey?.key && (modelValue.value[fromKey.key] = null);
    modelValue.value = mapKeys(modelValue.value, (_, key) =>
    {
        if (key === fromKey.key) return toKey.key;

        return key;
    });
};

const updateFieldValue = (fromKey: KeyValuePair, value: KeyValuePair) =>
{
    modelValue.value[fromKey.key] = value.key;
};

const deleteField = (index: number) =>
{
    const key = fields.value[index].bindFrom.key;

    delete modelValue.value[key];
};
</script>

<template>
    <div class="form-group">
        <label v-if="label">{{ $t(label) }}</label>
        <div v-for="(item, index) in fields" :key="index" class="d-flex mb-1">
            <div class="d-flex gap-1 flex-fill">
                <ideo-select
                    :model-value="item.bindFrom"
                    :clear-button="false"
                    :options="keyOptions(item.bindFrom)"
                    :narrow-options="{ item: item.bindFrom }"
                    class="w-50"
                    @update:model-value="updateFieldKey(item.bindFrom, $event)"
                />
                <ideo-select
                    :model-value="item.bindTo"
                    :clear-button="false"
                    :options="filtredValueOptions(item.bindFrom)"
                    :narrow-options="{ item: item.bindTo }"
                    class="w-50"
                    @update:model-value="updateFieldValue(item.bindFrom, $event)"
                />
            </div>
            <div class="input-group-append d-flex ms-1">
                <button type="button" class="btn btn-outline-light" @click="deleteField(index)">
                    <i class="far fa-trash text-danger"></i>
                </button>
            </div>
        </div>
        <button class="btn btn-outline-light w-100" type="button" :disabled="!keyOptions().length" @click="addField">
            <i class="far fa-plus text-success"></i>
        </button>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { KeyValuePair } from '@/helpers/Interfaces';
import { DefaultAddressListItemModel } from '@/modules/logito/crm/services/BusinessPartnerService';

const props = defineProps({
  "property": { default: null },
  "features": { default: (): KeyValuePair[] => [] },
  "isOnGrid": { type: Boolean, default: false }
});

const getAddress = computed(() =>
{
    return `${props.property.street} ${props.property.houseID} ${
        props.property.additionalHouseID ? '/ ' + props.property.additionalHouseID : ''
    }`;
});

const getAddress2 = computed(() => `${props.property.postalCode} ${props.property.city}`);
</script>

<template>
    <div class="d-flex align-items-center" v-if="property">
        <span class="fa fa-map-marker-alt text-primary me-2"></span>
        <div class="d-flex flex-column">
            <span v-html="getAddress"></span>
            <span v-html="getAddress2"></span>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import { KeyValuePair } from '@/helpers/Interfaces';

@Options({
    name: 'UserCard',
})
export default class UserCard extends Vue
{
    @Prop({ default: '' }) public username: string;
    @Prop({ default: '' }) public avatarUrl: string;
    @Prop({ default: '' }) public jobPosition: KeyValuePair;
    @Prop({ default: '' }) public unit: KeyValuePair;
    @Prop({ default: '' }) public roomNumber: string;
    @Prop({ default: '' }) public email: string;
    @Prop({ default: '' }) public phoneNumber: string;
    @Prop({ default: '' }) public cellNumber: string;
    @Prop({ default: null }) public absence: any;

    public get teamsIcon(): string
    {
        return '/static/img/microsoft-teams.svg';
    }
}
</script>

<template>
    <div class="user-card">
        <div class="left-section">
            <user-avatar :src="avatarUrl" :username="username" :size="50" />
            <ideo-badge v-if="absence" variant="success" class="absence">
                {{ absence.type.value }}
            </ideo-badge>
            <a
                :href="`https://teams.microsoft.com/l/chat/0/0?users=${email}`"
                target="_blank"
                class="icon icon-custom-size"
                :title="$t('[[[Wyślij wiadomość Microsoft Teams]]]')"
                @click.stop
            >
                <img :src="teamsIcon" style="height: 25px; width: 25px" />
            </a>
        </div>
        <div class="right-section">
            <strong class="name">{{ username }}</strong>
            <span v-if="jobPosition?.value">{{ jobPosition.value }}</span>
            <ideo-badge v-if="unit" variant="dark" :title="unit.value" class="unit">
                {{ unit.value }}
            </ideo-badge>
            <div class="details-group-item">
                <span>{{ $t('[[[Pokój]]]') }}: </span>
                <strong>{{ roomNumber }}</strong>
            </div>
            <div class="details-group-item">
                <span>{{ $t('[[[Email]]]') }}: </span>
                <a :href="`mailto:${email}`">{{ email }}</a>
            </div>
            <div class="details-group-item">
                <span>{{ $t('[[[Tel]]]') }}: </span>
                <strong>{{ phoneNumber }}</strong>
            </div>
            <div class="details-group-item">
                <span>{{ $t('[[[Kom]]]') }}: </span>
                <strong>{{ cellNumber }}</strong>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.user-card {
    display: flex;
    border: 1px solid var(--bs-border-color);
    border-radius: 4px;
    overflow: hidden;
    min-width: 320px;
    max-width: 320px;
    cursor: pointer;

    .left-section {
        background-color: #f3f3f5;
        display: flex;
        flex-direction: column;
        gap: 0.9375rem;
        align-items: center;
        padding: 10px;
    }

    .right-section {
        background: #f3f3f5;
        padding: 10px 10px 10px 5px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        overflow: hidden;
        flex-shrink: 1;
        flex-grow: 1;
        align-items: start;

        .name {
            font-size: 0.8125rem;
            line-height: 1.063rem;
        }

        .unit {
            display: inline-block;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
</style>

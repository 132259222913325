<script lang="ts" setup>
import { ref, reactive, computed, provide, onUnmounted } from 'vue';
import { useLocalization } from '@/plugins/localization';
import { useEvents } from '@/plugins/events';
import { useAlerts } from '@/plugins/alerts';
import { useLowCodeStore } from '@/modules/low-code/common/store/low-code';
import Pager from '@/helpers/Pager';
import { Form } from '@/helpers/Form';
import { FormContract, FormEntry } from '@/components/forms/blueprints/form/index';
import { RelatedDocumentContract, RelatedDocumentEntry } from '@/components/forms/blueprints/logito-related-documents';
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import { FluentFormBuilder } from '@/components/forms';
import { AttachmentsContract, instanceOfAttachmentsEntry } from '@/components/forms/blueprints/attachments';
import { instanceOfUserGroupEntry } from '@/components/forms/blueprints/logito-user-group';
import { instanceOfUserEntry } from '@/components/forms/blueprints/logito-user';
import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { Entry } from '@/components/builder/form/entries/Entry';
import { FrontActionModal } from '@/components/common/dynamic-grid/helpers/GridEnums';
import ModulesService from '@/modules/low-code/services/ModulesService';
import BpmnService, { BpmnEmitOptions } from '@/modules/low-code/process/services/BpmnService';
import StorageService, { Resource as FileResource } from '@/modules/core/files/services/StorageService';
import FileReader from '@/views/partials/components/attachments/FIleReader.vue';
import IdeoModal from '@/components/ideo/modal/IdeoModal.vue';
import Loader from '@/components/common/Loader.vue';

const emit = defineEmits(["reload"]);

const props = defineProps({
  "level": null
});

const { $t } = useLocalization();
const { $alert } = useAlerts();
const { $events } = useEvents();
const store = useLowCodeStore();

const form = reactive(Form.create({
    document: null as FormContract,
}));
const entry = ref<FormEntry>(new FormEntry());
const builder = ref(
    FluentFormBuilder.setup(false, true)
        .blueprint(
            () => form.document,
            () => 0
        )
        .entry(
            () => entry.value,
            () => 0
        )
        .make()
);

const modal = ref<InstanceType<typeof IdeoModal> | null>(null);
const reloadKey = ref(0);
const isLoaded = ref(false);
const filePreview = ref<FileResource | null>(null);

const action = reactive(Form.create<BpmnEmitOptions>({
    id: '',
    licence: '',
    actionName: '',
    buttonId: '',
    displayMode: 'Modal',
    displaySize: 'Medium',
    title: $t('[[[Formularz]]]'),
    buttonName: $t('[[[Zapisz]]]'),
    buttonVariant: 'success',
    buttonIcon: '',
    groupAction: false,
    formButtonName: '',
    blueprint: null,
}));

provide('level', props.level);

const isLogitoDocument = computed(() =>
{
    const document = builder.value.schema.names('document');

    if (document.length && action.displaySize === 'LargeWithDocument') return true;

    return false;
});

const size = computed(() =>
{
    switch (action.displaySize)
    {
        case 'Large':
            return 'xl';
        case 'LargeWithDocument':
            return 'xl';
        case 'Medium':
            return 'lg';
        case 'Small':
            return 'md';
        default:
            return 'md';
    }
});

const title = computed(() => form.document?.title ? builder.value.localization.translate(form.document.title) : action.name);
const guids = computed(() => action.id.split(',')[0]);

const reset = () =>
{
    isLoaded.value = false;
    entry.value = new FormEntry();
    form.document = null;
    builder.value.update();
    action.reset();
};

const loadForm = async () =>
{
    try
    {
        const response = guids.value
            ? await ModulesService.fetchForm(
                `${action.licence}/${guids.value}/${action.actionName}/${action.buttonId}`
            )
            : await ModulesService.fetchForm(`${action.licence}/bpmn-start/${action.buttonId}`);

        form.document = response;
        builder.value.update();
    }
    catch (ex: any)
    {
        if (ex.code === 400) $alert.warning(ex.message);
    }
};

const loadData = async () =>
{
    if (action.groupAction) return;

    try
    {
        if (guids.value)
        {
            const response = await BpmnService.fetchFormEntry(
                action.licence,
                guids.value,
                action.buttonId
            );

            entry.value = response;
        }
        else
        {
            if (store.fieldKey && store.document)
            {
                entry.value[store.fieldKey] = new RelatedDocumentEntry();
                entry.value[store.fieldKey].data = [store.document];

                const logitoRelatedDocumentsBlueprint = builder.value.schema.find(
                    store.fieldKey
                ) as RelatedDocumentContract;

                if (!logitoRelatedDocumentsBlueprint) return;

                Object.entries(logitoRelatedDocumentsBlueprint.fieldMappings).forEach(([key, value]) =>
                {
                    const blueprint = builder.value.schema.find(key) as EntryFactory<any>;

                    if (!blueprint) return;

                    entry.value[key] = blueprint.createEntry(store.document.additionalFields?.[value]?.data);
                });
            }
        }

        builder.value.update();
    }
    catch (ex: any)
    {
        if (ex.code === 400) $alert.warning(ex.message);
    }
};

const addEntriesData = () =>
{
    entry.value = builder.value.getEntry() as FormEntry;
    entry.value.module = action.licence;
    entry.value.formId = `${guids.value ? `${guids.value}/` : ''}${action.buttonId}`;
    entry.value.actionName = action.actionName;
};

const addEvents = () =>
{
    $events.$on(`${FrontActionModal.Object}-${props.level}`, async (_action: BpmnEmitOptions) =>
    {
        form.document = null;
        entry.value = new FormEntry();
        action.withData(_action);

        try
        {
            if (!action.buttonId)
            {
                $alert.warning($t('[[[Nie podano ID procesu, którego dotyczy formularz]]]'));

                return;
            }

            await initForm();

            modal.value.show();
        }
        catch (ex: any)
        {
            if (ex.code === 400) $alert.warning(ex.message);
        }
    });

    $events.$on(
        `update-related-documents-${props.level}`,
        async (blueprint: RelatedDocumentContract, publicId: string) =>
        {
            const pager = new Pager(1, 32, '', 'ASC');
            const response = await ModulesService.fetchDocument(
                action.licence,
                entry.value.formId,
                action.actionName,
                blueprint.name,
                { key: publicId },
                pager
            );
            const document = response.items?.[0]?.result;

            if (blueprint && document)
            {
                if (blueprint.multiselect)
                {
                    entry.value[blueprint.name].data ??= [];
                    entry.value[blueprint.name].data.push(document);
                }
                else entry.value[blueprint.name].data = Array.isArray(document) ? document : [document];
            }
        }
    );

    $events.$on(`file-to-preview-${props.level}`, (file: FileResource) =>
    {
        filePreview.value = file;
    });

    $events.$on('form-reload', () =>
    {
        builder.value.update();
        reloadKey.value++;
    });
};

const onSubmit = async () =>
{
    form.wait();

    const additonalData: Record<string, any> = {};

    let data = (await builder.value.collectEntry(async (blueprint: Blueprint, entry: Entry) =>
    {
        instanceOfAttachmentsEntry(entry) &&
        (await entry.upload(
            blueprint as AttachmentsContract,
            async (file) => await StorageService.upload(file, 'documents')
        ));
        instanceOfUserGroupEntry(entry) && (additonalData[`${blueprint.name}_type`] = entry.settings?.key);
        instanceOfUserEntry(entry) && (additonalData[`${blueprint.name}_type`] = entry.valuesType);
    })) as Record<string, any>;

    data = { ...data, ...additonalData };

    try
    {
        if (action.id)
        {
            // Group action
            if (action.groupAction)
            {
                const endpoint = `${action.licence}/${action.buttonId}/bpmn-execute/${action.id}`;
                const response: any = await BpmnService.execute(endpoint, data);

                if (response?.length)
                {
                    $alert.warning(
                        `${$t('[[[Dla dokumentów o sygnaturach]]]')}: ${response.join(', ')} ${$t(
                            '[[[nie udało się wykonać akcji]]]'
                        )}`
                    );
                }
                else
                {
                    $alert.success($t('[[[Akcja wykonana pomyślnie]]]'));
                }
            }
            else
            {
                const endpoint = `${action.licence}/${action.id}/${action.buttonId}/bpmn-execute`;

                await BpmnService.execute(endpoint, data);
                $alert.success($t('[[[Zapisano formularz]]]'));
            }
        }
        else
        {
            const response = await ModulesService.saveForm(action.licence, action.buttonId, data);
            const publicId = response.result.publicId;

            await new Promise((resolve) =>
                setTimeout(() =>
                {
                    $events.$emit(
                        `update-related-documents-${props.level - 1}`,
                        action.blueprint,
                        publicId
                    );
                    resolve('resolved');
                }, 1000)
            );

            $alert.success($t('[[[Zapisano formularz]]]'));
        }

        modal.value.hide();

        setTimeout(() =>
        {
            emit('reload');
            $events.$emit('refetch');
            // $events.$emit('refetchData');
            $events.$emit('refetchActions');
        }, 500);
    }
    catch (ex: any)
    {
        if (ex.code === 400) $alert.danger($t('[[[Nie udało się zapisać formularza]]]'));
        else if (ex.code === 422)
        {
            const errors: Record<string, string[]> = {};

            Object.entries(ex.data.errors).forEach(([key, value]) =>
            {
                const keyWithDot =
                    key.includes('.value') || key.includes('.custom')
                        ? key.toLowerCase()
                        : `${key.toLowerCase()}.value`;

                errors[keyWithDot] = value as string[];
            });

            builder.value.setEntryErrors(errors);
            $alert.warning($t('[[[Nie wszystkie pola zostały wypełnione prawidłowo.]]]'));
        }
    }
    finally
    {
        form.continue();
    }
};

const initForm = async () =>
{
    form.wait();
    await loadForm();
    await loadData();
    addEntriesData();

    isLoaded.value = true;
    form.complete();
};

const init = async () =>
{
    addEvents();
};

init();

onUnmounted(() =>
{
    $events.$off(`${FrontActionModal.Object}-${props.level}`);
    $events.$off(`update-related-documents-${props.level}`);
    $events.$off(`file-to-preview-${props.level}`);
});
</script>

<template>
    <IdeoModal
        ref="modal"
        centered
        :size="size"
        :title="title"
        @close="reset"
        @hidden="reset"
    >
        <template #default>
            <div class="row">
                <div :class="isLogitoDocument ? 'col-md-6' : 'col-md-12'">
                    <div v-if="!isLoaded" class="position-absolute top-50 start-50 translate-middle">
                        <Loader />
                    </div>
                    <form v-else id="dynamic-form" @submit.prevent="onSubmit">
                        <FormBlueprint :key="reloadKey" :builder="builder" />
                    </form>
                </div>
                <div v-if="isLogitoDocument" class="col-md-6">
                    <FileReader v-if="filePreview && filePreview.url" :src="filePreview.url" />
                </div>
            </div>
            <BPMNDynamicModal :level="level + 1" />
        </template>
        <template #modal-footer="{ cancel }">
            <IdeoButton :variant="action.buttonVariant" :disabled="!form.active()" @click="onSubmit">
                <i v-if="action.buttonIcon" class="me-2" :class="action.buttonIcon"></i>
                <span>{{ action.formButtonName }}</span>
            </IdeoButton>
            <IdeoButton variant="secondary" @click="cancel">
                {{ $t('[[[Anuluj]]]') }}
            </IdeoButton>
        </template>
    </IdeoModal>
</template>

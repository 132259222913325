<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop } from "@/helpers/Decorators";
import InvoicesService, {
    CellDefinition,
    DescriptionLineDetailsModel,
    DescriptionLineFormModel,
} from "@/modules/low-code/services/InvoicesService";
import DescriptionLinesMultiselect from "./DescriptionLinesMultiselect.vue";
import TaxCodeMultiselect from "./TaxCodeMultiselect.vue";
import { Form, FormType } from "@/helpers/Form";
import { KeyValuePair, Resource } from "@/helpers/Interfaces";
import ImportLinesModal from "../modals/ImportLinesModal.vue";
import { BpmnInvoiceDescriptionFormType } from "@/modules/low-code/inc/finance/settings";
import Pager from "@/helpers/Pager";
import { cloneDeep } from "lodash";

interface BpmnActionButton {
    url: string;
    buttonName: KeyValuePair;
    formType: string,
    endpointId: number,
    formId: number;
}

@Options({
    components: { DescriptionLinesMultiselect, TaxCodeMultiselect, ImportLinesModal },
})
export default class DescriptionLines extends Vue
{
    @Prop({ default: null }) public module: string;
    @Prop({ default: null }) public bpmnPermissions: BpmnActionButton[];
    @Prop({ default: null }) public issueDate: number;
    @Prop({ default: false }) public canDescribe: boolean;
    @Prop({ default: "" }) public status: string;
    @Prop({ default: false }) public design: boolean;

    public useTaxLines: boolean = false;
    public validationNetAmount: number = 0;
    public descriptionTemplate: KeyValuePair = null;
    public validationTaxLines: Record<string, number> = null;

    public descriptionLinesHeaders: CellDefinition[] = [];
    public descriptionLines: Resource<DescriptionLineDetailsModel>[] = [];

    public endpointId: number = null;
    public addLine: boolean = false;
    public editLineId: number = null;
    public form: FormType<DescriptionLineFormModel> = Form.create<DescriptionLineFormModel>({
        attributes: [],
        taxCode: null,
        description: "",
        netAmount: 0,
    });

    public initialForm: FormType<DescriptionLineFormModel> = Form.create<DescriptionLineFormModel>(this.form);
    public netAmountSum: number = 0;

    public get isEditMode(): boolean
    {
        return !!this.publicId;
    }

    public get publicId(): string
    {
        return this.$route.params.publicId as string;
    }

    public get showLegend(): boolean
    {
        return this.status === "InDescription" || this.status === "BossAccept" || this.status === "LinesAccept";
    }

    public get noResults(): boolean
    {
        return !this.descriptionLines.length && !this.addLine;
    }

    public get isProcess(): boolean
    {
        return this.bpmnPermissions != null && this.bpmnPermissions.length > 0;
    }

    public get isAmountCorrect(): boolean
    {
        if (this.validationNetAmount <= 0) return true;

        return this.validationNetAmount - this.netAmountSum == 0;
    }

    public get isVatTableAmountCorrect(): boolean
    {
        if (this.validationTaxLines === null || this.validationTaxLines.length === 0) return true;

        const lines = this.descriptionLines;

        if (lines === null || lines.length === 0) return false;

        const consolidatedTaxLines = this.validationTaxLines;
        const consolidatedDescriptionLinesByVat = this.consolidatedDescriptionLinesByVat;

        for (const [vatKey, vatValue] of Object.entries(consolidatedTaxLines))
        {
            let foundTaxEntryOnLines = false;

            for (const [linesVat, linesValue] of Object.entries(consolidatedDescriptionLinesByVat))
            {
                if (linesVat == vatKey)
                {
                    foundTaxEntryOnLines = true;

                    if (vatValue != linesValue)
                    {
                        return false;
                    }
                }
            }

            if (!foundTaxEntryOnLines) return false;
        }

        return true;
    }

    public get amountDifference(): number
    {
        return Math.abs(this.validationNetAmount - this.netAmountSum);
    }

    public get legendList(): any[]
    {
        return [
            {
                key: this.$t("[[[Do akceptacji]]]"),
                value: "1",
                color: "warning",
            },
            {
                key: this.$t("[[[Zaakceptowano]]]"),
                value: "2",
                color: "success",
            },
            {
                key: this.$t("[[[Zwrócono]]]"),
                value: "3",
                color: "danger",
            },
        ];
    }

    public get consolidatedDescriptionLinesByVat(): Record<string, number>
    {
        const consolidatedLines: Record<string, number> = {};
        const lines = this.descriptionLines;

        if (lines === null || lines.length === 0) return consolidatedLines;

        for (let index = 0; index < lines.length; index++)
        {
            const element = lines[index];

            if ((element as any).result)
            {
                if ((element as any).result.taxCode?.key)
                {
                    if (consolidatedLines[(element as any).result.taxCode.key] == null)
                    {
                        consolidatedLines[(element as any).result.taxCode.key] = (element as any).result.netAmount as number;
                    }
                    else
                    {
                        consolidatedLines[(element as any).result.taxCode.key] += (element as any).result.netAmount as number;
                    }
                }
            }
        }

        return consolidatedLines;
    }

    public async created(): Promise<void>
    {
        if (!this.design)
        {
            this.$events.$on('refetchData', async () =>
            {
                await Promise.all([this.getDescriptionLinesHeaders(), this.getDescriptionLines()]);
            });

            await Promise.all([this.getDescriptionLinesHeaders(), this.getDescriptionLines()]);
        }
    }

    public clearErrors(name: string): void
    {
        this.form.$errors.clear(name);
    }

    public async getDescriptionLinesHeaders(): Promise<void>
    {
        try
        {
            const definition = await InvoicesService.getDescriptionLinesHeaders(this.publicId, this.module);

            this.descriptionLinesHeaders = definition.attributes;
            this.form.attributes = definition.attributes.map((item) => ({ controlCodeId: null, definitionCellId: item.cellId }));
            this.useTaxLines = definition.vatEnabled;
            this.validationNetAmount = definition.validationAmount;
            this.validationTaxLines = definition.vatLines;
        }
        catch (ex)
        {
            this.$log.debug(ex);
        }
    }

    public async getDescriptionLines(): Promise<void>
    {
        try
        {
            this.descriptionLines = (await InvoicesService.getDescriptionLines(this.module, this.publicId, new Pager(1, 20, "id", "asc"))).items;
            this.netAmountSum = this.descriptionLines?.reduce((total: number, item: Resource<DescriptionLineDetailsModel>) =>
            {
                return total + (item.result as any).netAmount;
            }, 0);
        }
        catch (ex)
        {
            this.$log.debug(ex);
        }
    }

    public openNewLineForm(): void
    {
        this.clearForm();
        this.addLine = true;
        this.editLineId = null;
        this.form.$errors.clear();
    }

    public openEditLineForm(rowIndex: number): void
    {
        this.addLine = false;
        this.editLineId = rowIndex;

        const line = this.descriptionLines[rowIndex].result;

        this.form.description = line.description;
        this.form.netAmount = line.netAmount;
        this.form.taxCode = line.taxCode;
        this.descriptionLinesHeaders.forEach((p, i) =>
        {
            const attr = line.attributes.find((x) => x.cellId == p.cellId);

            this.form.attributes[i] = {
                controlCodeId: {
                    key: attr.key,
                    value: attr.value,
                },
                definitionCellId: attr.cellId,
            };
        });
        this.form.$errors.clear();
    }

    public closeEditLineForm(): void
    {
        this.editLineId = null;
    }

    public async saveLine(): Promise<void>
    {
        try
        {
            this.form.wait();

            const model = cloneDeep(this.form);

            model.attributes.forEach((p, i) =>
            {
                p.controlCodeId = (model.attributes[i].controlCodeId as any)?.["key"];
            });
            await InvoicesService.saveDescriptionLines(this.module, this.publicId, model.formatData());

            await this.getDescriptionLines();
            this.addLine = false;
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
                422: (ex: any) => [
                    this.form.$errors.record(ex.data.errors),
                    this.$alert.warning(this.$t("[[[Nie wszystkie wymagane pola zostały wypełnione]]]")),
                ],
            });
        }
        finally
        {
            this.form.continue();
        }
    }

    public async editLine(rowPublicId: string): Promise<void>
    {
        try
        {
            this.form.wait();

            this.form.attributes.forEach((p, i) =>
            {
                p.controlCodeId = (this.form.attributes[i].controlCodeId as any)?.["key"];
            });
            await InvoicesService.editLine(rowPublicId, this.form.formatData());

            await this.getDescriptionLines();
            this.editLineId = null;
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
                422: (ex: any) => [
                    this.form.$errors.record(ex.data.errors),
                    this.$alert.warning(this.$t("[[[Nie wszystkie wymagane pola zostały wypełnione]]]")),
                ],
            });
        }
        finally
        {
            this.form.continue();
        }
    }

    public async deleteLine(rowPublicId: string): Promise<void>
    {
        try
        {
            if (this.isProcess)
            {
                const endpointId = this.bpmnPermissions.find((x) => x.formType === BpmnInvoiceDescriptionFormType)?.endpointId;

                await InvoicesService.deleteBpmnLine(endpointId, this.publicId, rowPublicId);
            }
            else
            {
                await InvoicesService.deleteLine(rowPublicId);
            }

            await this.getDescriptionLines();
        }
        catch (ex)
        {
            this.$log.debug(ex);
        }
    }

    public async applyTemplate(): Promise<void>
    {
        try
        {
            await InvoicesService.applyTemplate(this.module, this.publicId, this.descriptionTemplate.key);
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
                422: (ex: any) => [
                    this.form.$errors.record(ex.data.errors),
                    this.$alert.warning(this.$t("[[[Wczytanie szablonu nie powiodło się]]]")),
                ],
            });
        }
        finally
        {
            await this.getDescriptionLines();
        }
    }

    public classNameByLineStatus(status: KeyValuePair): string
    {
        const cellStatus = status?.key;

        if (!cellStatus) return;

        switch (cellStatus)
        {
            case "ToAccept":
                return "alert-warning";
            case "Accepted":
                return "alert-success";
            case "Returned":
                return "alert-danger";
            default:
                return "";
        }
    }

    private clearForm(): void
    {
        this.form.attributes = this.form.attributes.map((p) => ({ controlCodeId: null, definitionCellId: p.definitionCellId }));
        this.form.taxCode = null;
        this.form.description = "";
        this.form.netAmount = 0;
    }

    public unmounted(): void
    {
        this.$events.$off("refetchData");
    }
}
</script>

<template>
    <data-card v-if="canDescribe || descriptionLines.length > 0 || design">
        <template #header>
            <strong>{{ $t("[[[Opis dokumentu]]]") }}</strong>
        </template>
        <template #default>
            <div class="scroll">
                <div>
                    <table class="description-line-table table table-data table-hover mb-0" :class="{ active: addLine || editLineId || editLineId === 0 }">
                        <thead>
                            <th v-for="(item, index) in descriptionLinesHeaders" :key="index">
                                {{ item.displayName }} <var v-if="item.required" class="text-danger">*</var>
                            </th>
                            <th>{{ $t("[[[Opis]]]") }}</th>
                            <th v-if="useTaxLines" width="120px">{{ $t("[[[Stawka VAT]]]") }}</th>
                            <th width="94px" :class="{ 'text-end': !noResults }">{{ $t("[[[Kwota]]]") }}</th>
                            <th width="94px"></th>
                        </thead>
                        <tbody>
                            <tr v-for="(row, rowIndex) in descriptionLines" :key="rowIndex" :class="{ 'row-edit': editLineId === rowIndex }">
                                <td v-for="(item, index) in descriptionLinesHeaders" :key="index">
                                    <template v-if="editLineId === rowIndex">
                                        <IdeoFormGroup
                                            :invalid-feedback="form.$errors.first(item.propertyName)"
                                            :state="form.$errors.state(item.propertyName)"
                                            nospace
                                        >
                                            <IdeoSelect
                                                v-model="form.attributes[index].controlCodeId"
                                                :placeholder="item.displayName"
                                                :endpoint="`costs/dictionary/${module}/definition/control-codes/${publicId}/${item.type}`"
                                                @update:modelValue="clearErrors('companyId')"
                                            />
                                        </IdeoFormGroup>
                                    </template>
                                    <template v-else>
                                        {{ row.result.attributes.find((p) => p?.cellId == item.cellId)?.value }}
                                    </template>
                                </td>
                                <td class="description-column">
                                    <template v-if="editLineId === rowIndex">
                                        <IdeoFormInput v-model="form.description" />
                                    </template>
                                    <template v-else>
                                        {{ row.result.description }}
                                    </template>
                                </td>
                                <td v-if="useTaxLines">
                                    <template v-if="editLineId === rowIndex">
                                        <IdeoFormGroup :invalid-feedback="form.$errors.first('taxCodeId')" :state="form.$errors.state('taxCodeId')" nospace>
                                            <IdeoSelect
                                                v-model="form.taxCode"
                                                :placeholder="$t('[[[Stawka VAT]]]')"
                                                :endpoint="`simple-dictionary/${module}/form/${publicId}/descriptions/taxCode`"
                                                @update:modelValue="clearErrors('taxCode')"
                                            />
                                        </IdeoFormGroup>
                                    </template>
                                    <template v-else>
                                        {{ (row.result.taxCode as KeyValuePair)?.value }}
                                    </template>
                                </td>
                                <td class="amount-column text-end">
                                    <template v-if="editLineId === rowIndex">
                                        <IdeoFormGroup :invalid-feedback="form.$errors.first('amount')" :state="form.$errors.state('amount')" nospace>
                                            <IdeoFormInput v-model="form.netAmount" type="number" />
                                        </IdeoFormGroup>
                                    </template>
                                    <template v-else>
                                        {{ $filters.currencyFormat(row.result["netAmount"]) }}
                                    </template>
                                </td>
                                <td class="text-end">
                                    <template v-if="editLineId === rowIndex">
                                        <div class="d-flex justify-content-end">
                                            <IdeoButton variant="danger" :title="$t('[[[Zamknij edycje]]]')" @click="closeEditLineForm()">
                                                <i class="fa fa-times"></i>
                                            </IdeoButton>
                                            <IdeoButton variant="success" :title="$t('[[[Zapisz]]]')" @click="editLine(row.result.publicId)">
                                                <i class="fa fa-check"></i>
                                            </IdeoButton>
                                        </div>
                                    </template>
                                    <IdeoDropdown
                                        v-else-if="canDescribe"
                                        ref="ideo-dropdown"
                                        variant="light"
                                        :text="$t('[[[Akcje]]]')"
                                        dropleft
                                        boundary="body"
                                        no-flip
                                        no-caret
                                    >
                                        <template #button-content>
                                            <i v-show="!($refs['ideo-dropdown'] as any)?.visible" class="fas fa-ellipsis-vertical bigger"></i>
                                            <i v-show="($refs['ideo-dropdown'] as any)?.visible" class="fas fa-close bigger"></i>
                                        </template>
                                        <template #default>
                                            <IdeoDropdownItemButton @click="openEditLineForm(rowIndex)">
                                                <i class="fas fa-pencil-alt"></i> {{ $t("[[[Edytuj]]]") }}
                                            </IdeoDropdownItemButton>
                                            <IdeoDropdownItemButton @click="deleteLine(row.result.publicId)">
                                                <i class="fas fa-times"></i> {{ $t("[[[Usuń]]]") }}
                                            </IdeoDropdownItemButton>
                                        </template>
                                    </IdeoDropdown>
                                </td>
                            </tr>

                            <tr v-if="noResults">
                                <td :colspan="descriptionLinesHeaders.length + 4" class="text-center p-3 mb-3 no-results text-muted my-2">
                                    {{ $t("[[[Brak wyników]]]") }}
                                </td>
                            </tr>

                            <tr v-if="addLine" class="alert-secondary my-5">
                                <td v-for="(item, index) in descriptionLinesHeaders" :key="index" :id="item.propertyName">
                                    <IdeoFormGroup
                                        :invalid-feedback="form.$errors.first(item.propertyName)"
                                        :state="form.$errors.state(item.propertyName)"
                                        nospace
                                    >
                                        <IdeoSelect
                                            v-model="form.attributes[index].controlCodeId"
                                            :placeholder="item.displayName"
                                            :endpoint="`costs/dictionary/${module}/definition/control-codes/${publicId}/${item.type}`"
                                            @update:modelValue="clearErrors('companyId')"
                                        />
                                    </IdeoFormGroup>
                                </td>

                                <td class="description-column">
                                    <IdeoFormInput v-model="form.description" />
                                </td>

                                <td v-if="useTaxLines">
                                    <IdeoFormGroup :invalid-feedback="form.$errors.first('taxCode')" :state="form.$errors.state('taxCode')" nospace>
                                        <IdeoSelect
                                            v-model="form.taxCode"
                                            :placeholder="$t('[[[Stawka VAT]]]')"
                                            :endpoint="`simple-dictionary/${module}/form/${publicId}/descriptions/taxCode`"
                                            @update:modelValue="clearErrors('taxCode')"
                                        />
                                    </IdeoFormGroup>
                                </td>

                                <td class="amount-column">
                                    <IdeoFormGroup :invalid-feedback="form.$errors.first('amount')" :state="form.$errors.state('amount')" nospace>
                                        <IdeoFormInput v-model="form.netAmount" name="amount" type="number" />
                                    </IdeoFormGroup>
                                </td>

                                <td class="text-end">
                                    <div class="d-flex justify-content-end">
                                        <IdeoButton variant="danger" :title="$t('[[[Zamknij linię]]]')" @click="addLine = false">
                                            <i class="fa fa-times"></i>
                                        </IdeoButton>
                                        <IdeoButton variant="success" :title="$t('[[[Zapisz]]]')" @click="saveLine">
                                            <i class="fa fa-check"></i>
                                        </IdeoButton>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr v-if="netAmountSum" class="row-summary">
                                <td v-for="(item, index) in descriptionLinesHeaders" :key="index"></td>
                                <td :colspan="useTaxLines ? 3 : 2" class="amount-column px-0 text-end">
                                    <strong>{{ $t("[[[Suma]]]") }}:</strong> {{ $filters.currencyFormat(netAmountSum) }}
                                    <div v-if="canDescribe">
                                        <small v-if="!isAmountCorrect" class="form-text text-danger invalid-feedback d-block">{{ $t("[[[Kwota opisu nie jest zgodna z kwotą faktury. Różnica: ]]]") }} <br />
                                            {{ $filters.currencyFormat(amountDifference) }}</small>
                                        <small v-else class="form-text text-success invalid-feedback d-block">{{ $t("[[[Opis kompletny]]]") }}</small>

                                        <small v-if="!isVatTableAmountCorrect" class="form-text text-danger invalid-feedback d-block">{{
                                            $t("[[[Niezgodne sumy kwot wg stawek vat z tabelką VAT]]]")
                                        }}</small>
                                    </div>
                                </td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>

                <div v-if="canDescribe" class="col-lg-12 mb-3 mt-2">
                    <div class="d-flex flex-wrap gap-2 flex-row align-items-center">
                        <IdeoButton variant="success" class="m-0" :disabled="addLine" @click="openNewLineForm">
                            {{ $t("[[[Dodaj nową linię]]]") }}
                        </IdeoButton>
                        <IdeoButton variant="success" class="m-0" @click="$events.$emit('import-lines-modal')">
                            {{ $t("[[[Importuj linie z pliku]]]") }}
                        </IdeoButton>
                        <div class="d-flex align-items-center mx-2">
                            <div class="me-2">
                                <strong>{{ $t("[[[Szablon]]]") }}</strong>
                            </div>
                            <IdeoSelect
                                v-model="descriptionTemplate"
                                :endpoint="`simple-query/${module}/${publicId}/description-templates-select`"
                                :placeholder="$t('[[[Szablon]]]')"
                                direction="up"
                            />
                        </div>
                        <IdeoButton variant="success" class="m-0" :disabled="addLine || !descriptionTemplate" @click="applyTemplate">
                            {{ $t("[[[Wczytaj szablon]]]") }}
                        </IdeoButton>
                    </div>
                </div>

                <ImportLinesModal :document-id="publicId" :module="module" @reload="getDescriptionLines" />

                <div v-if="showLegend" class="col-lg-12">
                    <ul class="d-flex list-unstyled legend mb-1">
                        <li v-for="(status, index) in legendList" :key="index" class="request-type me-3">
                            <div class="d-flex align-items-center">
                                <div :class="'legend_item bg-' + status.color"></div>
                                <span>{{ status.key }}</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </template>
    </data-card>
</template>

<style lang="scss" scoped>
.card-header {
    padding: 0;
    min-height: 40px !important;
    font-size: 0.875rem !important;
}

.description-line-table {
    width: 100%;
    overflow: auto;
    table-layout: fixed;
    min-width: 650px;

    thead {
        th {
            padding: 12px 4px;

            &:first-of-type {
                padding-left: 10px;
            }

            &:last-of-type {
                padding-right: 10px;
            }
        }
    }

    td {
        width: 150px;
        min-width: 150px;
        max-width: 150px;
        line-height: 1.25;
        height: 60px;
        padding: 0.3125rem 4px;
        vertical-align: middle;

        &:first-of-type {
            padding-left: 10px;
        }

        &:last-of-type {
            padding-right: 10px;
        }
    }

    tfoot {
        td {
            padding: 0.75rem 0 0.5rem 0;
        }
    }

    .row-edit {
        background: #e4e7ea;
    }

    .row-summary {
        border-top: 1px solid #c0cadd;
    }

    .description-column {
        width: auto;
        min-width: 150px;
        max-width: initial;
    }

    .amount-column {
        width: 100px;
        min-width: 100px;
        max-width: initial;
    }
}

.legend {
    .legend_item {
        position: relative;
        width: 15px;
        min-width: 15px;
        height: 15px;
        margin-right: 5px;
        cursor: pointer;
    }
}
</style>

import { axios } from "@/plugins/axios";
import { KeyValue, Resource } from "@/helpers/Interfaces";
import { IMetaModel } from "@/modules/core/common/services/GridService";
import { FieldIndex } from '@/modules/low-code/unique-keys/services/UniqueKeysService';

export default class HomeService
{
    public static async getAvailableWidgets(filter?: FormFilter): Promise<Array<APIWidget>>
    {
        return (
            await axios.get(`widgets-user-dashboard/dictionary/widgets`, {
                params: filter,
            })
        ).data;
    }

    public static async getSettings(sitemapId?: string): Promise<WidgetSettings>
    {
        return (await axios.get(`widgets-user-dashboard/config/${sitemapId || ""}`)).data;
    }

    public static async saveSettings(publicId: string, form: any): Promise<any>
    {
        return (await axios.put(`simple-command/widgets-user-dashboard/${publicId}`, form)).data;
    }

    public static async getWidgetUserConfig(publicId: string): Promise<Resource<WidgetModel>>
    {
        return (await axios.get(`widgets-user-config/${publicId}`)).data;
    }

    public static async updateWidgetUserConfig(publicId: string, form: any, urlParams: any = {}): Promise<any>
    {
        return (await axios.put(`simple-command/widgets-user-config/${publicId}`, form, {
            params: urlParams
        })).data;
    }

    public static async getWidget(publicId: string): Promise<APIWidget>
    {
        return (await axios.get(`widgets-user-config/${publicId}`)).data.result;
    }

    public static async getWidgets(publicIds: string[]): Promise<Resource<APIWidget>[]>
    {
        return (await axios.get(`widgets-user-config`, {
            params: { publicIds: publicIds.join(',') }
        })).data;
    }

    public static async initSettings(form: any): Promise<any>
    {
        return (await axios.post(`simple-command/widgets-user-dashboard`, form)).data;
    }

    public static async getChartWidgetData(url: string, filter: any): Promise<ChartData>
    {
        return (
            await axios.get(url, {
                params: filter,
            })
        ).data;
    }

    public static async getCountWidgetData(url: string, filter: any): Promise<any>
    {
        return (
            await axios.get(url, {
                params: filter,
            })
        ).data;
    }
}

export interface GridItemAttributes {
    id: string;
    "gs-id": string;
    "gs-x": number;
    "gs-y": number;
    "gs-w": number;
    "gs-h": number;
    "gs-no-resize"?: boolean;
}

export interface Widget {
    publicId: string;
    name: Record<string, string>;
    w: number;
    h: number;
    x?: number;
    y?: number;
    config?: APIWidget;
}

export interface WidgetSettings {
    config: Array<{ publicId: string; x: number; y: number, width: number, height: number, sourceConfig: any }>;
    publicId: string;
}


export interface WidgetModel {
    description: string;
    height: string;
    name: string;
    type: string;
    width: string;
    sourceConfig: Record<string, any> | string;
    sourceType: KeyValue<string, string>;
    headerIcon: string;
}

export interface APIWidget {
    actionButtons: string[],
    additionalData?: AdditionalData;
    dataEndpoint: string;
    description: Record<string, string>;
    height: KeyValue<string, string>;
    licence: KeyValue<string, string>;
    licenseUrl: string;
    actionUrl: string;
    moduleId: KeyValue<string, string>;
    name: Record<string, string>;
    publicId: string;
    sourceConfig: SourceConfigList | SourceConfigCount | SourceConfigEmployees | SourceConfigChart;
    sourceType: KeyValue<string, string>;
    staticWidgetType: KeyValue<string, string>;
    sitemapEntryDetails: SitemapEntryDetailsModel;
    width: KeyValue<string, string>;
    headerIcon: string;
    showFilters: boolean;
    showAddButton: boolean;
    showGroupedActionsButton: boolean;
    showPager: boolean;
}

interface SitemapEntryDetailsModel {
    entryType: SitemapEntryType;
    publicId: string;
}

interface AdditionalData {
    footerButtons: Array<IMetaModel>;
    headerButtons: Array<IMetaModel>;
    actionsUrl?: string;
    detailsUrl?: string;
}

export interface SourceConfigList {
    pageSize: number;
    listType: KeyValue<string, string>;
    columns: string;
    filter: string;
    sortField: FieldIndex;
    sortDirection: KeyValue<string, string>;
}

export interface SourceConfigCount {
    icon: string;
    iconColor: KeyValue<string, string>;
    numberColor: KeyValue<string, string>;
    filter: any;
    countType: KeyValue<string, string>;
}

export interface SourceConfigEmployees {}

export interface SourceConfigChart {
    chartType: KeyValue<string, string>;
    dateGroupType: KeyValue<string, string>;
    filter: any;
    icon: string;
    iconColor: KeyValue<string, string>;
    numberColor: KeyValue<string, string>;
    showValues?: boolean;
}


export interface FormFilter {
    search: string;
}

export interface ChartData {
    data: Array<{ amount: number; key: string; value: string }>;
    features: Array<KeyValue<string, string>>;
}

export enum WidgetTypeEnum {
    List = "List",
    Count = "Count",
    NewEmployees = "NewEmployees",
    ActiveSubstitutions = "ActiveSubstitutions",
    DoubleCounter = "DoubleCounter",
    Chart = "Chart",
    Sitemap = "Sitemap",
    Calendar = "Calendar",
    Timetable = "Timetable"
}

export enum WidgetActionsEnum {
    ShowAll = "show-all",
    Add = "add",
}

export enum SitemapEntryType {
    Node = "Node",
    Grid = "Grid",
    Calendar = "Calendar",
    Chart = "Chart",
    Timetable = "Timetable",
    Dashboard = "Dashboard",
}

import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { ValidatableBlueprint } from '@/components/builder/base/blueprints/ValidatableBlueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { HasHelp } from '@/components/builder/form/traits/HasHelp';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { Column } from '@/modules/core/common/services/GridService';

export const Definition: BlueprintDefinition = {
    type: 'logito-document-details',
    name: 'Szczegóły dokumentu',
    icon: 'far fa-memo-circle-info',
    group: 'logito',
    position: 14,
};

export interface LogitoDocumentDetailsContract extends Blueprint, VisibleBlueprint, HasLabel, HasWidth, HasHelp {
    mode: string;
    fieldName: string;
    moduleId: number;
    filtersJson: string;
    filterMappings: Record<string, string>;
    fields: Record<string, Column>;
    backgroundColor: string;
    fontColor: string;
}

export class LogitoDocumentDetailsType implements LogitoDocumentDetailsContract, ValidatableBlueprint
{
    public mode: string;
    public fieldName: string;
    public moduleId: number;
    public filtersJson: string;
    public filterMappings: Record<string, string>;
    public fields: Record<string, Column>;
    public backgroundColor: string;
    public fontColor: string;
    public id: string;
    public type: string;
    public name: string;
    public disabled?: boolean;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public label: Record<string, string>;
    public showLabel: boolean;
    public width: number;
    public minWidth: number;
    public exceptWidth?: number[];
    public help: Record<string, string>;
    public errors: ValidationErrors;

    public constructor(id: string, name: string)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.width = 0;
        this.minWidth = 1;
        this.mode = DocumentDetailsMode[DocumentDetailsMode.Field];
        this.fieldName = null;
        this.moduleId = null;
        this.filtersJson = null;
        this.filterMappings = {};
        this.fields = {};
        this.backgroundColor = null;
        this.fontColor = null;
        this.disabled = false;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.label = { 'pl-PL': 'Szczegóły dokumentu' };
        this.showLabel = true;
        this.help = {};
        this.errors = {};
    }

    public setDefaultWidth(width: number): void
    {
        this.width = Math.min(3, Math.max(this.minWidth, width));
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        if (this.mode === DocumentDetailsMode[DocumentDetailsMode.Field] && this.fieldName == null)
        {
            this.errors.fieldName = ['[[[Pole jest wymagane]]]'];
        }
        else if (
            (this.mode === DocumentDetailsMode[DocumentDetailsMode.Single] ||
                this.mode === DocumentDetailsMode[DocumentDetailsMode.Multi]) &&
            this.moduleId == null
        )
        {
            this.errors.moduleId = ['[[[Moduł jest wymagany]]]'];
        }

        return {
            [this.name]: this.errors,
        };
    }
}

export enum DocumentDetailsMode {
    Field,
    Single,
    Multi,
}

<template>
    <div class="d-flex gap-4 p-1">
        <UserCard
            v-for="item in items"
            :key="item.publicId"
            :username="item.name"
            :job-position="item.jobPosition"
            :avatar-url="$filters.baseurl(item.pictureUrl || '')"
            :unit="item.unit"
            :room-number="item.roomNumber"
            :email="item.email"
            :phone-number="item.phoneNumber"
            :cell-number="item.cellNumber"
            :absence="item.absence"
            @click="openStaffModal(item.publicId)"
        />
        <p v-if="!items.length" class="m-0">{{ $t("[[[Brak wyników]]]") }}</p>
        <StaffModal ref="staff-modal" :user="activeUser" @close="activeUser = null" />
    </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import { Ref, Prop } from "@/helpers/Decorators";
import Pager from "@/helpers/Pager";
import { Form } from "@/helpers/Form";
import PeopleService, { ListItemModel } from "@/modules/logito/staff/services/PeopleService";
import { APIWidget } from "@/modules/core/home/services/HomeService";
import UserCard from "@/modules/logito/staff/components/UserCard.vue";
import StaffModal from "@/modules/logito/staff/components/StaffModal.vue";

@Options({
    name: "EmployeesWidget",
    components: { UserCard, StaffModal },
})
export default class EmployeesWidget extends Vue
{
    @Prop({ default: () => {} })
    public config: APIWidget;

    @Ref("staff-modal")
    public modal: () => InstanceType<typeof StaffModal>;

    public activeUser: ListItemModel = null;
    public pager = new Pager(1, 8, "Id", "DESC");
    public filter = Form.create({
        searchText: "",
        organizationId: -1,
        showInactive: false,
    });
    public items: ListItemModel[] = [];

    public created(): void
    {
        this.loadData();
    }

    public async loadData(): Promise<void>
    {
        this.items = [];

        try
        {
            const filter = this.filter.formatData();
            const response = await PeopleService.getList(filter, this.pager);

            this.items = response.items.map((item) => item.result);
        }
        catch (ex)
        {
            this.items = [];
        }
    }

    public openStaffModal(id: string): void
    {
        this.activeUser = this.items.find((user) => user.publicId === id);
        (this.modal() as any).openModal();
    }
}
</script>

<style lang="scss" scoped>
.new-employees-widget {
    display: flex;
    gap: 20px;
    height: 100%;
}
</style>

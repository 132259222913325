import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useModalStore = defineStore('core-modal', () =>
{
    const openedModalCounter = ref(0);

    function incrementOpenedModalCounter()
    {
        return ++openedModalCounter.value;
    }

    function decrementOpenedModalCounter()
    {
        return --openedModalCounter.value;
    }

    return { incrementOpenedModalCounter, decrementOpenedModalCounter };
});
